import React, { useState } from 'react';
import locales from '../../../constants/en.json';
import PlanPricing from '../../../components/PlanPricing/PlanPricing';
import FAQS from '../../../components/FAQS/FAQS';
import FeedbackForm from '../../../components/FeedbackForm/FeedbackForm';
import { comparefeatureTableData, comparefeaturePriceData } from '../config';
import Banner from '../../../../assets/images/pricing_page_banner.png';
import { ReactComponent as Check } from '../../../../assets/images/check.svg';
import { ReactComponent as Minus } from '../../../../assets/images/minus.svg';
import './Pricing.scss';

function Pricing() {
    const planPricingData = {
        subtitle: 'Affordable',
        title: 'Pricing Options',
        info: 'Choose the plan that best fits your needs and budget',
    };

    return (
        <React.Fragment>
            <div className='pricing_page_container'>
                <div className='pricing-page_banner'>
                    <img src={Banner} className='banner_img' alt='banner'></img>
                </div>
                <div className='pricing_option_section'>
                    <PlanPricing data={planPricingData} />
                </div>
                <div className='compare_feature_section'>
                    <div className='container'>
                        <h5 className='subtitle'>Affordable</h5>
                        <h4 className='title'>Compare features</h4>
                        <span className='description'>Compare different pricing plans to find the best fit.</span>
                        <div className='grid_box'>
                            {comparefeaturePriceData.map((item, index) => {
                                return (
                                    <div className='card_column' key={index}>
                                        <span className='status'>{item.status}</span>
                                        <h5 className='card_title'>{item.title}</h5>
                                        <span className='amount'>{item.price}</span>
                                        <button className='get_start_btn'>{locales.get_started}</button>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='feature_table_Data'>
                            {comparefeatureTableData.map((item, index) => {
                                return (
                                    <div className='table-content' key={index}>
                                        <div className='table_heading'>{item.heading}</div>
                                        <div className='table_row_flex'>
                                            {item.children.map(data => {
                                                return (
                                                    <div className='table_row'>
                                                        <div className='table_subheading'>{data.subheading}</div>
                                                        {data.statusData.map(item => {
                                                            return (
                                                                <div className='status_cell'>
                                                                    {item == 'ok' ? (
                                                                        <span className='check_container'>
                                                                            <Check />
                                                                        </span>
                                                                    ) : (
                                                                        <span className='minus_container'>
                                                                            <Minus />
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <FAQS />
                <FeedbackForm />
            </div>
        </React.Fragment>
    );
}

export default Pricing;
