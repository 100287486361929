import * as Yup from 'yup';

export const loginValidationSchema = Yup.object({
    email: Yup.string().email().required('Email is required.'),
    password: Yup.string().required('Password is required'),
});

export const createAccountInitialData = {
    name: "",
    email: "",
    password: "",
    countryId: "",
    phone: "",
}

export const createAccountValidationSchema = Yup.object({
    name: Yup.string().required('Name is required.'),
    email: Yup.string().email().required('Email is required.'),
    password: Yup.string().required('Password is required'),
    countryId: Yup.string().required('Country is required'),
    phone: Yup.string().required('Phone is required'),
});

export const registrationInitialData = {
    userId: JSON.parse(localStorage.getItem('profileData')|| '{}').userId ,
    company_name: "",
    industryId: "",
    company_type:"",
    address: "",
    tin: "",
    vat: "",
    countryId: "",
    employee_number: "",
    heard_about_us: "",
    multiBranch: "",
    subscribe: "",
}

export const registrationValidationSchema = Yup.object({
    company_name: Yup.string().required('Company name is required.'),
    industryId: Yup.string().email().required('Industry is required.'),
    company_type: Yup.string().required('Company Type is required'),
    countryId: Yup.string().required('Country is required'),
    address: Yup.string().required('Address is required'),
});