import React, { useState } from 'react';
import locales from '../../constants/en.json';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ReactComponent as Check } from '../../../assets/images/check.svg';
import './PlanPricing.scss';

function PlanPricing(props) {
    const [value, setValue] = useState(0);
    const plandPricingData = [
        {
            statusLabel: '',
            title: 'Free Trial',
            amount: '$0',
            children: ['1 user', 'Plan features', 'Product support'],
        },
        {
            statusLabel: 'Most popular',
            title: 'Basic',
            amount: '$50',
            children: ['1 user', 'Plan features', 'Product support'],
        },
        {
            statusLabel: '',
            title: 'Standard',
            amount: '$550',
            children: ['1 user', 'Plan features', 'Product support'],
        },
        {
            statusLabel: '',
            title: 'Enterprise',
            amount: '$648',
            children: ['1 user', 'Plan features', 'Product support'],
        },
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <div className='plan_pricing_section'>
                <div className='container'>
                    <h5 className='subtitle'>Enhance</h5>
                    <h4 className='title'>Pick Your Perfect Plan</h4>
                    <span className='info'>Check out our Pricing page for complete details</span>
                    <Box sx={{ width: '100%' }} className='tab_section'>
                        <Box sx={{ width: '100%' }} className='tab_header'>
                            <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                                <Tab label='Monthly' {...a11yProps(0)} />
                                <Tab label='Yearly' {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <Box className='tab_body'>
                            <CustomTabPanel value={value} index={0} className='tab_content'>
                                <div className='flex_grid'>
                                    {plandPricingData.map((item, index) => {
                                        return (
                                            <div className='card'>
                                                <span className='status_label'>{item.statusLabel}</span>
                                                <h5 className='title'>{item.title}</h5>
                                                <span className='amount'>{item.amount}</span>
                                                <ul className='listing_points'>
                                                    {item.children.map((data, index) => {
                                                        return (
                                                            <li className='list_item'>
                                                                <span className='icon'>
                                                                    <Check />
                                                                </span>
                                                                <span className='text'>{data}</span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                                <button className='get_started'>{locales.get_started}</button>
                                            </div>
                                        );
                                    })}
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1} className='tab_content'>
                                <div className='flex_grid'>
                                    {plandPricingData.map((item, index) => {
                                        return (
                                            <div className='card' key={index}>
                                                <span className='status_label'>{item.statusLabel}</span>
                                                <h5 className='title'>{item.title}</h5>
                                                <span className='amount'>{item.amount}</span>
                                                <ul className='listing_points'>
                                                    {item.children.map((data, index) => {
                                                        return (
                                                            <li className='list_item'>
                                                                <span className='icon'>
                                                                    <Check />
                                                                </span>
                                                                <span className='text'>{data}</span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                                <button className='get_started'>{locales.get_started}</button>
                                            </div>
                                        );
                                    })}
                                </div>
                            </CustomTabPanel>
                        </Box>
                    </Box>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PlanPricing;
