import React, { useState } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { Input, SelectWithInput } from '../common';
import { createAccountInitialData, createAccountValidationSchema } from '../../pages/SignUp/signupValidationSchema';
import locales from '../../constants/en.json';
import { signupVerificationService } from '../../../services/registration.service';

function CreatAccount({countryDataList, onNextClick}) {
    const [passwordShow, setPasswordShow] = useState(false);

    const { values, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: createAccountInitialData,
        validationSchema: createAccountValidationSchema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: (values, action) => {
            handleSignupVerification(values);
            action.resetForm();
        },
    });

    const onPasswordShow = () => {
        setPasswordShow(state => !state);
    };

    const handleSignupVerification = (values) => {
        try {
            signupVerificationService(values).then(response => {
                console.log({response});
                if (response?.data?.status == true) {
                    window.localStorage.setItem('profileData', JSON.stringify(response.data.data));
                    onNextClick();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response?.data?.message,
                    });
                }
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Something went wrong',
                text: error,
            });
        }
    };

    return (
        <React.Fragment>
            <div className='creatAccountForm_wrapper'>
                <div className='formContainer'>
                    <h2 className='title'>{locales.create_account}</h2>
                    {/* <div className='signup_google'>
                        <button
                            className='googleButton'
                            onClick={() => {
                                // setEmailVerify(true);
                                // setSignInView(false)
                            }}
                        >
                            <span className='icon'>
                                <img src={GoogleIcon} alt='google_icon'></img>
                            </span>
                            <span className='text'>{locales.signIn_google}</span>
                        </button>
                    </div>
                    <div className='or_seperate'>or</div> */}
                    <Input
                        label={'Full Name'}
                        type={'text'}
                        name={'name'}
                        id={'name'}
                        wrapperClass={'col12'}
                        value={values.name}
                        onChange={handleChange}
                        isRequired
                        error={errors.name}
                        touched={touched.name}
                    />
                    <Input
                        label={'Email'}
                        type={'text'}
                        name={'email'}
                        id={'email'}
                        wrapperClass={'col12'}
                        value={values.email}
                        onChange={handleChange}
                        isRequired
                        error={errors.email}
                        touched={touched.email}
                    />
                    <Input
                        label={'Password'}
                        type={passwordShow ? 'text' : 'password'}
                        name={'password'}
                        id={'password'}
                        isRequired
                        value={values.password}
                        className={'eyeWrap'}
                        wrapperClass={'col12'}
                        onChange={handleChange}
                        hasEye
                        onEyeClick={onPasswordShow}
                        showEye={passwordShow}
                        touched={touched.password}
                        error={errors.password}
                    />
                    <SelectWithInput
                        label={'Contact Number'}
                        selectName={'countryId'}
                        options={countryDataList}
                        inputName={'phone'}
                        placeholder={'+1 (000) 000-0000'}
                        wrapperClass={'col12'}
                        onChange={handleChange}
                        values={values}
                        touched={touched}
                        errors={errors}
                        maxLength={10}
                        isRequired
                    />
                    <div className='submit_action'>
                        <button className='continue_btn' onClick={handleSubmit}>
                            {locales.continue_to_verification}
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CreatAccount;
