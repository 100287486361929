import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Input, Select, DatePicker, SelectWithInput } from '../../../components/common';
import { axiosClient } from '../../../../services/axiosClient';
import SuccessModal from '../../../components/common/CommonSuccessModal/SuccessModal';
import { ReactComponent as Info } from '../../../../assets/images/info.svg';
import { ReactComponent as Barcode } from '../../../../assets/images/sample-bar-code.svg';
import QRcode from '../../../../assets/images/sample-qr-code.png';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import './AddNewLedger.scss';
import ErrorModal from '../../../components/common/CommonErrorModal/ErrorModal';


const exciseDetailEditFormSchema = Yup.object({
    exciseRegistrationNumber: Yup.string().required('Excise Registration Number is required.'),
    // productType: Yup.string().required('Product Type is required.'),
    // manufacturer: Yup.string().required('Manufacturer is required.'),
});

const exciseDetailInitialValues = {
    importCode: '',
    exportCode: '',
    exciseRegistrationNumber: '',
    exciseDivision: 'Divison A',
    exciseRange: 'Range 2',
    exciseRate: '',
    exciseDuty: 'Special Excise Duty'
};

function ExciseDetails({ changeTab, ProductCreateList, ledgerDetailData, preview, previewData, back, ledgerAllData, draftPopUpClose }) {
    const [draftClicked, setDraftClicked] = useState(false);
    const [draftButtonClick, setDraftButtonClick] = useState(false);
    const [draftsuccessModal, setDraftSuccessModal] = useState(false);
    const [SuccessMsg, setSuccessMsg] = useState('');
    const [SuccessTitle, setSuccessTitle] = useState('');
    const [showLoader, setshowLoader] = useState(false);
    const [currentTabvalue, setCurrentTabvalue] = useState(0);
    const [errorModal, setErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik({
        initialValues: exciseDetailInitialValues,
        validationSchema: exciseDetailEditFormSchema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: (values, action) => {
            handleFormSubmit(values);
            // action.resetForm();
        },
    });

    const handleFormSubmit = async values => {
        if (draftClicked) {
            handleDraft(values);
        } else {
            ledgerDetailData(values);
            changeTab(3);
        }
    };
    const handleTabBack=()=>{
        changeTab(1);
    }
    
    // useEffect(() => {
    //     setTimeout(() => {
    //         if (previewData) {
    //             Object.entries(previewData).map(item => {
    //                 setFieldValue(item[0], item[1]);
    //                 if (item[0] == 'packaging' && item[1].length > 0) {
    //                     item[1].map((data, index) => {
    //                         setFieldValue(`Sales_Packing_${index + 1}`, data.packType);
    //                         setFieldValue(`Quantity_${index + 1}`, data.quantity);
    //                         setFieldValue(`Rate_${index + 1}`, data.price);
    //                         setFieldValue(`Stock_${index + 1}`, data.stock);
    //                     });
    //                 }
    //             });
    //         }
    //         if (ledgerAllData) {
    //             Object.entries(ledgerAllData).map(item => {
    //                 setFieldValue(item[0], item[1]);
    //                 if (item[0] == 'packaging' && item[1].length > 0) {
    //                     item[1].map((data, index) => {
    //                         setFieldValue(`Sales_Packing_${index + 1}`, data.packType);
    //                         setFieldValue(`Quantity_${index + 1}`, data.quantity);
    //                         setFieldValue(`Rate_${index + 1}`, data.price);
    //                         setFieldValue(`Stock_${index + 1}`, data.stock);
    //                     });
    //                 }
    //             });
    //         }
    //         setshowLoader(false);
    //     }, 700);
    // }, []);


    useEffect(()=>{
        if(Object.keys(previewData).length>0){
           Object.keys(exciseDetailInitialValues).map((item)=>{
            setFieldValue(item,previewData[item]);
           })
        }else{
            Object.keys(exciseDetailInitialValues).map((item)=>{
                if(ledgerAllData[item]){
                setFieldValue(item,ledgerAllData[item]);
                }
               })
        }
    },[])
    const handleDraft = data => {
        if (draftButtonClick == false) {
            setDraftButtonClick(true);
            ledgerAllData.isDraft= true;
            delete ledgerAllData?.ledgerCode;
            const value = {...values,...ledgerAllData};
            value?.bankDetails?.map((item) => {
                if(item?._id?.toString().length<=3){
                    delete item._id;
                    delete item.bankId      
                    }
             });
             value?.document?.map(item => {
                    if (item?._id?.toString().length <= 3) {
                        delete item._id;
                        delete item.documentId;
                    }
                });
            saveDraft(value);
        }
    };

    const saveDraft = async event => {
        const accessToken = `Bearer ${sessionStorage.accessToken} `;
        try {
            let response = await axiosClient.post(`admin/ledger/create`, event, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-via-device': true,
                    Authorization: accessToken,
                },
            });

            if (response.status == 200) {
                setDraftClicked(false);
                setDraftSuccessModal(true);
                setSuccessTitle('Draft Saved Successfully');
                setDraftButtonClick(false);
            }
        } catch (error) {
            setDraftClicked(false);
            setDraftButtonClick(false);
            setErrorModal(true);
            setErrorMsg(error.response.data.message);
            console.log('error', error);
        }
    };

    const handleDraftSuccessPopupClose = () => {
        draftPopUpClose();
    };
    const handleModalErrorPopUP = () => {
        setErrorModal(false);
        setErrorMsg('');
    }

    return (
        <React.Fragment>
            <div className={`Detail_container exciseDetails_Section ${preview ? 'preview_active' : ''}`}>
                <div className='first_flexbox'>
                    <div className='inputBox importCode sm-30 lg-25'>
                        <Input
                            label={'Import Code'}
                            type={'text'}
                            name={'importCode'}
                            id={'importCode'}
                            value={values.importCode}
                            wrapperClass={'col12'}
                            onChange={handleChange}
                            error={errors.importCode}
                            touched={touched.importCode}
                            ReadOnly={preview ? true : false}
                        />
                    </div>
                    <div className='inputBox exportCode sm-30 lg-25'>
                        <Input
                            label={'Export Code'}
                            type={'text'}
                            name={'exportCode'}
                            id={'exportCode'}
                            value={values.exportCode}
                            wrapperClass={'col12'}
                            onChange={handleChange}
                            error={errors.exportCode}
                            touched={touched.exportCode}
                            ReadOnly={preview ? true : false}
                        />
                    </div>
                    </div>
                     <div className='first_flexbox'>
                    {/* <div className='inputBox blank sm-30 lg-25'></div> */}
                    <div style={{width:'550px'}} className='inputBox exciseRegistration sm-60 lg-50'>
                        <Input
                            label={'Excise Registration No.'}
                            type={'text'}
                            name={'exciseRegistrationNumber'}
                            id={'exciseRegistrationNumber'}
                            value={values.exciseRegistrationNumber}
                            wrapperClass={'col12'}
                            onChange={handleChange}
                            error={errors.exciseRegistrationNumber}
                            touched={touched.exciseRegistrationNumber}
                            ReadOnly={preview ? true : false}
                            isRequired
                        />
                        {preview ? null : <ul className='info_listing'>
                            <li>Unique ID for businesses handling excise goods.</li>
                            <li>Required for tax compliance in relevant transactions.</li>
                        </ul>}
                    </div>
                    </div>
                     <div className='first_flexbox'>
                    {/* <div className='inputBox blank sm-30 lg-25'></div> */}
                    <div className='inputBox exciseDivision sm-30 lg-25'>
                        <Select
                            label={'Excise Divison'}
                            name={'exciseDivision'}
                            options={[
                                { id: 'Divison A', value: 'Divison A' },
                                { id: 'Divison B', value: 'Divison B' },
                            ]}

                            wrapperClass={'col12'}
                            value={values.exciseDivision}
                            onChange={handleChange}
                            error={errors.exciseDivision}
                            touched={touched.exciseDivision}
                            disabled={preview ? true : false}
                        />
                    </div>
                    <div className='inputBox exciseRange sm-30 lg-25'>
                        <Select
                            label={'Excise Range'}
                            name={'exciseRange'}
                            options={[
                                { id: 'Range 1', value: 'Range 1' },
                                { id: 'Range 2', value: 'Range 2' },
                            ]}

                            wrapperClass={'col12'}
                            value={values.exciseRange}
                            onChange={handleChange}
                            error={errors.exciseRange}
                            touched={touched.exciseRange}
                            disabled={preview ? true : false}
                        />
                    </div>
                    </div>
                     <div className='first_flexbox'>
                    {/* <div className='inputBox blank sm-30 lg-25'></div> */}
                    <div className='inputBox exciseRate sm-30 lg-25'>
                        <Input
                            label={'Excise Rate'}
                            type={'text'}
                            name={'exciseRate'}
                            id={'exciseRate'}
                            value={values.exciseRate}
                            wrapperClass={'col12'}
                            onChange={handleChange}
                            error={errors.exciseRate}
                            touched={touched.exciseRate}
                            ReadOnly={preview ? true : false}
                        />
                    </div>
                    <div className='inputBox exciseDuty sm-30 lg-25'>
                        <Select
                            label={'Excise Duty Type'}
                            name={'exciseDuty'}
                            options={[
                                { id: 'Special Excise Duty', value: 'Special Excise Duty' },
                            ]}

                            wrapperClass={'col12'}
                            value={values.exciseDuty}
                            onChange={handleChange}
                            error={errors.exciseDuty}
                            touched={touched.exciseDuty}
                            disabled={preview ? true : false}
                        />
                    </div>
                </div>

                <div className='actionFlexbox'>
                    {preview ? null : (
                        <React.Fragment>
                            {!Object.keys(previewData).length>0 && (
                            <button
                                type='button'
                                className='draftBtn'
                                onClick={() => {
                                    setDraftClicked(true);
                                    handleSubmit();
                                }}
                            >
                                Save Draft
                            </button>
                            )}
                            <div className='rightCol'>
                                <button type='button' className='canceltBtn' onClick={handleTabBack}>
                                    Back
                                </button>
                                <button type='button' className='nextBtn' onClick={handleSubmit}>
                                    Next
                                </button>
                            </div>{' '}
                        </React.Fragment>
                    )}
                </div>
            </div>
            {draftsuccessModal && (
                <SuccessModal
                    handleSuccessClose={handleDraftSuccessPopupClose}
                    SuccessPopUp={draftsuccessModal}
                    SuccessMsg={SuccessMsg}
                    SuccessTitle={SuccessTitle}
                />
            )}
            {errorModal && (
                <ErrorModal
                    handleErrorClose={handleModalErrorPopUP}
                    ErrorPopUp={errorModal}
                    ErrorMsg={errorMsg}
                />
            )}
        </React.Fragment>
    );
}

export default ExciseDetails;
