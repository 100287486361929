import React, { useState, useEffect } from 'react';

import { Box, Tabs, Tab } from '@mui/material';
import { ProductMasterTabs, tableHeader, DELETE_MODAL_TITLE, DELETE_MODAL_MSG, updateHeader } from './constant';
import { dateExtract, updateColumnBodyData } from './util';
import { axiosClient } from '../../../services/axiosClient';

import DeleteModal from '../../components/common/CommonDeleteModal/CommonDeleteModal';
import SuccessModal from '../../components/common/CommonSuccessModal/SuccessModal';
import CommonTable from '../../components/common/CommonTable/CommonTable';
import Pagination from '../../components/common/PaginationLayout';

import CreateNewPurchase from './CreateNewPurchase';
import ManageColumn from './Component/ManageColumn';

import { ReactComponent as UserPlus } from '../../../assets/images/user-plus.svg';

import './purchaseStyle.scss';




const PurchaseList = ({breadcrumbUpdateData, updateBreadCrumb }) => {
    const [value, setValue] = useState(0);
    const [searchVal, setSearchVal] = useState('');
    const [allListData, setAllListData] = useState([]);
    const [previewData, setPreviewData] = useState(null);

    const [purchaseTableData, setPurchaseTableData] = useState([]);
    const [purchaseTableDataOld, setPurchaseTableDataOld] = useState([]);

    const [tableFilterHeader, setTableFilterHeader] = useState(tableHeader);
    const [createNewPurchase, setCreateNewPurchase] = useState(false);

    const [pageValue, setPageValue] = useState(1);
    const [totalPagesList, setTotalPagesList] = useState('');

    const [deleteProductData, setDeleteProductData] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);

    const [DeleteModalTitle, setDeleteModalTitle] = useState('');
    const [DeleteModalMsg, setDeleteModalMsg] = useState('');

    const [successModal, setSuccessModal] = useState('');
    const [SuccessModalMsg, setSuccessModalMsg] = useState('');

    const [breadcrumb, setBreadCrumb] = useState([...breadcrumbUpdateData]);
    const [mode, setMode] = useState('');


    const [drawerColumnOpen, setDrawerColumnOpen] = React.useState(false);
    const [tableHeaderData, setTableHeaderData] = useState(updateHeader);

    const handleAddNewPurchase = () => {
        setCreateNewPurchase(true);
        setMode('create');
        setBreadCrumb([...breadcrumb, 'Add New Ledger']);
    };

    const onHandleSearch = (event) => {
        setSearchVal(event.target.value);

        if (event.target.value == '') {
            handlePurchaseList({ pageNo: '', data: event.target.value });
        } else {
            handlePurchaseList({ pageNo: 1, data: event.target.value });
        }
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchVal('');
        setPageValue(1);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`}
                 aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handlePurchaseDelete = e => {
        setDeleteProductData(e);
        setDeleteModal(true);
        setDeleteModalTitle(DELETE_MODAL_TITLE);
        setDeleteModalMsg(DELETE_MODAL_MSG);
    };

    const handleDeleteData = () => {
        handleDeleteLedgerData(deleteProductData);
        handlePurchaseList()
    };

    const handleDeleteLedgerData = async event => {
        const accessToken = `Bearer ${sessionStorage.accessToken} `;
        try {
            let response = await axiosClient.post(`admin/purchase/delete`, JSON.stringify({ _id: event }), {
                headers: {
                    'Content-Type': 'application/json',
                    'x-via-device': true,
                    Authorization: accessToken,
                },
            });
            if (response.status == 200) {
                setSuccessModal(true);
                setSuccessModalMsg(response?.data?.message);
                setDeleteProductData('');
                setDeleteModal(false);
                // setDraftListData( response?.data?.data?.products)
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleDeleteSuccessModalClose = () => {
        setSuccessModal(false);
        setSuccessModalMsg('');
        setPreviewData([]);
    };

    const handleEditData = (id) => {

        // Find the specific purchase by its ID (assuming `_id` is a unique identifier)
        const selectedData = allListData.find(item => item._id === id);

        if (selectedData) {
            setPreviewData(selectedData);
            setCreateNewPurchase(true);  // Opens the create/edit form with the selected data
            setMode('edit');
            setBreadCrumb([...breadcrumb, 'Edit Ledger']);
        } else {
            console.warn('No matching data found for the given ID.');
        }

    };

    const handleViewDetail = (id) => {

        // Find the specific purchase by its ID (assuming `_id` is a unique identifier)
        const selectedData = allListData.find(item => item._id === id);

        if (selectedData) {
            setPreviewData(selectedData);
            setCreateNewPurchase(true);  // Opens the create/edit form with the selected data
            setMode('viewMode');
            setBreadCrumb([...breadcrumb, 'Edit Ledger']);
        } else {
            console.warn('No matching data found for the given ID.');
        }

    };

    const handleDataPopulate = () => {
        console.log('handlePurchaseDelete');
    };
    const handleManageFilter = () => {
        console.log('handlePurchaseDelete');
    };

    const handlePagination = event => {
        setPageValue(event);
    };


    const handlePurchaseList = async event => {
        const accessToken = `Bearer ${sessionStorage.accessToken} `;
        try {
            let response = await axiosClient.post(
                `admin/purchase/list`,
                JSON.stringify({
                    search: event?.data,
                    isDraft: value == 0 ? false : true,
                    page: event?.pageNo ? 1 : pageValue,
                    limit: 5,
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-via-device': true,
                        Authorization: accessToken,
                    },
                },
            );
            if (response.status == 200) {
                setTotalPagesList(response?.data?.data?.totalPages);


                const transformedData = response?.data?.data?.purchaseTransaction.map((item) => ({
                    'Purchase Type': item.purchaseType,
                    'Transaction ID': item.transactionId,
                    'Purchase Number': item.purchaseNumber,
                    'Purchase Date': dateExtract(item.purchaseDate),
                    'Invoice Number': item.invoiceNumber,
                    'Invoice Date': dateExtract(item.invoiceDate),
                    '_id':item._id
                }));

                let bodyDataUpdate = updateColumnBodyData(tableHeaderData, transformedData);
                setPurchaseTableData(bodyDataUpdate);
                setPurchaseTableDataOld(transformedData)
                setDeleteProductData('');
                setDeleteModal(false);
                setAllListData(response?.data?.data?.purchaseTransaction);
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    // when column change than body data also change
    useEffect(()=>{
        let bodyDataUpdate = updateColumnBodyData(tableHeaderData, purchaseTableDataOld);
        setPurchaseTableData(bodyDataUpdate);
    },[tableHeaderData])

    const handleBradCrumb = () => {
        let removeLastBreadcrumb = breadcrumb.filter(item => {
            if (item !== 'Add New Ledger' && item !== 'Edit Ledger') {
                return item;
            }
        });
        setBreadCrumb([...removeLastBreadcrumb]);
        setCreateNewPurchase(false);
        setPreviewData(null);
        handlePurchaseList();
    };



    useEffect(() => {
        handlePurchaseList();
    }, []);

    useEffect(() => {
        if (pageValue !== 0 || value !== '') {
            handlePurchaseList();
        }
    }, [pageValue, value]);

    useEffect(() => {
        updateBreadCrumb(breadcrumb);
    }, [breadcrumb]);

    return (
        <>
            {
                !createNewPurchase ?
                    <div className="ledgerMaster_container">
                        <div className="ledgerMaster_content">
                            <div className='headerFlexbox'>
                                <h5 className='title'>Purchase Transactions List</h5>
                                <button className='addnewProduct' onClick={() => handleAddNewPurchase()}>
                                    <span className='icon'><UserPlus/></span>
                                    Record New Purchase
                                </button>
                            </div>
                            <div className="contentSection">
                                <div className='head-flexbox'>
                                    <input
                                        type='text'
                                        className='searchBox'
                                        placeholder='Search Transaction'
                                        value={searchVal}
                                        onChange={onHandleSearch}>
                                    </input>

                                </div>
                                <div className='ledgerMasterListingTabs'>
                                    <Box className='tabsContainer' sx={{ width: '100%' }}>
                                        <Box className='tabFlexContainer'>
                                            <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                                                {ProductMasterTabs &&
                                                ProductMasterTabs.length > 0 &&
                                                ProductMasterTabs.map((label, index) => {
                                                    return <Tab label={label} {...a11yProps(index)} />;
                                                })}
                                            </Tabs>
                                        </Box>
                                        <CustomTabPanel value={value} index={0} className='tabContentContainer'>
                                            <div className='table_container'>
                                                <CommonTable
                                                    deleteProductData={e => handlePurchaseDelete(e)} // Passing the unique ID
                                                    dataEditPopulate={item => handleEditData(item)}  // Passing the unique ID
                                                    dataPopulate={e => handleDataPopulate(e)} // Passing the unique ID
                                                    header={tableHeaderData.filter(item => item.visible).map(item => item.name)}
                                                    tableBodyData={purchaseTableData}
                                                    tableFilterHeader={tableFilterHeader}
                                                    pagerender="PurchaseList"
                                                    filterSave={(data) => handleManageFilter(data)}
                                                    filterDrawerData={tableHeader}
                                                    copyHeaderItem={['Transaction ID', 'Invoice Number']}
                                                    showViewProducts={true}
                                                    handleViewDetail={item => handleViewDetail(item)}
                                                    setDrawerColumnOpen={setDrawerColumnOpen}
                                                    showEditAction={true}
                                                    showDeleteAction={true}

                                                />
                                            </div>
                                            {purchaseTableData ? (
                                                <Pagination totalPages={totalPagesList} pageNo={pageValue}
                                                            paginationSet={e => handlePagination(e)}/>
                                            ) : (
                                                ''
                                            )}
                                        </CustomTabPanel>
                                        <CustomTabPanel value={value} index={1} className='tabContentContainer'>
                                            <CommonTable
                                                deleteProductData={e => handlePurchaseDelete(e)} // Passing the unique ID
                                                dataEditPopulate={item => handleEditData(item)}  // Passing the unique ID
                                                dataPopulate={e => handleDataPopulate(e)} // Passing the unique ID
                                                header={tableHeaderData.filter(item => item.visible).map(item => item.name)}
                                                tableBodyData={purchaseTableData}
                                                tableFilterHeader={tableFilterHeader}
                                                pagerender="PurchaseList"
                                                filterSave={(data) => handleManageFilter(data)}
                                                filterDrawerData={tableHeader}
                                                copyHeaderItem={['Transaction ID', 'Invoice Number']}
                                                showViewProducts={true}
                                                handleViewDetail={item => handleViewDetail(item)}
                                                setDrawerColumnOpen={setDrawerColumnOpen}
                                                showEditAction={true}
                                                showDeleteAction={true}
                                            />
                                            {purchaseTableData ? (
                                                <Pagination totalPages={totalPagesList} pageNo={pageValue}
                                                            paginationSet={e => handlePagination(e)}/>
                                            ) : (
                                                ''
                                            )}
                                        </CustomTabPanel>
                                    </Box>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <CreateNewPurchase previewData={previewData} mode={mode} back={handleBradCrumb} setMode={setMode}/>
            }

            {successModal && (
                <SuccessModal
                    handleSuccessClose={handleDeleteSuccessModalClose}
                    SuccessPopUp={successModal}
                    SuccessTitle={SuccessModalMsg}
                    // SuccessMsg={SuccessModalMsg}
                />
            )}

            {deleteModal && (
                <DeleteModal
                    handleDeleteClose={handleDeleteModalClose}
                    DeletePopUp={deleteModal}
                    DeleteModalTitle={DeleteModalTitle}
                    DeleteModalMsg={DeleteModalMsg}
                    handleDeleteConfirm={handleDeleteData}
                />
            )}
            {drawerColumnOpen && <ManageColumn setDrawerOpen={setDrawerColumnOpen} columnData ={tableHeaderData} setColumnData={setTableHeaderData}/>}
        </>
    );
};

export default PurchaseList;