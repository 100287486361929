import React, { useState, useEffect } from 'react';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateExtract } from '../util';

export function DatePicker({ label, name, value, dateFormat, wrapperClass, isRequired, onChange, ReadOnly, disabled = false }) {
    const parseDate = (val) => {
        return val ? new Date(dateExtract(val)) : null;
    };

    const [startDate, setStartDate] = useState(parseDate(value));

    useEffect(() => {
        setStartDate(parseDate(value));
    }, [value]);

    const handleDateChange = (date) => {
        if (date instanceof Date && !isNaN(date)) { // Confirm valid date
            setStartDate(date);
            onChange && onChange(date);
        } else {
            console.error("Selected date is invalid:", date);
        }
    };

    return (
        <div className={`inputField ${wrapperClass}`}>
            <label>
                {label}
                {isRequired && <span style={{ color: "#EF4444" }}>*</span>}
            </label>
            <ReactDatePicker
                selected={startDate}
                onChange={handleDateChange}
                dateFormat={dateFormat}
                readOnly={ReadOnly}
                placeholderText="Select Date"
                disabled={disabled}
            />
        </div>
    );
}
