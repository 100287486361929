// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const UserListSlices = createSlice({
    name: 'userList',
    initialState: {},
    reducers: {
        updateUsersList: (state, action) => {
            state.data = action.payload;
        },
        clearStore(state) {
            state = {};
        },
    },
});

export const { updateUsersList, clearStore } = UserListSlices.actions;
export default UserListSlices.reducer;
