
import { ReactComponent as Dashboard } from '../../../assets/images/home.svg';
import { ReactComponent as Admin } from '../../../assets/images/admin.svg';
import { ReactComponent as UserCog } from '../../../assets/images/user-cogNew.svg';
import { ReactComponent as MedicineIcon } from '../../../assets/images/medicines_green.svg';
import { ReactComponent as CRMIcon } from '../../../assets/images/crm_green.svg';
import { ReactComponent as CMSIcon } from '../../../assets/images/cms_green.svg';
import { ReactComponent as VendorsIcon } from '../../../assets/images/vendors_green.svg';
import { ReactComponent as FinanceIcon } from '../../../assets/images/finances_green.svg';
import { ReactComponent as ReportIcon } from '../../../assets/images/reports.svg';
import { ReactComponent as Boxes } from '../../../assets/images/boxes.svg';

export const leftNavConfig = [
    {
        label: 'Dashboard',
        icon: <Dashboard />,
        path: '/dashboard',
    },
    {
        label: 'Admin',
        icon: <Admin />,
        path: '/dashboard/admin',
    },
    {
        label: 'HRM',
        icon: <UserCog />,
        subMenu: [
            {
                label: 'User',
                path: '/dashboard/users',
            },
            {
                label: 'Employee',
                path: '/dashboard/employees',
            },
        ],
    },
    {
        label: 'Masters',
        icon: <Boxes />,
        subMenu: [
            {
                label: 'Products',
                path: '/dashboard/master/products',
            },
            {
                label: 'Attributes',
                path: '/dashboard/master/attributes',
            },
            {
                label: 'Manufacturers',
                path: '/dashboard/master/manufacturers',
            },
            {
                label: 'Suppliers',
                path: '/dashboard/master/suppliers',
            },
            {
                label: 'Categories',
                path: '/dashboard/master/categories',
            },
            {
                label: 'Ledger',
                path: '/dashboard/master/ledger',
            },
            {
                label: 'Purchase',
                path: 'master/purchase',
            },
        ],
    },
    {
        label: 'Medicines',
        icon: <MedicineIcon />,
        path: '/dashboard/medicines',
    },
    {
        label: 'CRM',
        icon: <CRMIcon />,
        path: '/dashboard/crm',
    },
    {
        label: 'CMS',
        icon: <CMSIcon />,
        path: '/dashboard/cms',
    },
    {
        label: 'Vendors',
        icon: <VendorsIcon />,
        path: '/dashboard/vendors',
    },
    {
        label: 'Finances',
        icon: <FinanceIcon />,
        path: '/dashboard/finances',
    },
    {
        label: 'Reports',
        icon: <ReportIcon />,
        path: '/dashboard/reports',
    },
];