import Team1 from '../../../../assets/images/team_1.png';
import Team2 from '../../../../assets/images/team_2.png';
import Team3 from '../../../../assets/images/team_3.png';
import Team4 from '../../../../assets/images/team_4.png';
import Team5 from '../../../../assets/images/team_5.png';
import Team6 from '../../../../assets/images/team_6.png';
import Team7 from '../../../../assets/images/team_7.png';
import Team8 from '../../../../assets/images/team_8.png';
import BlogPost1 from '../../../../assets/images/blog_post_1.png';
import BlogPost2 from '../../../../assets/images/blog_post_2.png';
import BlogPost3 from '../../../../assets/images/blog_post_3.png';
import BlogPost4 from '../../../../assets/images/blog_post_4.png';
import BlogPost5 from '../../../../assets/images/blog_post_5.png';
import BlogPost6 from '../../../../assets/images/blog_post_6.png';
import BlogPost7 from '../../../../assets/images/blog_post_7.png';
import BlogPost8 from '../../../../assets/images/blog_post_8.png';
import BlogPost9 from '../../../../assets/images/blog_post_9.png';
import BlogPost10 from '../../../../assets/images/blog_post_10.png';
import BlogPost11 from '../../../../assets/images/blog_post_11.png';
import BlogPost12 from '../../../../assets/images/blog_post_12.png';
import BlogAvatar1 from '../../../../assets/images/blog_avatar_1.png';
import BlogAvatar2 from '../../../../assets/images/blog_avatar_2.png';
import BlogAvatar3 from '../../../../assets/images/blog_avatar_3.png';
import BlogAvatar4 from '../../../../assets/images/blog_avatar_4.png';
import BlogAvatar5 from '../../../../assets/images/blog_avatar_5.png';
import BlogAvatar6 from '../../../../assets/images/blog_avatar_6.png';
import BlogAvatar7 from '../../../../assets/images/blog_avatar_7.png';
import BlogAvatar8 from '../../../../assets/images/blog_avatar_8.png';
import BlogAvatar9 from '../../../../assets/images/blog_avatar_9.png';
import BlogAvatar10 from '../../../../assets/images/blog_avatar_10.png';
import BlogAvatar11 from '../../../../assets/images/blog_avatar_11.png';
import BlogAvatar12 from '../../../../assets/images/blog_avatar_12.png';
import { ReactComponent as UserCog } from '../../../../assets/images/user-cog.svg';
import { ReactComponent as CRMIcon } from '../../../../assets/images/goal.svg';
import { ReactComponent as CMSIcon } from '../../../../assets/images/settings.svg';
import { ReactComponent as InventoryIcon } from '../../../../assets/images/shopping-basket.svg';

export const timelineData = [
    {
        type: 'blue',
        data: '2000',
        title: 'Created "Preline in React" task',
        info: 'Find more detailed insctructions here.',
    },
    {
        type: 'safron',
        data: '2000',
        title: 'Created "Preline in React" task',
        info: 'Find more detailed insctructions here.',
    },
    {
        type: 'blue',
        data: '2000',
        title: 'Created "Preline in React" task',
        info: 'Find more detailed insctructions here.',
    },
    {
        type: 'safron',
        data: '2000',
        title: 'Created "Preline in React" task',
        info: 'Find more detailed insctructions here.',
    },
];

export const teamData = [
    {
        profileimg: Team1,
        profilename: 'Alex Brown',
        profileStatus: 'Founder & CEO',
    },
    {
        profileimg: Team2,
        profilename: 'Freida Varnes',
        profileStatus: 'Engineering Manager',
    },
    {
        profileimg: Team3,
        profilename: 'Flores, Juanita',
        profileStatus: 'Product Manager',
    },
    {
        profileimg: Team4,
        profilename: 'Aileen Fullbright',
        profileStatus: 'Frontend Developer',
    },
    {
        profileimg: Team5,
        profilename: 'Black Marvin',
        profileStatus: 'Backend Developer',
    },
    {
        profileimg: Team6,
        profilename: 'Chieko Chute',
        profileStatus: 'Product Designer',
    },
    {
        profileimg: Team7,
        profilename: 'Janetta Rotolo',
        profileStatus: 'UX Researcher',
    },
    {
        profileimg: Team8,
        profilename: 'Henry, Arthur',
        profileStatus: 'Customer Success',
    },
];

export const swiperData = [
    {
        professionInfo: 'Product Designer',
        status: 'Design',
        info: 'We’re looking for a Senior Product Designer to join our team.',
    },
    {
        professionInfo: 'Engineering manager',
        status: 'Software',
        info: 'We’re looking for a Senior Product Designer to join our team.',
    },
    {
        professionInfo: 'Product Designer',
        status: 'Design',
        info: 'We’re looking for a Senior Product Designer to join our team.',
    },
    {
        professionInfo: 'Engineering manager',
        status: 'Software',
        info: 'We’re looking for a Senior Product Designer to join our team.',
    },
    {
        professionInfo: 'Product Designer',
        status: 'Design',
        info: 'We’re looking for a Senior Product Designer to join our team.',
    },
    {
        professionInfo: 'Engineering manager',
        status: 'Software',
        info: 'We’re looking for a Senior Product Designer to join our team.',
    },
];

export const blogsfirstData = [
    {
        image: BlogPost1,
        label: 'Energy',
        duration: '5 min read',
        heading: 'How to write content about your photographs',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar1,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost2,
        label: 'Science',
        duration: '5 min read',
        heading: 'How a visual artist redefines success in graphic design',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar2,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost3,
        label: 'Travel',
        duration: '5 min read',
        heading: 'Caring is the new marketing',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar3,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost4,
        label: 'Education',
        duration: '5 min read',
        heading: 'How to design your site footer like we did',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar4,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost5,
        label: 'Future',
        duration: '5 min read',
        heading: 'Lessons and insights from 8 years of Pixelgrade',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar5,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost6,
        label: 'Development',
        duration: '5 min read',
        heading: 'How to choose the right customer for your photo business?',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar6,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost7,
        label: 'Life',
        duration: '5 min read',
        heading: 'How to build a loyal community online and offline',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar7,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost8,
        label: 'Blockchain',
        duration: '5 min read',
        heading: 'Helping a local business reinvent itself',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar8,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost9,
        label: 'Innovation',
        duration: '5 min read',
        heading: 'Why choose a theme that looks good with WooCommerce',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar9,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost10,
        label: 'Startup',
        duration: '5 min read',
        heading: 'Starting your traveling blog with Vasco',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar10,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost11,
        label: 'Business',
        duration: '5 min read',
        heading: 'How to optimize images in WordPress for faster loading (complete guide)',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar11,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost12,
        label: 'Team',
        duration: '5 min read',
        heading: 'The Importance of Sleep for Optimal Health',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar12,
        profileName: 'By Lauren Waller',
    },
];

export const BlogPostData = [
    {
        image: BlogPost1,
        label: 'Energy',
        duration: '5 min read',
        heading: 'How to write content about your photographs',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar1,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost2,
        label: 'Science',
        duration: '5 min read',
        heading: 'How a visual artist redefines success in graphic design',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar2,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost3,
        label: 'Travel',
        duration: '5 min read',
        heading: 'Caring is the new marketing',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar3,
        profileName: 'By Lauren Waller',
    },
    {
        image: BlogPost4,
        label: 'Education',
        duration: '5 min read',
        heading: 'How to design your site footer like we did',
        describe: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        profileIcon: BlogAvatar4,
        profileName: 'By Lauren Waller',
    },
];

export const comparefeaturePriceData = [
    {
        status: '',
        title: 'Free Trial',
        price: '$0',
    },
    {
        status: 'Most popular',
        title: 'Basic',
        price: '$50',
    },
    {
        status: '',
        title: 'Standard',
        price: '$550',
    },
    {
        status: '',
        title: 'Enterprise',
        price: '$648',
    },
];

export const comparefeatureTableData = [
    {
        heading: 'Future Highlights',
        children: [
            {
                subheading: 'Advanced Inventory Management',
                statusData: ['ok', 'ok', 'ok', 'ok'],
            },
            {
                subheading: 'CRM Integration Included',
                statusData: ['ok', 'ok', 'ok', 'ok'],
            },
            {
                subheading: '24/7 Customer Support',
                statusData: ['ok', 'ok', 'ok', 'ok'],
            },
            {
                subheading: 'Customizable Reporting Features',
                statusData: ['no', 'no', 'ok', 'ok'],
            },
            {
                subheading: 'Secure Data Encryption',
                statusData: ['no', 'no', 'ok', 'ok'],
            },
        ],
    },
    {
        heading: 'Future Highlights',
        children: [
            {
                subheading: 'Advanced Inventory Management',
                statusData: ['ok', 'ok', 'ok', 'ok'],
            },
            {
                subheading: 'CRM Integration Included',
                statusData: ['ok', 'ok', 'ok', 'ok'],
            },
            {
                subheading: '24/7 Customer Support',
                statusData: ['ok', 'ok', 'ok', 'ok'],
            },
            {
                subheading: 'Customizable Reporting Features',
                statusData: ['no', 'no', 'ok', 'ok'],
            },
            {
                subheading: 'Secure Data Encryption',
                statusData: ['no', 'no', 'ok', 'ok'],
            },
        ],
    },
    {
        heading: 'Future Highlights',
        children: [
            {
                subheading: 'Advanced Inventory Management',
                statusData: ['ok', 'ok', 'ok', 'ok'],
            },
            {
                subheading: 'CRM Integration Included',
                statusData: ['ok', 'ok', 'ok', 'ok'],
            },
            {
                subheading: '24/7 Customer Support',
                statusData: ['ok', 'ok', 'ok', 'ok'],
            },
            {
                subheading: 'Customizable Reporting Features',
                statusData: ['no', 'no', 'ok', 'ok'],
            },
            {
                subheading: 'Secure Data Encryption',
                statusData: ['no', 'no', 'ok', 'ok'],
            },
        ],
    },
];

export const servicesData = [
    {
        icon: <UserCog />,
        title: 'HRM',
        detail: 'HRM ensures employee productivity and satisfaction by aligning organizational goals with individual needs.',
    },
    {
        icon: <CRMIcon />,
        title: 'CRM',
        detail: 'CRM, or Customer Relationship Management, enhances customer satisfaction and loyalty.',
    },
    {
        icon: <CMSIcon />,
        title: 'CMS',
        detail: 'CMS, or Content Management System, simplifies the creation, management, and publication of digital content on websites.',
    },
    {
        icon: <InventoryIcon />,
        title: 'Inventory Management',
        detail: 'CMS, or Content Management System, simplifies the creation, management, and publication of digital content on websites.',
    },
    {
        icon: <CMSIcon />,
        title: 'Accounting',
        detail: 'CMS, or Content Management System, simplifies the creation, management, and publication of digital content on websites.',
    },
    {
        icon: <InventoryIcon />,
        title: 'Finance',
        detail: 'CMS, or Content Management System, simplifies the creation, management, and publication of digital content on websites.',
    },
];
