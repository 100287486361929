import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { FileUploader } from 'react-drag-drop-files';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { Input, Select } from '../common';
import { registrationInitialData, registrationValidationSchema } from '../../pages/SignUp/signupValidationSchema';
import { registrationService } from '../../../services/registration.service';
import { COMPANY_TYPE_LIST, EMPLOYEE_NUMBERS_LIST, HEARD_ABOUT_US_LIST } from '../../constants/Contants.common';
import locales from '../../constants/en.json';

function RegistrationPage({ industryDataList, onNextClick }) {
    const fileTypes = ['JPG', 'PNG', 'GIF'];
    const [file, setFile] = useState(null);
    const [newdata, setNewData] = useState({
        companyname: '',
        industryselectedData: '',
        companyTypeData: '',
        countryselectedData: '',
        address: '',
        tin: '',
        vat: '',
        employeeNumber: '',
        heardValue: '',
        multibranch: '',
        termscondition: false,
        subscribeNews: false,
    });

    const { values, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: registrationInitialData,
        validationSchema: registrationValidationSchema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: (values, action) => {
            handleRegistration(values);
            action.resetForm();
        },
    });

    const handlefileChange = file => {
        setFile(file);
    };

    const handleRegistration = () => {
        const userid = JSON.parse(localStorage.getItem('profileData')).userId;
        const formData = {
            userid: userid,
            companyname: newdata.companyname,
            industryselectedData: newdata.industryselectedData,
            companyTypeData: newdata.companyTypeData,
            countryselectedData: newdata.countryselectedData,
            address: newdata.address,
            tin: newdata.tin,
            vat: newdata.vat,
            file: file,
            employeeNumber: newdata.employeeNumber,
            heardValue: newdata.heardValue,
            multibranch: newdata.multibranch,
            subscribeNews: newdata.subscribeNews,
        };
        registrationService(formData).then(response => {
            if (response?.status) {
                onNextClick();
            }
        });
    };
    useEffect(() => {
        document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <React.Fragment>
            <div className='registrationPage_wrapper'>
                <div className='formContainer'>
                    <h2 className='title'>{locales.registration_title}</h2>
                    <div className='body_container'>
                        <div className='input_flexbox'>
                            <Input
                                label={'Company Name'}
                                type={'text'}
                                name={'company_name'}
                                id={'company_name'}
                                wrapperClass={'col12'}
                                value={values.company_name}
                                onChange={handleChange}
                                isRequired
                                error={errors.company_name}
                                touched={touched.company_name}
                            />
                        </div>
                        <div className='input_flexbox'>
                            <Select
                                label={'Industry'}
                                name={'industryId'}
                                options={industryDataList}
                                wrapperClass={'col6'}
                                value={values.industryId}
                                onChange={handleChange}
                                isRequired
                                error={errors.industryId}
                                touched={touched.industryId}
                            />
                            <Select
                                label={'Company Type'}
                                name={'company_type'}
                                options={COMPANY_TYPE_LIST}
                                wrapperClass={'col6'}
                                value={values.company_type}
                                onChange={handleChange}
                                isRequired
                                error={errors.company_type}
                                touched={touched.company_type}
                            />
                        </div>
                        <div className='input_flexbox'>
                            <Select
                                label={'Country'}
                                name={'countryId'}
                                options={COMPANY_TYPE_LIST}
                                wrapperClass={'col6'}
                                value={values.countryId}
                                onChange={handleChange}
                                isRequired
                                error={errors.countryId}
                                touched={touched.countryId}
                            />
                            <Input
                                label={'Address'}
                                type={'text'}
                                name={'address'}
                                id={'address'}
                                wrapperClass={'col6'}
                                value={values.address}
                                onChange={handleChange}
                                isRequired
                                error={errors.address}
                                touched={touched.address}
                            />
                        </div>
                        <div className='input_flexbox'>
                            <Input
                                label={'TIN'}
                                type={'text'}
                                name={'tin'}
                                id={'tin'}
                                wrapperClass={'col6'}
                                value={values.tin}
                                onChange={handleChange}
                            />
                            <Input
                                label={'VAT'}
                                type={'text'}
                                name={'vat'}
                                id={'vat'}
                                wrapperClass={'col6'}
                                value={values.vat}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='dropbox_container'>
                            <label className='label'>VAT Certification/ Business Licensee</label>
                            <div className='dropBox'>
                                <div className='content'>
                                    <span className='up_title'>
                                        Drop your files here or <span className='bluehighlight'>browse</span>
                                    </span>
                                    <span className='soft_title'>Maximum size: 50MB</span>
                                </div>
                                <FileUploader maxSize={50} classes='fileUploader' handleChange={handlefileChange} name='file' types={fileTypes} />
                            </div>
                        </div>

                        <div className='input_flexbox'>
                            <Select
                                label={'Employee Number'}
                                name={'employee_number'}
                                options={EMPLOYEE_NUMBERS_LIST}
                                wrapperClass={'col6'}
                                value={values.employee_number}
                                onChange={handleChange}
                            />
                            <Select
                                label={'How do you heard about this?'}
                                name={'heard_about_us'}
                                options={HEARD_ABOUT_US_LIST}
                                wrapperClass={'col6'}
                                value={values.heard_about_us}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='bottom_inputOption'>
                            <FormControl className='switch_radio'>
                                <FormLabel id='demo-radio-buttons-group-label'>Do you have multi branches</FormLabel>
                                <RadioGroup
                                    aria-labelledby='demo-radio-buttons-group-label'
                                    defaultValue='female'
                                    name='radio-buttons-group'
                                    value={newdata.multibranch}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value='Yes' control={<Radio name='multibranch' />} label='Yes' />
                                    <FormControlLabel value='No' control={<Radio name='multibranch' />} label='No' />
                                </RadioGroup>
                            </FormControl>
                            <FormGroup className='checkBox_block'>
                                <FormControlLabel
                                    control={<Checkbox checked={newdata.termscondition} name='termscondition' onChange={handleChange} />}
                                    label='By creating the account you agree terms and conditions'
                                />
                            </FormGroup>
                            <FormGroup className='checkBox_block'>
                                <FormControlLabel
                                    control={<Checkbox checked={newdata.subscribeNews} name='subscribeNews' onChange={handleChange} />}
                                    label='Subscribe me to the newsletter'
                                />
                            </FormGroup>
                        </div>
                        <div className='submit_action'>
                            <button
                                className='continue_btn'
                                disabled={
                                    newdata.companyname !== '' &&
                                    newdata.industryselectedData !== '' &&
                                    newdata.companyTypeData !== '' &&
                                    newdata.countryselectedData !== '' &&
                                    newdata.address !== '' &&
                                    newdata.termscondition !== false
                                        ? false
                                        : true
                                }
                                onClick={() => handleRegistration()}
                            >
                                {locales.continue_to_subscription}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default RegistrationPage;
