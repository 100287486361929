export const purchaseListData =[
    {
        'Purchase Type': 'Bonus Purchase',
        'Transaction ID':'TXN-20240915-001',
        'Purchase Number':'PO12345',
        'Purchase Date':'10/28/12',
        'Invoice Number':'INV-2024-00123',
        'Invoice Date':'10/28/12',
    },
    {
        'Purchase Type': 'Net Price Purchase',
        'Transaction ID':'INV-5678-09SEP24',
        'Purchase Number':'PO23456',
        'Purchase Date':'5/19/12',
        'Invoice Number':'INV-2024-00124',
        'Invoice Date':'5/19/12',
    },
    {
        'Purchase Type': 'Bonus Purchase',
        'Transaction ID':'TX-2024-000245',
        'Purchase Number':'PO98765',
        'Purchase Date':'8/15/17',
        'Invoice Number':'INV-2024-00125',
        'Invoice Date':'8/15/17',
    },
    {
        'Purchase Type': 'Net Price Purchase',
        'Transaction ID':'TRX-09152024-A1',
        'Purchase Number':'PO09876',
        'Purchase Date':'8/21/15',
        'Invoice Number':'INV-2024-00126',
        'Invoice Date':'8/21/15',
    } ,
    {
        'Purchase Type': 'Net Price Purchase',
        'Transaction ID':'INV-5678-09SEP24',
        'Purchase Number':'PO23456',
        'Purchase Date':'5/19/12',
        'Invoice Number':'INV-2024-00124',
        'Invoice Date':'5/19/12',
    },
    {
        'Purchase Type': 'Bonus Purchase',
        'Transaction ID':'TX-2024-000245',
        'Purchase Number':'PO98765',
        'Purchase Date':'8/15/17',
        'Invoice Number':'INV-2024-00125',
        'Invoice Date':'8/15/17',
    },
    {
        'Purchase Type': 'Net Price Purchase',
        'Transaction ID':'TRX-09152024-A1',
        'Purchase Number':'PO09876',
        'Purchase Date':'8/21/15',
        'Invoice Number':'INV-2024-00126',
        'Invoice Date':'8/21/15',
    }
]

export const tableHeader = [
    'Purchase Type',
    'Transaction ID',
    'Purchase Number',
    'Purchase Date',
    'Invoice Number',
    "Invoice Date",
]
export const updateHeader = [
    { name: 'Purchase Type', id: '1', visible:true },
    { name: 'Transaction ID', id: '2', visible:true },
    { name: 'Purchase Number', id: '3', visible:true },
    { name: 'Purchase Date', id: '4', visible:true },
    { name: 'Invoice Number', id: '5', visible:true },
    { name: 'Invoice Date', id: '6', visible:true }
]

export const EditTableData = [
    {
        code: 'M12345',
        itemName: 'Paracetamol',
        BuyRate: '14',
        qty: '583',
        invRate: '15',
        batchNo: '5830',
        exp: '07/24',
        disc: '15%',
        mrp: '$500',
        sPrice: '$500',
        margin: '10%',
        cost: '$450',
    },
    {
        code: 'M54321',
        itemName: 'Ibuprofen',
        BuyRate: '10',
        qty: '100',
        invRate: '12',
        batchNo: '1234',
        exp: '05/23',
        disc: '10%',
        mrp: '$300',
        sPrice: '$300',
        margin: '15%',
        cost: '$250',
    },
    {
        code: 'M98765',
        itemName: 'Aspirin',
        BuyRate: '5',
        qty: '200',
        invRate: '10',
        batchNo: '6789',
        exp: '12/23',
        disc: '5%',
        mrp: '$100',
        sPrice: '$110',
        margin: '8%',
        cost: '$90',
    }
];

export const ProductMasterTabs = ['Purchase List', 'Drafts'];
export const DELETE_MODAL_TITLE ='Confirm Purchase Transaction Deletion';
export const DELETE_MODAL_MSG ='Are you sure you want to delete this purchase transaction? This action cannot be undone';

export const data = {
    "status": true,
    "message": "Records retrieved successfully",
    "data": {
    "purchaseTransaction": [
        {
            "_id": "67148e10abd6b1d4ee6afd6d",
            "vendor": "66d331fb14fc1dce383e2589",
            "purchaseType": "Net Price Purchase",
            "transactionId": "TX-2024105016",
            "invoiceNumber": "INV123456",
            "invoiceDate": "2024-04-08T00:00:00.000Z",
            "purchaseNumber": "PUR123858",
            "purchaseDate": "2024-04-08",
            "purchaseBy": "manufacturer",
            "purchaseById": "66e149e32b39eead37460a91",
            "discountPercentage": 10,
            "subTotal": 100,
            "vatPercentage": 5,
            "discount": 5,
            "grandTotal": 100,
            "items": [
                {
                    "bonusSchema": "6712722e38e279d3facce6ea",
                    "product": {
                        "_id": "67126b0060db75071916c327",
                        "productCode": "SW99",
                        "name": "Testing 2o4"
                    },
                    "quantity": 10,
                    "buyingPrice": 100,
                    "invPrice": 120,
                    "batchNumber": "45678",
                    "expiryDate": "2024-10-20T00:00:00.000Z",
                    "discountPercentage": 5,
                    "mrp": 100,
                    "sellingPrice": 200,
                    "margin": 12,
                    "cost": 205,
                    "_id": "67148e10abd6b1d4ee6afd6e"
                },
                {
                    "bonusSchema": "6712722e38e279d3facce6ea",
                    "product": {
                        "_id": "67126b0060db75071916c327",
                        "productCode": "SW99",
                        "name": "Testing 2o4"
                    },
                    "quantity": 40,
                    "buyingPrice": 120,
                    "invPrice": 140,
                    "batchNumber": "45679",
                    "expiryDate": "2024-10-20T00:00:00.000Z",
                    "discountPercentage": 5,
                    "mrp": 102,
                    "sellingPrice": 210,
                    "margin": 12,
                    "cost": 204,
                    "_id": "67148e10abd6b1d4ee6afd6f"
                }
            ],
            "isDraft": false,
            "createdAt": "2024-10-20T04:58:56.486Z",
            "updatedAt": "2024-10-20T04:58:56.486Z",
            "__v": 0
        }
    ],
        "total": 1,
        "limit": 10,
        "page": 1,
        "totalPages": 1,
        "totalRecords": 1
}

}

export const vendorNamesData = {
    "status": true,
    "message": "Date fetched",
    "data": {
        "purchaeType": [],
        "vendorName": [
            {
                "_id": "66f18826b4ce076cbb9e45ac",
                "name": "Ankit Kumar",
                "type": "manufacturer"
            },
            {
                "_id": "66d331fb14fc1dce383e2589",
                "name": "Gaurav Manufacturing unit",
                "type": "manufacturer"
            },
            {
                "_id": "66f92d5704c2c50742898437",
                "name": "Test Attempt1",
                "type": "manufacturer"
            },
            {
                "_id": "66f9309804c2c5074289851e",
                "name": "MNP",
                "type": "manufacturer"
            },
            {
                "_id": "66f930f504c2c50742898581",
                "name": "YUKTI",
                "type": "manufacturer"
            },
            {
                "_id": "66f9313404c2c507428985b5",
                "name": "M22222",
                "type": "manufacturer"
            },
            {
                "_id": "66fa55434faf415888e1626f",
                "name": "Manufacture unit A",
                "type": "manufacturer"
            },
            {
                "_id": "66fa94121faad69ad0d06391",
                "name": "Dffdf",
                "type": "manufacturer"
            },
            {
                "_id": "66faafaf1faad69ad0d08268",
                "name": "Dsd",
                "type": "manufacturer"
            },
            {
                "_id": "66fad33909aa02adb1547ce8",
                "name": "Manufacture",
                "type": "manufacturer"
            },
            {
                "_id": "66fc0c2509aa02adb1548199",
                "name": "Fdfd",
                "type": "manufacturer"
            },
            {
                "_id": "66fc0e1309aa02adb1548217",
                "name": "A",
                "type": "manufacturer"
            },
            {
                "_id": "66fcd9d1321c7f9dfcfc0746",
                "name": "Hhjh",
                "type": "manufacturer"
            },
            {
                "_id": "67060435f70886f642f62da9",
                "name": "QWERTY",
                "type": "manufacturer"
            },
            {
                "_id": "6708fdfdd1480b4c256dd5dc",
                "name": "Dfddf",
                "type": "manufacturer"
            },
            {
                "_id": "670e76d715786d560703d840",
                "name": "Test Mnufacturer edited",
                "type": "manufacturer"
            },
            {
                "_id": "66fa86751faad69ad0d04b96",
                "name": "Supplier-2",
                "type": "supplier"
            },
            {
                "_id": "6706036bf70886f642f62d6e",
                "name": "Gaurav",
                "type": "supplier"
            },
            {
                "_id": "6706060ef70886f642f62f17",
                "name": "Test",
                "type": "supplier"
            },
            {
                "_id": "670c08511c5323d8884a4ab7",
                "name": "Dfdfddss",
                "type": "supplier"
            },
            {
                "_id": "670c089a1c5323d8884a4ad9",
                "name": "Dfdfdfdfdfd",
                "type": "supplier"
            }
        ]
    }
}

export const selectOptionsPurchaseType = [
    { id: 'BonusPurchase', value: 'Bonus Purchase' },
    { id: 'NetPricePurchase', value: 'Net Price Purchase' },
    { id: 'DiscountPurchase', value: 'Discount Purchase' },
];