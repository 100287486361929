import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import AddNewLedger from './AddNewLedger/AddNewLedger';
import CommonTable from '../../components/common/CommonTable/CommonTable';
import SuccessModal from '../../components/common/CommonSuccessModal/SuccessModal';
import Pagination from '../../components/common/PaginationLayout';
import DeleteModal from '../../components/common/CommonDeleteModal/CommonDeleteModal';
import { axiosClient } from '../../../services/axiosClient';
import DraftList from './DraftList';
import { ReactComponent as UserPlus } from '../../../assets/images/user-plus.svg';
import { ReactComponent as Download } from '../../../assets/images/download.svg';
import { ReactComponent as Setting } from '../../../assets/images/settings.svg';
import { ReactComponent as List } from '../../../assets/images/list.svg';
import { ReactComponent as UserSquare } from '../../../assets/images/user-square-2.svg';
import './LedgerMaster.scss';

function LedgerMaster({ breadcrumbUpdateData, updateBreadCrumb }) {
    const [value, setValue] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [addLedger, setAddLedger] = useState(false);
    const [toggleView, setToggleView] = useState('Card');
    const [ledgerListData, setLedgerListData] = useState(null);
    const [tableFilterHeader, setTableFilterHeader] = useState([
        'ledgerCode',
        'companyName',
        'accountStatus',
        'ledgerCategory',
        'glsNumber',
        'tradeLicenceNumber',
        'healthLicenceNumber',
    ]);
    const [tableHeaderData, setTableHeaderData] = useState([
        'Ledger Code',
        'Company Name',
        'Status',
        'Ledger Category',
        'GLS Number',
        'Trade Licence Number',
        'health Licence Number',
    ]);
    const [ledgerTableData, setLedgerTableData] = useState('');
    const [indexData, setIndexData] = useState('');
    const [previewMode, setPreviewMode] = useState();
    const [previewData, setPreviewData] = useState('');
    const [productlistUpdate, setProductListUpdate] = useState(false);
    const [successModal, setSuccessModal] = useState('');
    const [SuccessModalMsg, setSuccessModalMsg] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [showDraftList, setShowDrafList] = useState(false);
    const [pageValue, setPageValue] = useState(1);
    const [breadcrumb, setBreadCrumb] = useState([...breadcrumbUpdateData]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteProductData, setDeleteProductData] = useState('');
    const [DeleteModalTitle, setDeleteModalTitle] = useState('');
    const [DeleteModalMsg, setDeleteModalMsg] = useState('');
    const [totalPagesData, setTotalPagesData] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const [categoriesData, setCategoriesData] = useState('');
    const [productCreateList, setProductCreateList] = useState('');
    let tableHeader = ['Ledger Code', 'Company Name', 'Status', 'Ledger Category', 'GLS Number', 'Trade Licence Number', 'health Licence Number'];
    let backuptableHeader = [
        'ledgerCode',
        'companyName',
        'accountStatus',
        'ledgerCategory',
        'glsNumber',
        'tradeLicenceNumber',
        'healthLicenceNumber',
    ];

    const handleAddNewProduct = () => {
        setEditMode(false);
        setPreviewData([]);
        setAddLedger(true);
        setProductListUpdate(false);
        setBreadCrumb([...breadcrumb, 'Add New Ledger']);
        // updateBreadCrumb(breadcrumb)
    };

    const handleBack = () => {
        setPreviewData([]);
        setAddLedger(false);
        setEditMode(false);
        setPreviewData([]);
        setPreviewMode(false);
        handleBradCrumb();
    };

    const handleBradCrumb = () => {
        let removeLastBreadcrumb = breadcrumb.filter(item => {
            if (item !== 'Add New Ledger' && item !== 'Edit Ledger') {
                return item;
            }
        });
        setBreadCrumb([...removeLastBreadcrumb]);
    };

    useEffect(() => {
        updateBreadCrumb(breadcrumb);
    }, [breadcrumb]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        // if(showDraftList){
        //     setShowDrafList(false)
        // }
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleToggleView = event => {
        setToggleView(event);
    };

    const handleLedgerList = async event => {
        setShowLoader(true)
        const accessToken = `Bearer ${sessionStorage.accessToken} `;
        try {
            let response = await axiosClient.post(
                `admin/ledger/list`,
                JSON.stringify({ search: event?.data, isDraft: value == 0 ? false : true, page: event?.pageNo ? 1 : pageValue, limit: 10 }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-via-device': true,
                        Authorization: accessToken,
                    },
                },
            );
            if (response.status == 200) {
                setShowLoader(false)
                setLedgerListData(response?.data?.data?.ledger);
                setTotalPagesData(response?.data?.data?.totalPages);
            }
        } catch (error) {
            setShowLoader(false)
            console.log('error', error);
        }
    };

    const handleProductCreateList = async event => {
        const accessToken = `Bearer ${sessionStorage.accessToken} `;
        try {
            let response = await axiosClient.get(`admin/product/create/list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-via-device': true,
                    Authorization: accessToken,
                },
            });
            if (response.status == 200) {
                setProductCreateList(response?.data?.data);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        handleLedgerList();
    }, []);

    useEffect(() => {
        ledgerListingData();
    }, [ledgerListData, tableFilterHeader]);

    useEffect(() => {
        handleCategoryAll();
        handleProductCreateList();
    }, []);

    const ledgerListingData = () => {
        if (ledgerListData && ledgerListData.length > 0) {
            // Define a filter condition, for example, keep only keys where the value is not 'inactive'
            const filterCondition = obj => {
                // You can change the condition here
                const filteredObject = Object.keys(obj)
                    .filter(key => tableFilterHeader.includes(key)) // filter out 'inactive' status
                    .reduce((acc, key) => {
                        acc[key] = obj[key];
                        return acc;
                    }, {});

                return filteredObject;
            };

            const filteredArray = ledgerListData.map(obj => {
                const filteredObj = filterCondition(obj);

                // If no properties are left after filtering, return an empty object
                return Object.keys(filteredObj).length > 0 ? filteredObj : {};
            });

            // Function to reorder object properties
            const rearrangeArray =
                filteredArray &&
                filteredArray.map((obj, index) => ({
                    ledgerCode: obj.ledgerCode,
                    companyName: obj.companyName,
                    accountStatus: obj.accountStatus,
                    ledgerCategory: obj.ledgerCategory,
                    glsNumber: obj.glsNumber,
                    tradeLicenceNumber: obj.tradeLicenceNumber,
                    healthLicenceNumber: obj.healthLicenceNumber,
                    ...ledgerListData[index],
                }));
            setLedgerTableData(rearrangeArray);
        } else if (ledgerListData && ledgerListData.length == 0) {
            setLedgerTableData([]);
        }
    };

    const handleCategoryAll = async event => {
        const accessToken = `Bearer ${sessionStorage.accessToken} `;
        try {
            let response = await axiosClient.get(`admin/category/all`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-via-device': true,
                    Authorization: accessToken,
                },
            });
            if (response.status == 200) {
                setCategoriesData(response?.data?.data?.categories);
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    const handleDataPopulate = index => {
        let filter;
        filter = ledgerTableData.filter(item => {
            if (item.ledgerCode == index) {
                return item;
            }
        });
        if(filter && filter.length>0){
        setPreviewData(filter[0]);
        setPreviewMode(true);
        setAddLedger(true);
        }
    };

    const handleEditDataPopulate = index => {
        let filter;
        filter = ledgerTableData.filter(item => {
            if (item._id == index) {
                return item;
            }
        });
        setPreviewData(filter[0]);
        setPreviewMode(false);
        setAddLedger(true);
        setBreadCrumb([...breadcrumb, 'Edit Ledger']);
        handleRemovePreview();
    };

    const handleEditDraftData = data => {
        let filter;
        filter = ledgerTableData.filter((item, index) => {
            if (index == data) {
                return item;
            }
        });
        setPreviewData(filter[0]);
        setPreviewMode(false);
        setAddLedger(true);
        handleRemovePreview();
    };

    const handleRemovePreview = () => {
        setPreviewMode(false);
        setEditMode(true);
    };

    const handleSuccessModalClose = () => {
        setProductListUpdate(true);
        setAddLedger(false);
        handleBradCrumb();
        setPreviewData([]);
    };

    useEffect(() => {
        if (productlistUpdate == true || successModal == true || showDraftList == true || addLedger == true || pageValue !== 0 || value !== '') {
            handleLedgerList();
            handleCategoryAll();
            handleProductCreateList();
        }
    }, [productlistUpdate, successModal, addLedger, value, showDraftList, pageValue]);

    const handleDeleteLedgerData = async event => {
        const accessToken = `Bearer ${sessionStorage.accessToken} `;
        try {
            let response = await axiosClient.post(`admin/ledger/delete`, JSON.stringify({ _id: event }), {
                headers: {
                    'Content-Type': 'application/json',
                    'x-via-device': true,
                    Authorization: accessToken,
                },
            });
            if (response.status == 200) {
                setSuccessModal(true);
                setSuccessModalMsg(response?.data?.message);
                setDeleteProductData('');
                setDeleteModal(false);
                // setDraftListData( response?.data?.data?.products)
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleDeleteSuccessModalClose = () => {
        setSuccessModal(false);
        setSuccessModalMsg('');
        setPreviewData([]);
        handleBradCrumb();
    };

    const handleDraftSuccessPopUpclose = () => {
        setAddLedger(false);
        // setValue(1);
        setShowDrafList(true);
        handleBradCrumb();
    };

    const handleDeleteDraft = event => {
        setDeleteProductData(event);
        setDeleteModal(true);
        setDeleteModalTitle('Confirm Draft Deletion');
        setDeleteModalMsg('Are you sure you want to delete this draft? This action cannot be undone');
    };

    const handlePagination = event => {
        setPageValue(event);
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handleProductDelete = e => {
        setDeleteProductData(e);
        setDeleteModal(true);
        setDeleteModalTitle('Confirm Product Deletion');
        setDeleteModalMsg('Are you sure you want to delete this product? This action cannot be undone');
    };

    const handleDeleteData = () => {
        handleDeleteLedgerData(deleteProductData);
    };

    const handlesearch = event => {
        setSearchVal(event.target.value);
        if (event.target.value == '') {
            handleLedgerList({ pageNo: '', data: event.target.value });
        } else {
            handleLedgerList({ pageNo: 1, data: event.target.value });
        }
    };

    useEffect(() => {
        handlePagination(1);
    }, [value]);

    const ProductMasterTabs = ['Ledger', 'Drafts'];

    const handleManageFilter = event => {
        if (event && event.length > 0) {
            let filterIndex = [];
            let tableHeaderArray = [];
            let tableBodyArray = [];
            event.map(item => {
                filterIndex.push(tableHeader.indexOf(item));
            });
            if (filterIndex && filterIndex.length > 0) {
                tableHeaderArray = tableHeader.filter((item, index) => {
                    if (filterIndex.includes(index)) {
                        return item;
                    }
                });

                tableBodyArray = backuptableHeader.filter((item, index) => {
                    if (filterIndex.includes(index)) {
                        return item;
                    }
                });
                setTableHeaderData(tableHeaderArray);
                setTableFilterHeader(tableBodyArray);
            }
        }
    };

    return (
        <React.Fragment>
            <div className={`ledgerMaster_container ${editMode ? 'editMode' : ''}`}>
                {addLedger ? (
                    <AddNewLedger
                        successModalClose={() => handleSuccessModalClose()}
                        preview={previewMode}
                        removePreviewMode={() => handleRemovePreview()}
                        previewData={previewData}
                        back={handleBack}
                        EditData={editMode}
                        draftSuccessPopUpClose={() => handleDraftSuccessPopUpclose()}
                        categoriesAllData={categoriesData}
                        productCreateListData={productCreateList}
                        editMode={editMode}
                    />
                ) : (
                    <div className='ledgerMaster_content'>
                        <div className='headerFlexbox'>
                            <h5 className='title'>Ledger</h5>
                            <button className='addnewProduct' onClick={() => handleAddNewProduct()}>
                                <span className='icon'>
                                    <UserPlus />
                                </span>
                                Create New Ledger
                            </button>
                        </div>
                        <div className='contentSection'>
                            <div className='head-flexbox'>
                                <input
                                    type='text'
                                    className='searchBox'
                                    placeholder='Search Ledger'
                                    value={searchVal}
                                    onChange={handlesearch}
                                ></input>
                            </div>
                            <div className='ledgerMasterListingTabs'>
                                <Box className='tabsContainer' sx={{ width: '100%' }}>
                                    <Box className='tabFlexContainer'>
                                        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                                            {ProductMasterTabs &&
                                                ProductMasterTabs.length > 0 &&
                                                ProductMasterTabs.map((label, index) => {
                                                    return <Tab label={label} {...a11yProps(index)} />;
                                                })}
                                        </Tabs>
                                    </Box>
                                    <CustomTabPanel value={value} index={0} className='tabContentContainer'>
                                        {showLoader ? (
                                            <CircularProgress style={{ width: '100%' }} />
                                        ) : (
                                            <div className='table_container'>
                                                <CommonTable
                                                    deleteProductData={e => handleProductDelete(e)}
                                                    dataEditPopulate={e => handleEditDataPopulate(e)}
                                                    dataPopulate={e => handleDataPopulate(e)}
                                                    header={tableHeaderData}
                                                    tableBodyData={ledgerTableData}
                                                    tableFilterHeader={tableFilterHeader}
                                                    copyHeaderItem={[]}
                                                    // moreVertical={["Ledger Category"]}
                                                    pagerender='ledgerMaster'
                                                    filterSave={data => handleManageFilter(data)}
                                                    filterDrawerData={tableHeader}
                                                    showEditAction={true}
                                                    showDeleteAction={true}
                                                />
                                            </div>
                                        )}
                                        {ledgerTableData ? (
                                            <Pagination totalPages={totalPagesData} pageNo={pageValue} paginationSet={e => handlePagination(e)} />
                                        ) : (
                                            ''
                                        )}
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1} className='tabContentContainer'>
                                        <DraftList
                                            draftData={ledgerTableData}
                                            deleteDataPopulate={e => handleDeleteDraft(e)}
                                            editDataPopulate={e => handleEditDraftData(e)}
                                        />
                                        {ledgerTableData ? (
                                            <Pagination totalPages={totalPagesData} pageNo={pageValue} paginationSet={e => handlePagination(e)} />
                                        ) : (
                                            ''
                                        )}
                                    </CustomTabPanel>
                                </Box>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {successModal && (
                <SuccessModal
                    handleSuccessClose={handleDeleteSuccessModalClose}
                    SuccessPopUp={successModal}
                    SuccessTitle={SuccessModalMsg}
                    // SuccessMsg={SuccessModalMsg}
                />
            )}
            {deleteModal && (
                <DeleteModal
                    handleDeleteClose={handleDeleteModalClose}
                    DeletePopUp={deleteModal}
                    DeleteModalTitle={DeleteModalTitle}
                    DeleteModalMsg={DeleteModalMsg}
                    handleDeleteConfirm={handleDeleteData}
                />
            )}
        </React.Fragment>
    );
}

export default LedgerMaster;
