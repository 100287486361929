import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Store from './redux/Store'
import HomePage from './js/pages/Home/Home';
import SignIn from './js/pages/SignIn/SignIn';
import SignUp from './js/pages/SignUp/SignUp';
import Header from './js/components/Header/Header';
import Footer from './js/components/Footer/Footer';
import AboutUs from './js/pages/WelcomePages/AboutUs/AboutUs';
import Services from './js/pages/WelcomePages/Services/Services';
import Pricing from './js/pages/WelcomePages/Pricing/Pricing';
import Product from './js/pages/WelcomePages/Product/Product';
import Carrer from './js/pages/WelcomePages/Carrers/Carrers';
import Blog from './js/pages/WelcomePages/Blog/Blog';
import ContactUs from './js/pages/WelcomePages/Contactus/Contactus';
import JobSingle from './js/pages/WelcomePages/JobSingle/JobSingle';
import Article from './js/pages/WelcomePages/Article/Article';
import POS from './js/pages/WelcomePages/POS/POS';
import PageNotFound from './js/pages/PageNotFound/PageNotFound';
import PrivateRoutes from './js/components/common/PrivateRoutes';
import AdminDashboard from './js/pages/AdminDashboard/AdminDashboard';
import { SHOW_HEADER_PATHS } from './js/constants/Contants.common';
import './style/style.scss';

function App() {
    let location = useLocation();
    window.store = Store;
    const showHeader = () => {
        if (location.pathname === '/' || SHOW_HEADER_PATHS.includes(location.pathname)) {
            return true;
        }
        return false;
    };

    return (
        <>
            {showHeader() && <Header />}
            <Routes>
                {/* Private Routes */}
                {/* <Route element={<PrivateRoutes />}> */}
                    <Route path='/dashboard/*' element={<AdminDashboard />} />
                {/* </Route> */}
                {/* Public Routes */}
                <Route path='/' element={<HomePage />} />
                <Route path='/signup' element={<SignUp />} />
                <Route path='/login' element={<SignIn />} />
                <Route path='/aboutus' element={<AboutUs />} />
                <Route path='/services' element={<Services />} />
                <Route path='/pricing' element={<Pricing />} />
                <Route path='/products' element={<Product />} />
                <Route path='/carrer' element={<Carrer />} />
                <Route path='/blog' element={<Blog />} />
                <Route path='/contactus' element={<ContactUs />} />
                <Route path='/jobsingle' element={<JobSingle />} />
                <Route path='/article' element={<Article />} />
                <Route path='/pos' element={<POS />} />
                <Route path='/*' exact={true} element={<PageNotFound />} />
            </Routes>
            {showHeader() && <Footer />}
        </>
    );
}

export default App;
