import React from 'react';

export const dateExtract = (timestamp )=>{
    const dateObj = new Date(timestamp);

    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = String(dateObj.getFullYear()).slice(-2);  // Get the last two digits of the year

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate
}

export const updateColumnBodyData = (tableHeaderData, purchaseTableData)=>{
    // Step 1: Get keys where visible is false
    const hiddenKeys = tableHeaderData
        .filter(item => !item.visible) // Filter items where visible is false
        .map(item => item.name); // Extract the names of those keys

// Step 2: Filter the data array
    const filteredData = purchaseTableData.length>0 && purchaseTableData.map(entry => {
        const newEntry = { ...entry };
        hiddenKeys.forEach(key => {
            delete newEntry[key]; // Remove the key if it's in the hiddenKeys array
        });
        return newEntry;
    });
    return filteredData
}
