import { axiosAuthenticatedClient } from './axiosClient';

export const UserSaveList = async () => {
    return await axiosAuthenticatedClient
        .get(`admin/vendor/userSaveList`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const UserList = async (searchTerm = '') => {
    return await axiosAuthenticatedClient
        .post(
            `admin/vendor/userList`,
            JSON.stringify({
                search: searchTerm,
                filter: [],
                columnList: [],
                page: '1',
                limit: '10',
            }),
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const UserListSearch = async searchValue => {
    return await axiosAuthenticatedClient
        .post(
            `admin/vendor/userList/search`,
            JSON.stringify({
                search: searchValue,
            }),
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const UserSave = async values => {
    return await axiosAuthenticatedClient
        .post(
            `admin/vendor/user/save`,
            JSON.stringify({
                userId: values.userId,
                role: values.role,
                status: values.status,
            }),
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const UserDelete = async userId => {
    return await axiosAuthenticatedClient
        .post(
            `admin/vendor/user/save`,
            JSON.stringify({
                userId: userId,
                role: '',
                status: 'Inactive',
            }),
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const RolePermissionSave = async (basicFormData, permissionFormData) => {
    return await axiosAuthenticatedClient
        .post(
            `admin/rolepermission/save`,
            JSON.stringify({
                name: basicFormData.role_name,
                description: basicFormData.role_description,
                permissions: permissionFormData,
            }),
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};
