import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AdminLeftNav from '../../components/AdminLeftNav/AdminLeftNav';
import Collections from '../DashboardPages/DashboardCollections';
import User from '../User/User';
import CategoryMaster from '../CategoryMaster/CategoryMaster';
import ProductMaster from '../ProductMaster/ProductMaster';
import ManufacturerMaster from '../ManufacturerMaster/ManufacturerMaster';
import Suppliers from '../Suppliers/Suppliers';
import SearchIcon from '../../../assets/images/search_icon.svg';
import BellIcon from '../../../assets/images/bell_icon.svg';
import AvtaarIcon from '../../../assets/images/avatar-large.png';
import LedgerMaster from "../Ledger/LedgerMaster";
import {getRequiredListingsService} from '../../../services/registration.service';
import { updateRequiredListings } from '../../../redux/slices/RequiredListingsSlices';
// import AdminContentWrapper from '../../Components/Admin/AdminContentWrapper/AdminContentWrapper';
// import {withRouter} from 'react-router'
import './AdminDashboard.scss';
import PurchaseList from '../Purchase';

function AdminDashboard(props) {
    const data = useSelector(state => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname = '' } = useLocation();
    let paths = [];
    const [collapsedNav, setCollapsedNav] = useState(false);
    const [MenuSelected, setMenuSelected] = useState('');
    const [breadcrumbUpdate, setBreadCrumbUpdate] = useState('');

    if (pathname.includes('/dashboard/')) {
        paths = pathname.replaceAll('/dashboard/', '').split('/');
    }

    const handleHomeBreadcrumb = (e, pathname) => {
        e?.preventDefault();
        navigate(pathname);
    };

    function toggleCollapsedNav() {
        setCollapsedNav(!collapsedNav);
    }

    const handleSelectedMenu = event => {
        setMenuSelected(event);
    };

    const handleUpdateBreadCrumb = event => {
        setBreadCrumbUpdate(event);
    };

    const handleLogout = () => {
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('loginData');
        navigate('/');
    };

    useEffect(() => {
        document.scrollingElement.scrollTop = 0;
        (async function () {
            const response = await getRequiredListingsService();
            dispatch(updateRequiredListings(response.data));
        })();
    }, []);
    console.log(paths);
    return (
        <React.Fragment>
            <div className='appContainer'>
                <AdminLeftNav
                    toggleCollapsedNav={toggleCollapsedNav}
                    handleSelectedMenu={handleSelectedMenu}
                    handleUpdateBreadCrumb={handleUpdateBreadCrumb}
                    handleLogout={handleLogout}
                    collapsedNav={collapsedNav}
                />
                <div className={`main-content-wrapper ${collapsedNav ? 'collapsedNav' : ''}`}>
                    <div className='header-wrapper'>
                        <div className='searchBlock'>
                            <input type='text' className='input_field'></input>
                            <button className='searchBtn'>
                                <img src={SearchIcon} alt='search_icon'></img>
                            </button>
                        </div>
                        <div className='company_name'>
                            {data?.vendor?.company_name || JSON.parse(sessionStorage.getItem('loginData'))?.vendor?.company_name}
                        </div>
                        <div className='notify_profile_block'>
                            <div className='flex_box'>
                                <span className='notify'>
                                    <img src={BellIcon} alt='notify_icon'></img>
                                </span>
                                <span className='profile_icon'>
                                    <img src={AvtaarIcon} alt='profile_icon'></img>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div role='presentation' className='breadcrumb_block'>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link underline='hover' href='/dashboard' onClick={e => handleHomeBreadcrumb(e, '/dashboard')}>
                                home
                            </Link>
                            {paths?.length > 0 &&
                                paths.map((data, index) => {
                                    return (
                                        <Link
                                            underline='hover'
                                            key={index + data}
                                            onClick={e => handleHomeBreadcrumb(e, '/dashboard' + (index > 0 ? `/${paths[index - 1]}/` : '/') + data)}
                                        >
                                            {data}
                                        </Link>
                                    );
                                })}
                        </Breadcrumbs>
                    </div>
                    <div className='mainContent'>
                        <Routes>
                            <Route path='/' exact={true} element={<Collections />} />
                            <Route path='users' exact={true} element={<User />} />;
                            <Route
                                path='master/categories'
                                exact={true}
                                element={<CategoryMaster updateBreadCrumb={setBreadCrumbUpdate} breadcrumbUpdateData={breadcrumbUpdate} />}
                            />
                            <Route
                                path='master/products'
                                exact={true}
                                element={<ProductMaster updateBreadCrumb={setBreadCrumbUpdate} breadcrumbUpdateData={breadcrumbUpdate} />}
                            />
                            <Route
                                path='master/manufacturers'
                                exact={true}
                                element={<ManufacturerMaster updateBreadCrumb={setBreadCrumbUpdate} breadcrumbUpdateData={breadcrumbUpdate} />}
                            />
                            <Route
                                path='master/suppliers'
                                exact={true}
                                element={<Suppliers updateBreadCrumb={setBreadCrumbUpdate} breadcrumbUpdateData={breadcrumbUpdate} />}
                            />
                              <Route
                                path='master/ledger'
                                exact={true}
                                element={<LedgerMaster updateBreadCrumb={setBreadCrumbUpdate} breadcrumbUpdateData={breadcrumbUpdate} />}
                            />
                            <Route
                                path='master/purchase'
                                exact={true}
                                element={<PurchaseList updateBreadCrumb={setBreadCrumbUpdate} breadcrumbUpdateData={breadcrumbUpdate}/>}
                            />
                        </Routes>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AdminDashboard;
