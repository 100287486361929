// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './slices/LoginServiceSlices';
import requiredListingsSlices from './slices/RequiredListingsSlices';
import userListReducer from './slices/UserListSlice';
import userSaveListReducer from './slices/UserSaveListSlice';

const store = configureStore({
    reducer: {
        requiredListings: requiredListingsSlices,
        loginData: loginReducer,
        userListData: userListReducer,
        userSaveListData: userSaveListReducer,
    },
});

export default store;
