import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Select } from '../../../components/common';
import { axiosClient } from '../../../../services/axiosClient';
import SuccessModal from '../../../components/common/CommonSuccessModal/SuccessModal';
import CommonTable from '../../../components/common/CommonTable/CommonTable';
import DeleteModal from '../../../components/common/CommonDeleteModal/CommonDeleteModal';
import React, { useEffect, useState, useCallback } from 'react';
import ErrorModal from '../../../components/common/CommonErrorModal/ErrorModal';
import { ReactComponent as PlusSquare } from '../../../../assets/images/plus-square.svg';
import Cross from '../../../../assets/images/x.svg';
import { ReactComponent as Alert } from '../../../../assets/images/alert-triangle.svg';
import { useDropzone } from 'react-dropzone';
import './AddNewLedger.scss';
import { ReactComponent as UploadIcon } from '../../../../assets/images/uploadFile.svg';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
const documentEditFormSchema = Yup.object({
    // documentType: Yup.string().required('Document Type is required.'),
    // documentNotificationAlert: Yup.string().required('Document Notification Alert is required.'),
    // manufacturer: Yup.string().required('Manufacturer is required.'),
});
const formatDate = date => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

const documentInitialValues = {
    _id: '',
    documentType: 'Tax',
    documentExpiryDate: `${new Date().getFullYear()}/${new Date().getMonth()+1}/${new Date().getDate()}`,
    documentNotificationAlert: 'None',
    documentFile: [],
};

function Document({ changeTab, EditMode, ProductCreateList, ledgerDetailData, preview, previewData, back, ledgerAllData, draftPopUpClose }) {
    const [draftClicked, setDraftClicked] = useState(false);
    const [draftButtonClick, setDraftButtonClick] = useState(false);
    const [draftsuccessModal, setDraftSuccessModal] = useState(false);
    const [SuccessMsg, setSuccessMsg] = useState('');
    const [SuccessTitle, setSuccessTitle] = useState('');
    const [showLoader, setshowLoader] = useState(false);
    const [currentTabvalue, setCurrentTabvalue] = useState(0);

    const [deleteBankData, setDeleteBankData] = useState('');
    const [DeleteModalTitle, setDeleteModalTitle] = useState('');
    const [DeleteModalMsg, setDeleteModalMsg] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [documentListData, setdocumentListData] = useState([]);
    const [editData, setEditData] = useState('');
    const [successModal, setSuccessModal] = useState('');
    const [SuccessModalMsg, setSuccessModalMsg] = useState('');
    const [isCheckFormClick, setIsCheckFormClick] = useState(false);
    const tableHeader = ['Document Type', 'Expiry Date', 'Notification Alert'];

    const fileTypes = ['JPG', 'PNG', 'GIF'];
    const [base64, setBase64] = useState('');
    const [errorModal, setErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [updateClicked, SetUpdateClicked] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadAlert, setUploadAlert] = useState(false);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [removeFile, setRemoveFile] = useState(false);
    const [addproductFormData, setAddproductFormData] = useState('');
    const [ecommerceDataState, setEcommerceDataState] = useState('');
    const [packagingData, setPackagingData] = useState('');
    const [uploadImageState, setUploadImageState] = useState('');
    const [productAddAPIcall, setProductAddAPIcall] = useState(false);
    const [editProductApiCall, setEditProductApiCall] = useState(false);
    const [editProductApiData, setEditProductApiData] = useState(false);

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const  [selectedFileName,setSelectedFileName] = useState();
    const todayDate = `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`
    const [documentExpiryDate, setDocumentExpiryDate] = useState(todayDate);
    const today = new Date();
    const maxFiles = 2; // Limit the number of files to 3
    let fileCollection = [];
    const tableFilterHeader = ['documentType', 'documentExpiryDate', 'documentNotificationAlert'];
    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik({
        initialValues: documentInitialValues,
        validationSchema: documentEditFormSchema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: (values, action) => {
            if (isCheckFormClick) {
            } else {
                handleFormSubmit(values);
            }
            // action.resetForm();
        },
    });

    useEffect(() => {
        values.documentFile = [];
        selectedFile.forEach(element => {
            values.documentFile.push(element);
            // values.documentFile.push({
            //     "files": element,
            //     "fileName": element.name,
            //     "name": `{${values.documentType}_${values.documentExpiryDate}_${element.name}}`
            //  })
        });
    }, [selectedFile]);

    const handleFormSubmit = async values => {
        setUploadAlert('');
        if (selectedFile && selectedFile.length == 0) {
            setUploadAlert('Please upload at least one image or file. Adding an image or file is required to proceed');
        } else {
            const value = [...[values], ...documentListData];
            // value.map(item => {
            //     if (item?._id?.toString().length <= 3) {
            //         delete item._id;
            //         delete item.documentId;
            //     }
            // });
            const newValue = transFormFiles(value);
            if (draftClicked) {
                let document = value; 
              handleBeforeDraftSubmit({document});
            } else {
                ledgerDetailData(newValue);
                changeTab(4);
            }
        }
    };

    const handleBeforeDraftSubmit = async values => {
        const finalValue = { ...ledgerAllData, ...values }
        const data = new FormData();
        let isuploadTrue = false;
        finalValue?.document?.forEach((docs) => {
            docs?.documentFile?.forEach((file) => {
                if (file?.name) {
                    isuploadTrue = true;
                    data.append('documentFile', file);
                }
            });
        });

        if (isuploadTrue) {
            handleUpload(data, finalValue);
        } else {
            handleDraft(finalValue);
        }

    };

    const transformFinalValue = (finalValue, fileUploadResponse) => {
        if (Object.keys(fileUploadResponse?.attachment).length > 0) {
            finalValue.attachment = [];
            Object.keys(fileUploadResponse?.attachment).forEach((key) => {
                finalValue.attachment.push(fileUploadResponse.attachment[key])
            })
        }
        if (Object.keys(fileUploadResponse.documents).length > 0) {
            finalValue?.document?.forEach((docs) => {
                docs.documentFile = [];
                Object.keys(fileUploadResponse.documents).forEach((key) => {
                    docs.documentFile.push(fileUploadResponse.documents[key]);
                });
            });
        }
        handleDraft(finalValue)
    }

    const handleUpload = async (data, finalValue) => {
        const accessToken = `Bearer ${sessionStorage.accessToken} `
        try {
            let response = await axiosClient.post(
                `admin/ledger/upload`,
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-via-device': true,
                        'Authorization': accessToken
                    },
                }

            );
            if (response.status == 200) {
                transformFinalValue(finalValue, response?.data?.data)
            }

        } catch (error) {
            console.log('error', error);
            setErrorModal(true);
            setErrorMsg(error.response.data.message);
        }
    }


    const handleTabBack = () => {
        changeTab(2);
    };

    const transFormFiles = value => {
        value.map(item => {
            item?.documentFile?.map(val => {
                // val.name = `{${item.documentType}_${item.documentExpiryDate}_${val.fileName}}`
                delete val.fileName;
                delete val.name;
            });
        });

        return value;
    };

    const handleNext = () => {
        setUploadAlert('');
        if (
            (documentListData.length === 0 && selectedFile && selectedFile.length == 0) ||
            (documentListData.length > 0 && documentListData[0]?.documentFile.length === 0 && selectedFile.length == 0)
        ) {
            setUploadAlert('Please upload at least one image or file. Adding an image or file is required to proceed');
        } else {
            if (values._id) {
                let filter;
                filter = documentListData.filter(item => {
                    if (item._id !== values._id) {
                        return item;
                    }
                });
                if (filter.length > 0) {
                    setdocumentListData(documentListData => [...filter, ...[values]]);
                } else {
                    setdocumentListData(documentListData => [...[values]]);
                }
            } else {
                values._id = documentListData.length + 1;
                setdocumentListData(documentListData => [...documentListData, ...[values]]);
            }

            // value = [...[values], ...documentListData];
            // documentListData.map(item => {
            //     if (item._id.toString().length <= 3) {
            //         delete item._id;
            //         delete item.documentId;
            //     }
            // });
            const newValue = transFormFiles(documentListData);
            ledgerDetailData(newValue);
            changeTab(4);
        }
    };

    //-------------------Document Table code --------------------------
    const handleResetForm = () => {
        Object.entries(documentInitialValues).forEach(([key, value]) => setFieldValue(key, value));
        setSelectedFile([]);
        setUploadedFile([]);
        setSelectedFileName('');
    };

    const handleChangedocumentExpiryDate =e=>{
        const date = `${e?.$D}-${e?.$M+1}-${e?.$y}`
            setFieldValue("documentExpiryDate", date);
            setDocumentExpiryDate(e);
    }

    const handleChangeDate = e => {
        if (e?.target) {
            const { name, value } = e.target;
            setFieldValue(prevState => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            const { name, value } = e;
            setFieldValue(name, value);
        }
    };

    const fileUrl = 'https://example.com/path/to/your/file.pdf'; // Replace with your file URL

    const downloadFile = () => {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = ''; // You can specify a filename here if desired
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    useEffect(() => {
        let document = previewData?.document?.length>0 ? previewData?.document : ledgerAllData?.document;
        document?.map((item)=>{
           if(item?.documentExpiryDate?.split('-')?.length===3){
             return
           }else{
         item.documentExpiryDate = format(new Date(item.documentExpiryDate), 'dd-MM-yyyy');
           }
        });
        if (document?.length > 0) {
            setdocumentListData(documentListData => [...document]);
        }
    }, []);

    const handleSave = () => {
        setUploadAlert("");
        setIsCheckFormClick(true);
        //handleSubmit();
        if (selectedFile && selectedFile.length == 0) {
            setUploadAlert('Please upload at least one image or file. Adding an image or file is required to proceed');
        } else if (Object.keys(errors).length === 0 && (values.documentType && values.documentNotificationAlert && values.documentFile)) {
            if (values._id) {
                let filter;
                filter = documentListData.filter(item => {
                    if (item._id !== values._id) {
                        return item;
                    }
                });
                if (filter.length > 0) {
                    setdocumentListData(documentListData => [...filter, ...[values]]);
                } else {
                    setdocumentListData(documentListData => [...[values]]);
                }
            } else {
                values._id = documentListData.length + 1;
                setdocumentListData(documentListData => [...documentListData, ...[values]]);
            }

            handleResetForm();
        }
    };
    const handleBankDelete = e => {
        //setDeleteBankData(e);
        // setDeleteModal(true);
        // setDeleteModalTitle('Confirm Product Deletion');
        // setDeleteModalMsg('Are you sure you want to delete this product? This action cannot be undone');
        handleDeleteData(e);
    };

    const handleEditDataPopulate = id => {
        let filter;
        filter = documentListData.filter(item => {
            if (item._id == id) {
                return item;
            }
        });
        setSelectedFile([...filter[0]?.documentFile]);
        Object.entries(filter[0]).forEach(([key, value]) => setFieldValue(key, value));
    };

    const saveDraft = async event => {
        const accessToken = `Bearer ${sessionStorage.accessToken} `;
        try {
            let response = await axiosClient.post(`admin/ledger/create`, event, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-via-device': true,
                    Authorization: accessToken,
                },
            });

            if (response.status == 200) {
                setDraftClicked(false);
                setDraftSuccessModal(true);
                setSuccessTitle('Draft Saved Successfully');
                setDraftButtonClick(false);
            }
        } catch (error) {
            setDraftClicked(false);
            setDraftButtonClick(false);
            setErrorModal(true);
            setErrorMsg(error.response.data.message);
            console.log('error', error);
        }
    };

    const handleDraftSuccessPopupClose = () => {
        draftPopUpClose();
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handleDeleteSuccessModalClose = () => {
        setSuccessModal(false);
        setSuccessModalMsg('');
        setEditData([]);
    };

    const handleDeleteData = id => {
        let filter;
        filter = documentListData.filter(item => {
            if (item._id !== id) {
                return item;
            }
        });
        const selectedItem = documentListData.filter(x => x._id === id);
        let file = selectedItem[0]?.documentFile?.length > 0 ? selectedItem[0]?.documentFile : [];
        if (file.length > 0) {
            let filterSelectedFile = selectedFile.filter(x => x !== file);

            setSelectedFile([...filterSelectedFile]);
        }
        setdocumentListData(documentListData => [...filter]);
    };
    const handleRemoveUpload = event => {
        setSelectedFileName('');
        if (preview) {
            return false;
        } else {
            let filtered;
            filtered = selectedFile.filter((item, index) => {
                if (index !== event) {
                    return item;
                }
            });
            setSelectedFile([...filtered]);
            setRemoveFile(true);

            if (values.documentFile.length > 0) {
                values.documentFile = filtered;
            }
            if (documentListData.length > 0) {
               documentListData.map((item)=>{
                 if(item?._id ===values?._id){
                    item.documentFile=[];
                 }
                });
                setdocumentListData(documentListData => [...documentListData]);
            }
        }
    };
    // -----------------------Upload-----------------------------
    // const onDrop = useCallback(
    //     acceptedFiles => {
    //         setUploadedFile([]);
    //         setSelectedFile([]);
    //         if (
    //             uploadedFile &&
    //             uploadedFile.length > 0 &&
    //             acceptedFiles &&
    //             acceptedFiles.length > 0 &&
    //             uploadedFile.length + acceptedFiles.length > maxFiles
    //         ) {
    //             setUploadAlert('Adding an file not more than 2');
    //         } else if (uploadedFile && uploadedFile.length == 0 && acceptedFiles && acceptedFiles.length == 0 && uploadAlert == false) {
    //             setUploadAlert('Adding an file not more than 2');
    //         } else if (((uploadedFile && uploadedFile.length == 0) || uploadedFile.length > 1) && acceptedFiles && acceptedFiles.length == 0) {
    //             setUploadAlert('Adding an file not more than 2');
    //         } else {
    //             setUploadAlert(false);
    //             setUploadedFiles(acceptedFiles);
    //             const selectedFiles = acceptedFiles;
    //             const filePreviews = selectedFiles.map(file => ({
    //                 name: file.name,
    //                 url: URL.createObjectURL(file),
    //             }));

    //             filePreviews.map(item => {
    //                 fileCollection.push(item.url);
    //             });

    //             setUploadedFile([...uploadedFile, ...fileCollection]);
    //             setFile(filePreviews);
    //         }
    //     },
    //     [uploadedFile],
    // );

    // const { getRootProps, getInputProps } = useDropzone({
    //     onDrop,
    //     maxFiles,
    //     multiple: true, // This allows multiple files to be selected
    //     accept: 'image/*, .pdf, .doc, .docx',
    // });

    // const handleRemoveUpload = event => {
    //     if (preview) {
    //         return false;
    //     } else {
    //         let filtered;
    //         let base64Filtered;
    //         filtered = uploadedFile.filter((item, index) => {
    //             if (index !== event) {
    //                 return item;
    //             }
    //         });
    //         base64Filtered =
    //             base64 &&
    //             base64.length > 0 &&
    //             base64.filter((item, index) => {
    //                 if (index !== event) {
    //                     return item;
    //                 }
    //             });

    //         fileCollection = [];
    //         setUploadedFile([...filtered]);
    //         setBase64(base64Filtered);
    //         setRemoveFile(true);
    //     }
    // };

    // var base64Array = [];
    // const convertBlobToBase64 = async url => {
    //     try {
    //         const response = await fetch(url);
    //         const blob = await response.blob();

    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
    //             base64Array.push(`data:image/png;base64,${base64String}`);
    //         };
    //         setBase64(base64Array);
    //         reader.readAsDataURL(blob);
    //     } catch (error) {
    //         console.error('Error converting blob to base64:', error);
    //     }
    // };

    // useEffect(() => {
    //     if (uploadedFile && uploadedFile.length > 0) {
    //         uploadedFile.map(item => {
    //             convertBlobToBase64(item);
    //         });
    //     }
    // }, [uploadedFile]);

    const handleModalErrorPopUP = () => {
        setErrorModal(false);
    };

    const handleDraft = () => {
        if (draftButtonClick == false) {
            setDraftButtonClick(true);
            ledgerAllData.isDraft = true;
            delete ledgerAllData.document;
            delete ledgerAllData?.ledgerCode;
            ledgerAllData.document = [...[values], ...documentListData];
            ledgerAllData?.bankDetails?.map((item) => {
                if(item?._id?.toString().length<=3){
                    delete item._id;
                    delete item.bankId      
                    }
             });
             ledgerAllData?.document?.map(item => {
                    if (item?._id?.toString().length <= 3) {
                        delete item._id;
                        delete item.documentId;
                    }
                });
            saveDraft(ledgerAllData);
        }
        if (selectedFile && selectedFile.length > 0) {
            setDraftClicked(true);
        } else if (selectedFile && selectedFile.length == 0) {
            setUploadAlert('Please upload at least one image or file. Adding an image or file is required to proceed');
        }
    };

    const handleFileChange = event => {
        if(event?.target?.files){
            setSelectedFile((selectedFile)=>[...selectedFile,...event.target.files]); 
            setUploadAlert('');
        }
    };

    // useEffect(() => {
    //     if (previewData && previewData.image) {
    //         let newCollection = [];
    //         previewData.image.map(item => {
    //             if (item.includes('data:image/png;base64')) {
    //                 newCollection.push(`${item}`);
    //             } else {
    //                 newCollection.push(`data:image/png;base64,${item}`);
    //             }
    //         });

    //         setUploadedFile(newCollection);

    //         // This will be the Blob object of the image
    //     }
    // }, []);

    const uploadDocumentlabel = () => {
        return (
            <>
                <label>Uploaded Document</label>
                <span style={{ color: "#EF4444" }}> *</span>
            </>
        )
    }
    return (
        <React.Fragment>
            <div className={`Detail_container  ${preview ? 'preview_active' : ''}`}>
                <div className={`uploadProduct_container ${preview ? 'preview_active' : ''}`}>
                    {preview ? null : (<>  <h5 className='section_title'>{uploadDocumentlabel()}</h5>


                        <div className='uploadInput'>
                            <input
                                multiple
                                type="file"
                                onChange={handleFileChange}
                                value={selectedFileName}
                            // accept=".jpg,.png,.gif" // Specify file types if needed
                            />
                        </div>
                        <h5 style={{ marginTop: '3px', fontSize: '12px', color: '#9CA3AF' }} className='document_title'>Supported for PDFs, JPEGs, PNGs and DOCs.</h5>
                    </>
                    )}
                    {selectedFile && selectedFile.map((item, index) => {
                        let fileName ='';
                        let fileUrl ='';
                        if(item?.name){
                         fileName =item.name;
                        }else{
                          fileName = item.slice(8,item.length)
                         fileUrl = `http://sootbook.com:3001/${item}`;
                        }
                        return (
                            <>
                                <div className='uploadFileSection' style={{ display: 'flex', justifyContent: 'flexstart', gap: '10px', marginTop: '10px' }}>
                                    <div><UploadIcon /></div>
                                    {item.name ?(
                                     <div>{fileName}</div>
                                    ):(
                                        <a href={fileUrl} download>
                                        {fileName}
                                        </a>
                                    )}
                                 
                                    <div style={{ marginTop: '-8px' }}><IconButton edge='end' aria-label='delete' onClick={() => handleRemoveUpload(index)}>
                                        <DeleteIcon />
                                    </IconButton></div>
                                </div>
                            </>
                        )
                    })}

                    {/* <div className='uploadFlexbox'>
                        <div className={`uploadBox ${uploadAlert && 'uploadError'} ${uploadedFile.length > 0 ? 'uploaded' : ''}`} id='dropzone'>
                            {uploadedFile &&
                                uploadedFile.length > 0 &&
                                uploadedFile.map((url, index) => {
                                    return (
                                        <div className='thumbnail'>
                                            <span
                                                className='close'
                                                onClick={() => {
                                                    handleRemoveUpload(index);
                                                }}
                                            >
                                                <img src={Cross} alt='close_icon' className='close_icon'></img>
                                            </span>
                                            <img src={url} alt='file' className='upload-img'></img>
                                        </div>
                                    );
                                })}

                            {uploadedFile.length == 0 || uploadedFile.length < maxFiles ? (
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <p className='uploadInfo'>
                                        {uploadedFile.length > 0 && uploadedFile.length < maxFiles ? (
                                            <PlusSquare />
                                        ) : (
                                            <React.Fragment>
                                                <h5 className='title'>
                                                    Drop your file or images here or <span className='blue'>browse</span>
                                                </h5>
                                                <span className='maxlimit'>Maximum size: 50MB Maximum file: {maxFiles} </span>
                                            </React.Fragment>
                                        )}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                        {/* <div className='uploadInput'>
                            <span className='label'>Choose file</span>
                            No file chosen
                        </div> */}
                    {/* </div> */}
                    {uploadAlert && (
                        <div className='errorBox'>
                            <p className='container'>
                                <span className='icon'>
                                    <Alert />
                                </span>
                                <span className='text'>{uploadAlert}</span>
                            </p>
                        </div>
                    )}
                </div>
                <div className='third_flexbox'>
                    <div className='inputBox Product_Name sm-20 lg-25'>
                        <Select
                            label={'Document Type'}
                            name={'documentType'}
                            options={[
                                { id: 'Tax', value: 'Tax' },
                                { id: 'License', value: 'License' },
                                { id: 'Contract', value: 'Contract' },
                            ]}
                            wrapperClass={'col12'}
                            value={values.documentType}
                            onChange={handleChange}
                            error={errors.documentType}
                            touched={touched.documentType}
                            disabled={preview ? true : false}
                        />
                    </div>
                    <div className='selectDate'>
                                    <label className='label'>Expiry date</label>
                                    <div className='inputBox datePicker sm-25 lg-20'>
                                      {preview?(
                                   <span style={{minWidth:"250px"}}> {values.documentExpiryDate}</span>
                                      ):(  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={documentExpiryDate ? dayjs(documentExpiryDate) : null}
                                                className='dateFilter'
                                                onChange={(newValue) => {
                                                    handleChangedocumentExpiryDate(newValue);
                                                  }}
                                                wrapperClass={'col12'}
                                                name='documentExpiryDate'
                                                format="DD-MM-YYYY"
                                                ReadOnly={preview ? true : false}
                                                minDate={today && dayjs(today)}
                                            />
                                        </LocalizationProvider>
                                      )}
                                    </div>
                                </div>
                    {/* <div className='inputBox Product_Name sm-20 lg-25'>
                        <DatePicker
                            label={'Expiry date'}
                            wrapperClass={`expiryDate`}
                            name={'documentExpiryDate'}
                            value={values.documentExpiryDate}
                            dateFormat={'dd-MM-yyyy'}
                            onChange={handleChangeDate}
                            disabledPastDates={true}
                        />
                    </div> */}
                    <div className='inputBox sm-20 lg-25'>
                        <Select
                            label={'Notification Alert'}
                            name={'documentNotificationAlert'}
                            options={[
                                { id: 'None', value: 'None' },
                                { id: '7 days before', value: '7 days before' },
                                { id: '15 days before', value: '15 days before' },
                                { id: '30 days before', value: '30 days before' },
                            ]}
                            wrapperClass={'col12'}
                            value={values.documentNotificationAlert}
                            onChange={handleChange}
                            error={errors.documentNotificationAlert}
                            touched={touched.documentNotificationAlert}
                            disabled={preview ? true : false}
                        />
                    </div>
                </div>
                {preview ? null : ( <div className='actionTableFlexbox'>
                    <div className='rightCol'>
                        <button type='button' className='canceltBtn' onClick={handleResetForm}>
                            Reset
                        </button>
                        <button type='button' style={{ width: '190px' }} className='nextBtn' onClick={handleSave}>
                            Add New Document
                        </button>
                    </div>
                </div>)}

                <div className='table_container bankDetailsTable' style={{ overflowX: 'auto' }}>
                    <CommonTable
                        deleteProductData={e => handleBankDelete(e)}
                        dataEditPopulate={e => handleEditDataPopulate(e)}
                        header={tableHeader}
                        tableBodyData={documentListData}
                        tableFilterHeader={tableFilterHeader}
                        preview={preview}
                        showEditAction={true}
                        showDeleteAction={true}
                    />
                </div>


                <div className='actionFlexbox'>
                    {preview ? null : (
                        <React.Fragment>
                            {!Object.keys(previewData).length > 0 && (
                                <button
                                    type='button'
                                    className='draftBtn'
                                    onClick={() => {
                                        setIsCheckFormClick(false);
                                        setDraftClicked(true);
                                        handleSubmit();
                                    }}
                                >
                                    Save Draft
                                </button>
                            )}
                            <div className='rightCol'>
                                <button type='button' className='canceltBtn' onClick={handleTabBack}>
                                    Back
                                </button>
                                <button
                                    type='button'
                                    className='nextBtn'
                                    onClick={() => {
                                        setIsCheckFormClick(false);
                                        documentListData.length > 0 ? handleNext() : handleSubmit();
                                    }}
                                >
                                    Next
                                </button>
                            </div>{' '}
                        </React.Fragment>
                    )}
                </div>
            </div>
            {draftsuccessModal && (
                <SuccessModal
                    handleSuccessClose={handleDraftSuccessPopupClose}
                    SuccessPopUp={draftsuccessModal}
                    SuccessMsg={SuccessMsg}
                    SuccessTitle={SuccessTitle}
                />
            )}
            {successModal && (
                <SuccessModal
                    handleSuccessClose={handleDeleteSuccessModalClose}
                    SuccessPopUp={successModal}
                    SuccessTitle={SuccessModalMsg}
                    // SuccessMsg={SuccessModalMsg}
                />
            )}
            {deleteModal && (
                <DeleteModal
                    handleDeleteClose={handleDeleteModalClose}
                    DeletePopUp={deleteModal}
                    DeleteModalTitle={DeleteModalTitle}
                    DeleteModalMsg={DeleteModalMsg}
                    handleDeleteConfirm={handleDeleteData}
                />
            )}
            {errorModal && <ErrorModal handleErrorClose={handleModalErrorPopUP} ErrorPopUp={errorModal} ErrorMsg={errorMsg} />}
        </React.Fragment>
    );
}

export default Document;
