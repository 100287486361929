import React from 'react';

const InfoBox = ({ data, bottom = false }) => {
    return (
        <div className={`infoBox ${bottom ? 'infoBottom' : ''}`}>
            <div className="header">
                <h2>Last Purchase Info</h2>
            </div>
            <div className="body">
                <div className="row">
                    <div className="item">
                        Product code: {data?.product}
                    </div>
                    <div className="item">
                        MRP: {data?.mrp}
                    </div>
                </div>
                <div className="row">
                    <div className="item">Batch Number: {data?.batchNumber}</div>
                    <div className="item">Purchase Price: {data?.buyingPrice}</div>
                </div>
                <div className="row">
                    <div className="item">Current Stock: {data?.quantity}</div>
                    <div className="item">Purchase Deal: {data?.discountPercentage}%</div>
                </div>
            </div>
        </div>
    );
};

export default InfoBox;