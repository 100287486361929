// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const LoginServiceSlices = createSlice({
    name: 'loginDetails',
    initialState: { token: '', userInfo: {} },
    reducers: {
        updateLoginDetails: (state, action) => {
            const { token, user } = action.payload;
            state.token = token;
            state.userInfo = user;
        },
        clearStore(state) {
            state = {};
        },
    },
});

export const { updateLoginDetails, clearStore } = LoginServiceSlices.actions;
export default LoginServiceSlices.reducer;
