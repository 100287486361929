import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Input, TextArea } from '../../../components/common';
import { axiosClient } from '../../../../services/axiosClient';
import SuccessModal from '../../../components/common/CommonSuccessModal/SuccessModal';
import CommonTable from '../../../components/common/CommonTable/CommonTable';
import DeleteModal from '../../../components/common/CommonDeleteModal/CommonDeleteModal';
import React, { useEffect, useState, useCallback } from 'react';
import ErrorModal from '../../../components/common/CommonErrorModal/ErrorModal';
import { ReactComponent as PlusSquare } from '../../../../assets/images/plus-square.svg';
import Cross from '../../../../assets/images/x.svg';
import { ReactComponent as Alert } from '../../../../assets/images/alert-triangle.svg';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploadIcon } from '../../../../assets/images/uploadFile.svg';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './AddNewLedger.scss';

const notesEditFormSchema = Yup.object({});

const notesInitialValues = {
    generalNotes: '',
    actionHistory: '',
    attachment: [],
};

function Notes({
    changeTab,
    EditMode,
    ProductCreateList,
    ledgerDetailData,
    preview,
    previewData,
    back,
    ledgerAllData,
    successModalClose,
    draftPopUpClose,
}) {
    const [draftClicked, setDraftClicked] = useState(false);
    const [draftButtonClick, setDraftButtonClick] = useState(false);
    const [draftsuccessModal, setDraftSuccessModal] = useState(false);
    const [SuccessMsg, setSuccessMsg] = useState('');
    const [SuccessTitle, setSuccessTitle] = useState('');
    const [showLoader, setshowLoader] = useState(false);
    const [SuccessModalMsg, setSuccessModalMsg] = useState('');
    const [successModal, setSucessModal] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    // -----------------------Upload-----------------------------

    const fileTypes = ['JPG', 'PNG', 'GIF'];
    const [base64, setBase64] = useState('');
    const [errorModal, setErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [file, setFile] = useState(null);
    const [uploadAlert, setUploadAlert] = useState(false);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [removeFile, setRemoveFile] = useState(false);
    const maxFiles = 5; // Limit the number of files to 3
    const [selectedFile, setSelectedFile] = useState([]);
    const  [selectedFileName,setSelectedFileName] = useState();

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik({
        initialValues: notesInitialValues,
        validationSchema: notesEditFormSchema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: (values, action) => {
            handleFormSubmit(values);
            // action.resetForm();
        },
    });
    const handleModalErrorPopUP = () => {
        setErrorModal(false);
        setErrorMsg('');
    };
    const handleFormSubmit = async values => {
        let finalValue = {};
        values.attachment = [];
        if (selectedFile) {
            selectedFile.forEach(file => {
                values.attachment.push(file);
            });
        }
        finalValue = { ...ledgerAllData, ...values };
        const data = new FormData();
        let isuploadTrue = false;
        finalValue?.document?.forEach(docs => {
            docs?.documentFile?.forEach(file => {
                if (file?.name) {
                    isuploadTrue = true;
                    data.append('documentFile', file);
                }
            });
        });

        finalValue?.attachment?.forEach(file => {
            if (file.name) {
                isuploadTrue = true;
                data.append('attachment', file);
            }
        });
        finalValue?.bankDetails?.map((item) => {
            if(item?._id?.toString().length<=3){
                delete item._id;
                delete item.bankId      
                }
         });
         finalValue?.document?.map(item => {
                if (item?._id?.toString().length <= 3) {
                    delete item._id;
                    delete item.documentId;
                }
            });
        if (isuploadTrue) {
            handleUpload(data, finalValue);
        } else {
            handleSaveData(finalValue);
        }
    };

    const handleSaveData = finalValue => {
        if (draftClicked) {
            handleDraft(finalValue);
        } else {
            saveAllData(finalValue);
        }
    };

    const transformFinalValue = (finalValue, fileUploadResponse) => {
        if (Object.keys(fileUploadResponse?.attachment).length > 0) {
            finalValue.attachment = [];
            Object.keys(fileUploadResponse?.attachment).forEach(key => {
                finalValue.attachment.push(fileUploadResponse.attachment[key]);
            });
        }
        if (Object.keys(fileUploadResponse.documents).length > 0) {
            finalValue?.document?.forEach(docs => {
                docs.documentFile = [];
                Object.keys(fileUploadResponse.documents).forEach(key => {
                    docs.documentFile.push(fileUploadResponse.documents[key]);
                });
            });
        }
        handleSaveData(finalValue);
    };

    const handleUpload = async (data, finalValue) => {
        const accessToken = `Bearer ${sessionStorage.accessToken} `;
        try {
            let response = await axiosClient.post(`admin/ledger/upload`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-via-device': true,
                    Authorization: accessToken,
                },
            });
            if (response.status == 200) {
                transformFinalValue(finalValue, response?.data?.data);
            }
        } catch (error) {
            console.log('error', error);
            setErrorModal(true);
            setErrorMsg(error.response.data.message);
        }
    };

    const saveAllData = value => {
        if (previewData._id) {
            value._id = previewData._id;
            handleEditLedger(value);
        } else {
            handleAddLedger(value);
        }
    };

    const handleFileChange = event => {
        if(event?.target?.files){
            if(selectedFile?.length>0){
                setSelectedFile((selectedFile)=>[...selectedFile,...event.target.files]); 
            }else{
            setSelectedFile((selectedFile)=>[...event.target.files]); 
            }
        }
    };

    const handleEditLedger = async values => {
        if (values !== '') {
            const accessToken = `Bearer ${sessionStorage.accessToken} `;
            try {
                let response = await axiosClient.post(`admin/ledger/update`, values, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-via-device': true,
                        Authorization: accessToken,
                    },
                });

                if (response.status == 200) {
                    setSucessModal(true);
                    setSuccessMsg('The Ledger has been updated into the system');
                    setSuccessTitle('Ledger has been updated successfully');
                }
            } catch (error) {
                console.log('error', error);
                setErrorModal(true);
                setErrorMsg(error.response.data.message);
            }
        }
    };

    const handleAddLedger = async values => {
        if (values !== '') {
            const accessToken = `Bearer ${sessionStorage.accessToken} `;
            try {
                let response = await axiosClient.post(`admin/ledger/create`, values, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-via-device': true,
                        Authorization: accessToken,
                    },
                });

                if (response.status == 200) {
                    setSucessModal(true);
                    setSuccessMsg('The Ledger has been added into the system');
                    setSuccessTitle('Ledger has been added successfully');
                }
            } catch (error) {
                console.log('error', error);
                setErrorModal(true);
                setErrorMsg(error.response.data.message);
            }
        }
    };
    const handleTabBack = () => {
        values.attachment = [];
        if (selectedFile) {
            selectedFile.forEach(file => {
                values.attachment.push(file);
            });
        }
        ledgerDetailData(values)
        changeTab(3);
    };

    useEffect(() => {
        if (previewData?.attachment?.length > 0 || previewData?.generalNotes) {
            Object.keys(notesInitialValues).map(item => {
                if (item === 'attachment') {
                    setSelectedFile(previewData[item]);
                }
                setFieldValue(item, previewData[item]);
            });
        }else{
            Object.keys(notesInitialValues).map((item) => {
                if (item === 'attachment') {
                    setSelectedFile(ledgerAllData[item]);
                }
                setFieldValue(item, ledgerAllData[item]);
            });
        }
    }, []);

    const saveDraft = async event => {
        const accessToken = `Bearer ${sessionStorage.accessToken} `;
        try {
            let response = await axiosClient.post(`admin/ledger/create`, event, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-via-device': true,
                    Authorization: accessToken,
                },
            });

            if (response.status == 200) {
                setDraftClicked(false);
                setDraftSuccessModal(true);
                setSuccessTitle('Draft Saved Successfully');
                setDraftButtonClick(false);
            }
        } catch (error) {
            setDraftClicked(false);
            setDraftButtonClick(false);
            setErrorModal(true);
            setErrorMsg(error.response.data.message);
            console.log('error', error);
        }
    };

    const handleDraftSuccessPopupClose = () => {
        draftPopUpClose();
    };

    const handleSuccessPopupClose = () => {
        setSucessModal(false);
        successModalClose();
    };

    let fileCollection = [];
    const onDrop = useCallback(
        acceptedFiles => {
            setUploadedFile([]);
            if (
                uploadedFile &&
                uploadedFile.length > 0 &&
                acceptedFiles &&
                acceptedFiles.length > 0 &&
                uploadedFile.length + acceptedFiles.length > maxFiles
            ) {
                setUploadAlert('Adding an file not more than 5');
            } else if (uploadedFile && uploadedFile.length == 0 && acceptedFiles && acceptedFiles.length == 0 && uploadAlert == false) {
                setUploadAlert('Adding an file not more than 5');
            } else if (((uploadedFile && uploadedFile.length == 0) || uploadedFile.length > 1) && acceptedFiles && acceptedFiles.length == 0) {
                setUploadAlert('Adding an file not more than 5');
            } else {
                setUploadAlert(false);
                const selectedFiles = acceptedFiles;
                setUploadedFiles(uploadedFiles => [...uploadedFiles, ...acceptedFiles]);
                const filePreviews = selectedFiles.map(file => ({
                    name: file.name,
                    url: URL.createObjectURL(file),
                }));
                filePreviews.map(item => {
                    fileCollection.push(item.url);
                });

                setUploadedFile([...uploadedFile, ...fileCollection]);
                setFile(filePreviews);
            }
        },
        [uploadedFile],
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles,
        multiple: true, // This allows multiple files to be selected
        accept: 'image/*', // This restricts the file type to images (you can adjust this)
    });

    const handleRemoveUpload = event => {
        setSelectedFileName('');
        if (preview) {
            return false;
        } else {
            let filtered;
            filtered = selectedFile.filter((item, index) => {
                if (index !== event) {
                    return item;
                }
            });
            if (values.attachment.length > 0) {
                values.attachment = filtered;
            }
            setSelectedFile([...filtered]);
            setRemoveFile(true);
        }
    };

    var base64Array = [];
    const convertBlobToBase64 = async url => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
                base64Array.push(`data:image/png;base64,${base64String}`);
            };
            setBase64(base64Array);
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Error converting blob to base64:', error);
        }
    };

    useEffect(() => {
        if (uploadedFile && uploadedFile.length > 0) {
            uploadedFile.map(item => {
                convertBlobToBase64(item);
            });
        }
    }, [uploadedFile]);

    const handleDraft = () => {
        if (draftButtonClick == false) {
            setDraftButtonClick(true);
            ledgerAllData.isDraft = true;
            delete ledgerAllData?.ledgerCode;
            const value = { ...values, ...ledgerAllData };
            saveDraft(value);
        }
        // if (uploadedFile && uploadedFile.length > 0) {
        //     setDraftClicked(true);
        // } else if (uploadedFile && uploadedFile.length == 0) {
        //     setUploadAlert('Please upload at least one image. Adding an image is required to proceed');
        // }
    };

    useEffect(() => {
        if (previewData && previewData.image) {
            let newCollection = [];
            previewData.image.map(item => {
                if (item.includes('data:image/png;base64')) {
                    newCollection.push(`${item}`);
                } else {
                    newCollection.push(`data:image/png;base64,${item}`);
                }
            });

            setUploadedFile(newCollection);

            // This will be the Blob object of the image
        }
    }, []);

    useEffect(() => {
        if (draftClicked) {
        }
    }, [draftClicked]);

    return (
        <React.Fragment>
            <div className={`Detail_container  ${preview ? 'preview_active' : ''}`}>
                <div className='first_flexbox'>
                    <div className='inputBox sm-50 lg-40'>
                        <TextArea 
                            label={'General Notes'}
                            name={'generalNotes'}
                            placeholder={'Additional information about the ledger...'}
                            value={values.generalNotes}
                            wrapperClass={'col12'}
                            onChange={handleChange}
                            error={errors.generalNotes}
                            touched={touched.generalNotes}
                            disabled={preview ? true : false}
                        />
                    </div>
                    {preview ? null : (
                        <div className='inputBox sm-50 lg-50 actionHistoryBox'>
                            <Input
                                label={'Action History'}
                                type={'text'}
                                name={'actionHistory'}
                                id={'actionHistory'}
                                value={values.actionHistory}
                                placeholder={'Ledger created by John Black on 01/01/2024.'}
                                wrapperClass={'col12'}
                                onChange={handleChange}
                                error={errors.actionHistory}
                                touched={touched.actionHistory}
                                ReadOnly={preview ? true : false}
                                disabled
                            />

                            <ul className='info_listing'>
                                <li>Automatically generated note.</li>
                                <li>Can't be edited.</li>
                            </ul>
                        </div>
                    )}
                </div>
                <div className={`uploadProduct_container ${preview ? 'preview_active' : ''}`}>
                    <h5 className='section_title'>{preview || EditMode ? `Attachments` : `Attachments`}</h5>
                    {preview ? null : ( 
                        <>
                        <div className='uploadInput'>
                        <input
                            multiple
                            type="file"
                            value={selectedFileName}
                            onChange={handleFileChange}
                        // accept=".jpg,.png,.gif" // Specify file types if needed
                        />
                    </div>
                    <h5 style={{ marginTop: '3px', fontSize: '12px', color: '#9CA3AF' }} className='document_title'>Supported for Images, PDFs, etc.</h5>
                    </>
                    )}
                    {selectedFile && selectedFile.map((item, index) => {
                        let fileName ='';
                        let fileUrl ='';
                        if(item?.name){
                         fileName =item.name;
                        }else{
                          fileName = item.slice(8,item.length)
                         fileUrl = `http://sootbook.com:3001/${item}`;
                        }
                        return (
                            <>
                                <div className='uploadFileSection' style={{ display: 'flex', justifyContent: 'flexstart', gap: '10px', marginTop: '10px' }}>
                                    <div><UploadIcon /></div>
                                    {item.name ?(
                                     <div>{fileName}</div>
                                    ):(
                                        <a href={fileUrl} download>
                                        {fileName}
                                        </a>
                                    )}
                                    <div style={{ marginTop: '-10px' }}>
                                        <IconButton edge='end' aria-label='delete' onClick={() => handleRemoveUpload(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </>
                        )
                    })}

                    {/* <div className='uploadFlexbox'>
                        <div className={`uploadBox ${uploadAlert && 'uploadError'} ${uploadedFile.length > 0 ? 'uploaded' : ''}`} id='dropzone'>
                            {uploadedFile &&
                                uploadedFile.length > 0 &&
                                uploadedFile.map((url, index) => {
                                    return (
                                        <div className='thumbnail'>
                                            <span
                                                className='close'
                                                onClick={() => {
                                                    handleRemoveUpload(index);
                                                }}
                                            >
                                                <img src={Cross} alt='close_icon' className='close_icon'></img>
                                            </span>
                                            <img src={url} alt='upload-img' className='upload-img'></img>
                                        </div>
                                    );
                                })}

                            {uploadedFile.length == 0 || uploadedFile.length < maxFiles ? (
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <p className='uploadInfo'>
                                        {uploadedFile.length > 0 ? (
                                            <PlusSquare />
                                        ) : (
                                            <React.Fragment>
                                                <h5 className='title'>
                                                    Drop your images or Files here or <span className='blue'>browse</span>
                                                </h5>
                                                <span className='maxlimit'>Maximum size: 50MB Maximum quantity: {maxFiles} </span>
                                            </React.Fragment>
                                        )}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {uploadAlert && (
                        <div className='errorBox'>
                            <p className='container'>
                                <span className='icon'>
                                    <Alert />
                                </span>
                                <span className='text'>{uploadAlert}</span>
                            </p>
                        </div>
                    )} */}
                </div>

                <div className='actionFlexbox'>
                    {preview ? null : (
                        <React.Fragment>
                            {!Object.keys(previewData).length > 0 && (
                                <button
                                    type='button'
                                    className='draftBtn'
                                    onClick={() => {
                                        setDraftClicked(true);
                                        handleSubmit();
                                    }}
                                >
                                    Save Draft
                                </button>
                            )}
                            <div className='rightCol'>
                                <button type='button' className='canceltBtn' onClick={handleTabBack}>
                                    Back
                                </button>
                                <button type='button' className='nextBtn' onClick={handleSubmit}>
                                    Save
                                </button>
                            </div>{' '}
                        </React.Fragment>
                    )}
                </div>
            </div>
            {successModal && (
                <SuccessModal
                    handleSuccessClose={handleSuccessPopupClose}
                    SuccessPopUp={successModal}
                    SuccessMsg={SuccessMsg}
                    SuccessTitle={SuccessTitle}
                />
            )}
            {errorModal && <ErrorModal handleErrorClose={handleModalErrorPopUP} ErrorPopUp={errorModal} ErrorMsg={errorMsg} />}
            {draftsuccessModal && (
                <SuccessModal
                    handleSuccessClose={handleDraftSuccessPopupClose}
                    SuccessPopUp={draftsuccessModal}
                    SuccessMsg={SuccessMsg}
                    SuccessTitle={SuccessTitle}
                />
            )}
        </React.Fragment>
    );
}

export default Notes;
