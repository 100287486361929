import React from 'react'

const SmallInfo = ()=>{
    return(
        <div className="smallInfo">
            Click on this will present the information of Sales Bonus Scheme.
        </div>
    )
}


export default SmallInfo;