import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';

export const AutoComplete = ({viewMode, productAllName, handleAutoCompleteChange, row, index})=>{
    return(
        <Autocomplete
            disablePortal
            disabled={viewMode}
            className={'rowAutoComplete'}
            options={productAllName}
            getOptionLabel={(option) => option.name}
            filterOptions={(options, state) => {
                const searchText = state.inputValue.toLowerCase();
                return options.filter(
                    (option) =>
                        option.name.toLowerCase().includes(searchText) ||
                        option.productCode.toLowerCase().includes(searchText),
                );
            }}
            sx={{ width: 220 }}
            renderOption={(props, option) => (
                <ListItem {...props}>
                    <ListItemText primary={`${option.productCode} - ${option.name}`}/>
                </ListItem>
            )}
            renderInput={(params) => <TextField {...params} />}
            value={
                productAllName.find(
                    (item) =>
                        item.name === row?.product?.name ||
                        item.productCode === row?.product?.productCode,
                ) || null
            }
            onChange={(event, newValue) => handleAutoCompleteChange(index, newValue)}
        />
    )
}

