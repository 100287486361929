import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Input, Select, SelectWithInput } from '../../../components/common';
import { axiosClient } from '../../../../services/axiosClient';
import SuccessModal from '../../../components/common/CommonSuccessModal/SuccessModal';
import { ReactComponent as Info } from '../../../../assets/images/info.svg';
import { ReactComponent as Barcode } from '../../../../assets/images/sample-bar-code.svg';
import QRcode from '../../../../assets/images/sample-qr-code.png';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, InputAdornment } from '@mui/material';
import './AddNewLedger.scss';
import { format } from 'date-fns';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const ledgerEditFormSchema = Yup.object({
    companyName: Yup.string().required('Company Name is required.'),
    ledgerCategory: Yup.string().required('Ledger Category is required.'),
    // ledgerType: Yup.string().required('Ledger Type is required.'),
});

const ledgerInitialValues = {
    ledgerCode: '',
    companyName: '',
    accountStatus: 'Active',
    ledgerCategory: 'Customer',
    ledgerType: 'Cash',
    glsNumber: '',
    tradeLicenceNumber: '',
    tradeExpiryDate: `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`,
    healthLicenceNumber: '',
    healthExpiryDate: `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`,
    country: 'India',
    city: '',
    zipCode: '',
    addressLine1: '',
    addressLine2: '',
    email: '',
    phoneNumber: '',
    phoneNumberCountry: 'India',
    ownerPhoneNumberCountry: 'India',
    officePhoneNumberCountry: 'India',
    mobilePhoneNumberCountry: 'India',
    website: '',
    faxNo: '',
    ownerFullName: '',
    ownerEmail: '',
    ownerPhoneNumber: '',
    contactFullName: '',
    contactEmail: '',
    contactDesignation: '',
    officePhoneNumber: '',
    mobilePhoneNumber: '',
    creditApplication: 'Received',
    billExport: 'GSTN',
    vatHeading: 'Local',
    vatNumber: '',
    currency: 'USD',
    legalComplianceStatus: 'Compliant',
    outstandingDisputes: 'None',
    auditLog: '',
    taxId: '',
    complianceOfficerName: '',
    taxFilingFrequency: 'Monthly',
    corporateTaxId: '',
    creditLimit: '',
    creditDays: '',
    billLimit: '',
    invoiceLimit: '',
    billDiscount: '',
    invoiceFormat: 'Default',
    exportBillFormat: 'Detailed',
    rebatePercentage: '',
    rebateNumberOfDays: '',
    collectionDays: '',
    paymentLimit: '',
    paymentModes: ['Cash', 'Credit Card'],
    salesmanAreaCode: '',
    salesmanTerritory: 'North Zone',
    salesmanRegion: 'Midwest',
    salesmanCountry: 'United States',
    businessActivity: 'Retail Pharma',
    salesman: '',
    deliveryRoute: 'Route 22',
    paymentPercentage: '',
    salesmanCollectionDays: ['Monday', 'Tuesday'],
    isDraft: false
};

function LedgerDetails({ changeTab, ProductCreateList, ledgerDetailData, preview, previewData, back, ledgerAllData, draftPopUpClose }) {
    const [draftClicked, setDraftClicked] = useState(false);
    const [draftButtonClick, setDraftButtonClick] = useState(false);
    const [draftsuccessModal, setDraftSuccessModal] = useState(false);
    const [SuccessMsg, setSuccessMsg] = useState('');
    const [SuccessTitle, setSuccessTitle] = useState('');
    const [showLoader, setshowLoader] = useState(false);
    const [currentTabvalue, setCurrentTabvalue] = useState(0);
    const AddLedgerDetailsTabs = ['Ledger Info', 'Contact Info', 'Finance & Legal', 'Payment', 'SalesMan Info'];
    const FilterDayList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    const [collectionDaysData, setCollectionDaysData] = React.useState(['Monday', 'Tuesday']);
    const todayDate = `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`
    const today = new Date();
    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik({
        initialValues: ledgerInitialValues,
        validationSchema: ledgerEditFormSchema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: (values, action) => {
            console.log("Values", values)
            handleFormSubmit(values);
            // action.resetForm();
        },
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tradeExpiryDate, setTradeExpiryDate] = useState(todayDate);
    const [healthExpiryDate, setHealthExpiryDate] = useState(todayDate);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const convertDateFormat = (dateString) => {
        return `${new Date(dateString).getDate()}/${new Date(dateString).getMonth()}/${new Date(dateString).getFullYear()}`;
    }

    const handleChangeSalesmanCollectionDays = (e) => {
        let newValue = [];
        if (values.salesmanCollectionDays.includes(e)) {
            newValue = values.salesmanCollectionDays.filter((x) => x !== e)
        } else {
            newValue = [...values.salesmanCollectionDays, ...[e]];
        }
        setFieldValue("salesmanCollectionDays", newValue)

    };

    const onSelectChange = (e) => {
        const { name, value } = e.target
        setFieldValue(name, value)
    }

    const handleFormSubmit = async values => {
        if (draftClicked) {
            handleModifyData();
            handleDraft(values);
        } else {
            if (currentTabvalue === 4) {
                handleModifyData();
                ledgerDetailData(values);
                changeTab(1);
            }
            else {
                let value = currentTabvalue + 1;
                setCurrentTabvalue(value);
            }
            // changeTab(1);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (previewData && Object.keys(previewData).length > 0) {
                Object.keys(ledgerInitialValues).map((item) => {
                    if(item==="tradeExpiryDate" || item ==="healthExpiryDate"){
                    const date= format(new Date(previewData[item]), 'yyyy-MM-dd');
                    const  dateValue = new Date(date).toDateString();
                    if(item==="tradeExpiryDate" && dateValue!==tradeExpiryDate){
                        setTradeExpiryDate(dateValue);
                    }
                      if(item==="healthExpiryDate" && dateValue!==healthExpiryDate){
                      setHealthExpiryDate(dateValue);
                      }
                      setFieldValue(item, dateValue);
                    }
                   else{
                    setFieldValue(item, previewData[item] ? previewData[item] : ledgerInitialValues[item]);
                    }
                });
            }
            setFieldValue("isDraft", false);
        }, 900);
    }, [])

    const handleModifyData = () => {
        delete values.phoneNumberCountry;
        delete values.ownerPhoneNumberCountry;
        delete values.officePhoneNumberCountry;
        delete values.mobilePhoneNumberCountry;
        delete values?.bankDetails;
        delete values?.document;
        delete values?.ledgerCode;
    }
    const handleBack = () => {
        if (currentTabvalue === 0) {
            back();
        } else {
            let value = currentTabvalue - 1;
            setCurrentTabvalue(value);
        }
    }

    const handleChangeTradeExpiryDate = e => {
        const date = `${e?.$D}-${e?.$M+1}-${e?.$y}`
        setFieldValue("tradeExpiryDate", date);
        setTradeExpiryDate(e);

    }

    const handleChangehealthExpiryDate = e => {
        const date = `${e?.$D}-${e?.$M+1}-${e?.$y}`
        setFieldValue("healthExpiryDate", date);
        setHealthExpiryDate(e);
    }

    const handleChangeDate = e => {
        if (e?.target) {
            const { name, value } = e.target;
            setFieldValue(prevState => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            const { name, value } = e;
            setFieldValue(name, value);
        }
    };


    const handleChangePaymentModes = (e) => {
        let newValue = [];
        if (values.paymentModes.includes(e)) {
            newValue = values.paymentModes.filter((x) => x !== e)
        } else {
            newValue = [...values.paymentModes, ...[e]];
        }
        setFieldValue("paymentModes", newValue);
    }

    const handleDraft = data => {
        handleModifyData();
        if (draftButtonClick == false) {
            setDraftButtonClick(true);
            values.isDraft = true;
            saveDraft(values);
        }
    };

    const saveDraft = async event => {
        const accessToken = `Bearer ${sessionStorage.accessToken} `;
        try {
            let response = await axiosClient.post(`admin/ledger/create`, event, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-via-device': true,
                    Authorization: accessToken,
                },
            });

            if (response.status == 200) {
                setDraftClicked(false);
                setDraftSuccessModal(true);
                setSuccessTitle('Draft Saved Successfully');
                setDraftButtonClick(false);
            }
        } catch (error) {
            setDraftClicked(false);
            setDraftButtonClick(false);
            console.log('error', error);
        }
    };


    const handleChangeLedgerCategory = (e) => {
        const { name, value } = e.target;
        if (value === "Customer") {
            setFieldValue("ledgerType", "Cash");

        } else {
            setFieldValue("ledgerType", "Credit")
        }
        setFieldValue(name, value)
    }


    const handleDraftSuccessPopupClose = () => {
        draftPopUpClose();
    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    const handleChangeTabsValue = (newValue) => {
        if (preview) {
            setCurrentTabvalue(newValue);
        }

    };

    return (
        <React.Fragment>
            <div className={`Detail_container  ${preview ? 'preview_active' : ''}`}>
                <div className='buttonContainer'>
                    {AddLedgerDetailsTabs.map((item, index) => {
                        return (
                            <button className={`btnTabs ${currentTabvalue === index ? 'btnselected' : ''}`} onClick={() => handleChangeTabsValue(index)} id={`${index}+item`}>{item}</button>
                        )
                    })}
                </div>

                {currentTabvalue === 0 &&
                    <>
                        <div className='ledger_infoSection'>
                            <div className='first_flexbox'>
                                <div className='inputBox ledgerCode sm-20 lg-20'>
                                    <Input
                                        label={'Ledger Code'}
                                        type={'text'}
                                        placeholder={" "}
                                        name={'ledgerCode'}
                                        id={'ledgerCode'}
                                        value={values.ledgerCode}
                                        wrapperClass={'col12'}
                                        onChange={handleChange}
                                        error={errors.ledgerCode}
                                        touched={touched.ledgerCode}
                                        ReadOnly={true}
                                        disabled={true}
                                    />
                                </div>
                                <div className='inputBox companyName sm-60 lg-60'>
                                    <Input
                                        label={'Company Name'}
                                        type={'text'}
                                        name={'companyName'}
                                        id={'companyName'}
                                        value={values.companyName}
                                        wrapperClass={'col12'}
                                        onChange={handleChange}
                                        error={errors.companyName}
                                        touched={touched.companyName}
                                        ReadOnly={preview ? true : false}
                                        isRequired
                                    />
                                </div>
                                <div className='inputBox status sm-20 lg-20'>
                                    <Select
                                        id='accountStatus'
                                        label={'Status'}
                                        name={'accountStatus'}
                                        value={values.accountStatus}
                                        options={[
                                            { id: 'Active', value: 'Active' },
                                            { id: 'Inactive', value: 'Inactive' },
                                        ]}
                                        onChange={handleChange}
                                        disabled={preview ? true : false}
                                    />
                                </div>
                            </div>

                            <div className='second_flexbox'>
                                <div className='inputBox ledgerCategory sm-20 lg-20'>
                                    <Select
                                        label={'Ledger Category'}
                                        name={'ledgerCategory'}
                                        options={[
                                            { id: 'Customer', value: 'Customer' },
                                            { id: 'Supplier', value: 'Supplier' },
                                        ]}
                                        isRequired
                                        wrapperClass={'col12'}
                                        value={values.ledgerCategory}
                                        onChange={handleChangeLedgerCategory}
                                        error={errors.ledgerCategory}
                                        touched={touched.ledgerCategory}
                                        disabled={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox ledgerType sm-20 lg-20'>
                                    <Select
                                        label={'Ledger Type'}
                                        name={'ledgerType'}
                                        options={[
                                            { id: 'Cash', value: 'Cash' },
                                            { id: 'Credit', value: 'Credit' },
                                        ]}
                                        // isRequired
                                        wrapperClass={'col12'}
                                        value={values.ledgerType}
                                        // onChange={handleChange}
                                        error={errors.ledgerType}
                                        touched={touched.ledgerType}
                                        disabled
                                    />
                                </div>

                                <div className='inputBox glsNumber sm-20 lg-20'>
                                    <Input
                                        label={'GLS Number'}
                                        type={'text'}
                                        name={'glsNumber'}
                                        id={'glsNumber'}
                                        value={values.glsNumber}
                                        wrapperClass={'col12'}
                                        onChange={handleChange}
                                        ReadOnly={preview ? true : false}
                                        placeholder={preview && values.glsNumber == '' ? '-' : 'Please type'}
                                    />
                                </div>

                            </div>
                            <div className='third_flexbox'>
                                <div className='inputBox supplier sm-25 lg-20'>
                                    <Input
                                        label={'Trade License Number'}
                                        type={'text'}
                                        name={'tradeLicenceNumber'}
                                        id={'tradeLicenceNumber'}
                                        value={values.tradeLicenceNumber}
                                        wrapperClass={'col12'}
                                        onChange={handleChange}
                                        ReadOnly={preview ? true : false}
                                        placeholder={preview && values.tradeLicenceNumber == '' ? '-' : 'Please type'}
                                    />
                                </div>
                                <div className='selectDate'>
                                    <label className='label'>Expiry date</label>
                                    <div className='inputBox datePicker sm-25 lg-20'>
                                        {preview ? (
                                            <span style={{minWidth:'250px'}}> {values.tradeExpiryDate}</span>
                                        ) : (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={tradeExpiryDate ? dayjs(tradeExpiryDate) : null}
                                                    className='dateFilter'
                                                    name='tradeExpiryDate'
                                                    onChange={(newValue) => {
                                                        handleChangeTradeExpiryDate(newValue);
                                                    }}
                                                    // wrapperClass={'col12'}
                                                    format="DD-MM-YYYY"
                                                    inputFormat="dd-MM-yyyy"
                                                    ReadOnly={preview ? true : false}
                                                    minDate={today && dayjs(today)}
                                                />
                                            </LocalizationProvider>)}
                                    </div>
                                </div>
                                <div className='inputBox supplier sm-25 lg-20'>
                                    <Input
                                        label={'Health License Number'}
                                        type={'text'}
                                        name={'healthLicenceNumber'}
                                        id={'healthLicenceNumber'}
                                        value={values.healthLicenceNumber}
                                        wrapperClass={'col12'}
                                        onChange={handleChange}
                                        ReadOnly={preview ? true : false}
                                        placeholder={preview && values.healthLicenceNumber == '' ? '-' : 'Please type'}
                                    />
                                </div>
                                <div className='selectDate'>
                                    <label className='label'>Expiry date</label>
                                    <div className='inputBox datePicker sm-25 lg-20'>
                                        {preview ? (
                                            <span style={{minWidth:'250px'}}> {values.healthExpiryDate}</span>
                                        ) : (<LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={healthExpiryDate ? dayjs(healthExpiryDate) : null}
                                                className='dateFilter'
                                                onChange={(newValue) => {
                                                    handleChangehealthExpiryDate(newValue);
                                                }}
                                                wrapperClass={'col12'}
                                                name='healthExpiryDate'
                                                format="DD-MM-YYYY"
                                                ReadOnly={preview ? true : false}
                                                minDate={today && dayjs(today)}
                                            />
                                        </LocalizationProvider>
                                        )}
                                    </div>
                                </div>
                                {/* <div className='inputBox sm-25 lg-20'>
                                    {/* <DatePicker
                                        label={'Expiry date'}
                                        wrapperClass={'col12'}
                                        name={"healthExpiryDate"}
                                        value={values.healthExpiryDate}
                                        dateFormat={'dd-MM-yyyy'}
                                        onChange={handleChangeDate}
                                        isLedger={true}
                                        ReadOnly={preview ? true : false}
                                        disabledPastDates={true}
                                    /> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </>}

                {currentTabvalue === 1 &&
                    <>
                        <div className='contactInfo_section'>
                            <div className='first_flexbox'>
                                <h5 className='title'>Company Address</h5>
                                <div className='inputBox country sm-20 lg-20'>
                                    <Select
                                        label={'Country'}
                                        name={'country'}
                                        options={
                                            ProductCreateList &&
                                            ProductCreateList.country.length > 0 &&
                                            ProductCreateList.country.map(item => {
                                                return { id: item.name, value: item.name };
                                            })
                                        }
                                        wrapperClass={'sm-40 '}
                                        value={values.country}
                                        onChange={handleChange}
                                        error={errors.country}
                                        touched={touched.country}
                                        disabled={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox city sm-20 lg-20'>
                                    <Input
                                        label={'City'}
                                        type={'text'}
                                        name={'city'}
                                        id={'city'}
                                        wrapperClass={'sm-40 '}
                                        value={values.city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox zipCode sm-10 lg-10'>
                                    <Input
                                        label={'Zip Code'}
                                        type={'text'}
                                        name={'zipCode'}
                                        id={'zipCode'}
                                        value={values.zipCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox addressLine1 sm-25 lg-25'>
                                    <Input
                                        label={'Address Line 1'}
                                        type={'text'}
                                        name={'addressLine1'}
                                        id={'addressLine1'}
                                        wrapperClass={'col6'}
                                        value={values.addressLine1}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox addressLine2 sm-25 lg-25'>
                                    <Input
                                        label={'Address Line 2'}
                                        type={'text'}
                                        name={'addressLine2'}
                                        id={'addressLine2'}
                                        wrapperClass={'col6'}
                                        value={values.addressLine2}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>

                            </div>

                            <div className='second_flexbox'>
                                <div className='inputBox email sm-25 lg-25'>
                                    <Input
                                        label={'Email'}
                                        type={'text'}
                                        name={'email'}
                                        id={'email'}
                                        placeholder='you@example.com'
                                        value={values.email}
                                        error={errors.email}
                                        touched={touched.email}
                                        wrapperClass={'col12'}
                                        onChange={handleChange}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox phoneNumber sm-30 lg-30'>
                                    <SelectWithInput
                                        label={'Phone Number'}
                                        selectName={'phoneNumberCountry'}
                                        options={[
                                            { id: 'India', value: 'IND' },
                                            { id: 'USA', value: 'USA' },
                                            { id: 'England', value: 'ENG' },
                                        ]}
                                        inputName={'phoneNumber'}
                                        placeholder={'+1 (000) 000-0000'}
                                        wrapperClass={'col12'}
                                        onInputChange={handleChange}
                                        onSelectChange={onSelectChange}
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                    />
                                </div>
                                <div className='inputBox website sm-25 lg-25'>
                                    <Input
                                        label={'Website'}
                                        type={'text'}
                                        name={'website'}
                                        id={'website'}
                                        wrapperClass={'col12 '}
                                        value={values.website}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox faxNo sm-15 lg-15'>
                                    <Input
                                        label={'Fax No.'}
                                        type={'text'}
                                        name={'faxNo'}
                                        id={'faxNo'}
                                        wrapperClass={'sm10'}
                                        value={values.faxNo}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>

                            </div>

                            <div className='second_flexbox'>
                                <h5 className='title'>Owner Details</h5>
                                <div className='inputBox ownerFullName sm-30 lg-30'>
                                    <Input
                                        label={'Full Name'}
                                        type={'text'}
                                        name={'ownerFullName'}
                                        id={'ownerFullName'}
                                        wrapperClass={'col12 '}
                                        value={values.ownerFullName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox ownerEmail sm-30 lg-30'>
                                    <Input
                                        label={'Email'}
                                        type={'text'}
                                        name={'ownerEmail'}
                                        id={'ownerEmail'}
                                        placeholder='you@example.com'
                                        value={values.ownerEmail}
                                        error={errors.ownerEmail}
                                        touched={touched.ownerEmail}
                                        wrapperClass={'col12'}
                                        onChange={handleChange}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox ownerPhoneNumber sm-30 lg-30'>
                                    <SelectWithInput
                                        label={'Phone Number'}
                                        selectName={'ownerPhoneNumberCountry'}
                                        options={[
                                            { id: 'India', value: 'IND' },
                                            { id: 'USA', value: 'USA' },
                                            { id: 'England', value: 'ENG' },
                                        ]}
                                        inputName={'ownerPhoneNumber'}
                                        placeholder={'+1 (000) 000-0000'}
                                        wrapperClass={'col12'}
                                        onSelectChange={onSelectChange}
                                        onInputChange={handleChange}
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                    />
                                </div>

                            </div>


                            <div className='third_flexbox'>
                                <h5 className='title'>Contact Person Details</h5>
                                <div className='inputBox contactFullName sm-30 lg-30'>
                                    <Input
                                        label={'Full Name'}
                                        type={'text'}
                                        name={'contactFullName'}
                                        id={'contactFullName'}
                                        wrapperClass={'col12 '}
                                        value={values.contactFullName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox contactEmail sm-30 lg-30'>
                                    <Input
                                        label={'Email'}
                                        type={'text'}
                                        name={'contactEmail'}
                                        id={'contactEmail'}
                                        placeholder='you@example.com'
                                        value={values.contactEmail}
                                        error={errors.contactEmail}
                                        touched={touched.contactEmail}
                                        wrapperClass={'col12'}
                                        onChange={handleChange}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox contactDesignation sm-30 lg-30'>
                                    <Input
                                        label={'Designation'}
                                        type={'text'}
                                        name={'contactDesignation'}
                                        id={'contactDesignation'}
                                        wrapperClass={'col12 '}
                                        value={values.contactDesignation}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox officePhoneNumber sm-30 lg-30'>
                                    <SelectWithInput
                                        label={'Office Phone Number'}
                                        selectName={'officePhoneNumberCountry'}
                                        options={[
                                            { id: 'India', value: 'IND' },
                                            { id: 'USA', value: 'USA' },
                                            { id: 'England', value: 'ENG' },
                                        ]}
                                        inputName={'officePhoneNumber'}
                                        placeholder={'+1 (000) 000-0000'}
                                        wrapperClass={'col12'}
                                        onInputChange={handleChange}
                                        onSelectChange={onSelectChange}
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                    />
                                </div>
                                <div className='inputBox mobilePhoneNumber sm-30 lg-30'>
                                    <SelectWithInput
                                        label={'Mobile Phone Number'}
                                        selectName={'mobilePhoneNumberCountry'}
                                        options={[
                                            { id: 'India', value: 'IND' },
                                            { id: 'USA', value: 'USA' },
                                            { id: 'England', value: 'ENG' },
                                        ]}
                                        inputName={'mobilePhoneNumber'}
                                        placeholder={'+1 (000) 000-0000'}
                                        wrapperClass={'col12'}
                                        onInputChange={handleChange}
                                        onSelectChange={onSelectChange}
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                        </div>
                    </>}

                {currentTabvalue === 2 &&
                    <>
                        <div className='first_flexbox'>
                            <div className='inputBox Product_Name sm-30 lg-30'>
                                <Select
                                    label={'Credit Application'}
                                    name={'creditApplication'}
                                    options={[
                                        { id: 'Received', value: 'Received' },
                                    ]}
                                    wrapperClass={'sm-40 '}
                                    value={values.creditApplication}
                                    onChange={handleChange}
                                    error={errors.creditApplication}
                                    touched={touched.creditApplication}
                                    disabled={preview ? true : false}
                                />
                            </div>
                        </div>
                        <div className='first_flexbox'>
                            <h5 className='title'>Address</h5>
                            <div className='inputBox Product_Name sm-25 lg-25'>
                                <Select
                                    label={'Bill Export'}
                                    name={'billExport'}
                                    options={[
                                        { id: 'GSTN', value: 'GSTN' },
                                    ]}
                                    wrapperClass={'sm-40 '}
                                    value={values.billExport}
                                    onChange={handleChange}
                                    error={errors.billExport}
                                    touched={touched.billExport}
                                    disabled={preview ? true : false}
                                />
                            </div>
                            <div className='inputBox Product_Name sm-25 lg-25'>
                                <Select
                                    label={'VAT Heading'}
                                    name={'vatHeading'}
                                    options={[
                                        { id: 'Local', value: 'Local' },
                                    ]}
                                    wrapperClass={'sm-40 '}
                                    value={values.vatHeading}
                                    onChange={handleChange}
                                    error={errors.vatHeading}
                                    touched={touched.vatHeading}
                                    disabled={preview ? true : false}
                                />
                            </div>
                            <div className='inputBox Product_Name sm-25 lg-25'>
                                <Input
                                    label={'VAT Number'}
                                    type={'text'}
                                    name={'vatNumber'}
                                    id={'vatNumber'}
                                    value={values.vatNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    ReadOnly={preview ? true : false}
                                />
                            </div>
                            <div className='inputBox Product_Name sm-25 lg-25'>
                                <Select
                                    label={'Currency'}
                                    name={'currency'}
                                    options={[
                                        { id: 'USD', value: 'USD' },
                                    ]}
                                    wrapperClass={'sm-40 '}
                                    value={values.currency}
                                    onChange={handleChange}
                                    error={errors.currency}
                                    touched={touched.currency}
                                    disabled={preview ? true : false}
                                />
                            </div>
                        </div>

                        <div className='second_flexbox'>
                            <div className='inputBox Product_Name sm-30 lg-30'>
                                <Select
                                    label={'Legal Compliance Status'}
                                    name={'complianceStatus'}
                                    options={[
                                        { id: 'Compliant', value: 'Compliant' },
                                    ]}
                                    wrapperClass={'sm-40 '}
                                    value={values.legalComplianceStatus}
                                    onChange={handleChange}
                                    error={errors.legalComplianceStatus}
                                    touched={touched.legalComplianceStatus}
                                    disabled={preview ? true : false}
                                />
                            </div>
                            <div className='inputBox Product_Name sm-30 lg-30'>
                                <Select
                                    label={'Outstanding Disputes'}
                                    name={'outstandingDisputes'}
                                    options={[
                                        { id: 'None', value: 'None' },
                                    ]}
                                    wrapperClass={'sm-40 '}
                                    value={values.outstandingDisputes}
                                    onChange={handleChange}
                                    error={errors.outstandingDisputes}
                                    touched={touched.outstandingDisputes}
                                    disabled={preview ? true : false}
                                />
                            </div>
                            <div className='inputBox Product_Name sm-30 lg-30'>
                                <Input
                                    label={'Audit Log'}
                                    type={'text'}
                                    placeholder={" "}
                                    name={'auditLog'}
                                    id={'auditLog'}
                                    value={values.auditLog}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    ReadOnly={preview ? true : false}
                                    disabled
                                />
                            </div>
                        </div>

                        <div className='second_flexbox'>
                            <div className='inputBox Product_Name sm-30 lg-30'>
                                <Input
                                    label={'Tax ID'}
                                    type={'text'}
                                    name={'taxId'}
                                    id={'taxId'}
                                    value={values.taxId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    ReadOnly={preview ? true : false}
                                />
                            </div>
                            <div className='inputBox Product_Name sm-30 lg-30'>
                                <Input
                                    label={'Compliance Officer Name'}
                                    type={'text'}
                                    name={'complianceOfficerName'}
                                    id={'taxId'}
                                    value={values.complianceOfficerName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    ReadOnly={preview ? true : false}
                                />
                            </div>
                            <div className='inputBox Product_Name sm-30 lg-30'>
                                <Select
                                    label={'Tax Filling Frequency'}
                                    name={'taxFilingFrequency'}
                                    options={[
                                        { id: 'Monthly', value: 'Monthly' },
                                    ]}
                                    wrapperClass={'sm-40 '}
                                    value={values.taxFilingFrequency}
                                    onChange={handleChange}
                                    error={errors.taxFilingFrequency}
                                    touched={touched.taxFilingFrequency}
                                    disabled={preview ? true : false}
                                />
                            </div>
                        </div>

                        <div className='second_flexbox'>
                            <h5 className='title'>Owner Details</h5>
                            <div className='inputBox Product_Name sm-20 lg-20'>
                                <Input
                                    label={'Corporate Tax ID'}
                                    type={'text'}
                                    name={'corporateTaxId'}
                                    id={'corporateTaxId'}
                                    wrapperClass={'col12 '}
                                    value={values.corporateTaxId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    ReadOnly={preview ? true : false}
                                />
                            </div>
                            <div className='inputBox Product_Name sm-20 lg-20'>
                                <Input
                                    label={'Credit Limit'}
                                    type={'text'}
                                    name={'creditLimit'}
                                    id={'creditLimit'}
                                    wrapperClass={'col12 '}
                                    value={values.creditLimit}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    ReadOnly={preview ? true : false}
                                />
                            </div>
                            <div className='inputBox Product_Name sm-20 lg-20'>
                                <Input
                                    label={'Credit Days'}
                                    type={'text'}
                                    name={'creditDays'}
                                    id={'creditDays'}
                                    wrapperClass={'col12 '}
                                    value={values.creditDays}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    ReadOnly={preview ? true : false}
                                />
                            </div>
                            <div className='inputBox Product_Name sm-20 lg-20'>
                                <Input
                                    label={'Bill Limit'}
                                    type={'text'}
                                    name={'billLimit'}
                                    id={'billLimit'}
                                    wrapperClass={'col12 '}
                                    value={values.billLimit}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    ReadOnly={preview ? true : false}
                                />
                            </div>
                            <div className='inputBox Product_Name sm-20 lg-20'>
                                <Input
                                    label={'Invoice Limit'}
                                    type={'text'}
                                    name={'invoiceLimit'}
                                    id={'invoiceLimit'}
                                    wrapperClass={'col12 '}
                                    value={values.invoiceLimit}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    ReadOnly={preview ? true : false}
                                />

                            </div>

                        </div>
                    </>}
                {currentTabvalue === 3 &&
                    <>
                        <div className='payment_section'>
                            <div className='first_flexbox'>
                                <div className='inputBox billDiscount sm-20 lg-20'>
                                    <Input
                                        label={'Bill Discount'}
                                        type={'text'}
                                        name={'billDiscount'}
                                        id={'billDiscount'}
                                        wrapperClass={'col12 '}
                                        value={values.billDiscount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox invoiceFormat sm-20 lg-20'>
                                    <Select
                                        label={'Invoice Format'}
                                        name={'invoiceFormat'}
                                        options={[
                                            { id: 'Default', value: 'Default' },
                                        ]}
                                        wrapperClass={'sm-40 '}
                                        value={values.invoiceFormat}
                                        onChange={handleChange}
                                        error={errors.invoiceFormat}
                                        touched={touched.invoiceFormat}
                                        disabled={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox exportBillFormat sm-20 lg-20'>
                                    <Select
                                        label={'Export Bill Format'}
                                        name={'exportBillFormat'}
                                        options={[
                                            { id: 'Detailed', value: 'Detailed' },
                                        ]}
                                        wrapperClass={'sm-40 '}
                                        value={values.exportBillFormat}
                                        onChange={handleChange}
                                        error={errors.exportBillFormat}
                                        touched={touched.exportBillFormat}
                                        disabled={preview ? true : false}
                                    />
                                </div>
                            </div>

                            <div className='second_flexbox'>
                                <h5 className='title'>Bank Rebate</h5>
                                <div className='inputBox rebatePercentage sm-30 lg-30'>
                                    <Input
                                        label={'Rebate Percentage'}
                                        type={'text'}
                                        name={'rebatePercentage'}
                                        id={'rebatePercentage'}
                                        wrapperClass={'col12 '}
                                        value={values.rebatePercentage}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox rebateNumberOfDays sm-30 lg-30'>
                                    <Input
                                        label={'Number Of Days'}
                                        type={'text'}
                                        name={'rebateNumberOfDays'}
                                        id={'rebateNumberOfDays'}
                                        wrapperClass={'col12 '}
                                        value={values.rebateNumberOfDays}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>

                            </div>
                            <div className='second_flexbox'>
                                <h5 className='title'>Payment Term and Conditions</h5>
                                <div className='inputBox collectionDays sm-20 lg-20'>
                                    <Input
                                        label={'Collection Days'}
                                        type={'text'}
                                        name={'collectionDays'}
                                        id={'collectionDays'}
                                        wrapperClass={'col12 '}
                                        value={values.collectionDays}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox paymentLimit sm-20 lg-20'>
                                    <Input
                                        label={'Payment Limit'}
                                        type={'text'}
                                        name={'paymentLimit'}
                                        id={'paymentLimit'}
                                        wrapperClass={'col12 '}
                                        value={values.paymentLimit}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox paymentPercentage sm-20 lg-20'>
                                    <Input
                                        label={'Payment %'}
                                        type={'text'}
                                        name={'paymentPercentage'}
                                        id={'paymentPercentage'}
                                        wrapperClass={'col12 '}
                                        value={values.paymentPercentage}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                            </div>

                            <div className='third_flexbox multipaymentMode'>
                                {/* <FormControl className='switch_radio'>
                                <FormLabel id='demo-radio-buttons-group-label'>Multiple Payment Modes</FormLabel>
                                <RadioGroup
                                    aria-labelledby='demo-radio-buttons-group-label'
                                    defaultValue='Cash'
                                    name='radio-buttons-group'
                                    value={values.paymentModes}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value='Cash'  control={<Radio name='paymentModes' />} label='Cash' />
                                    <FormControlLabel value='CreditCard' control={<Radio name='paymentModes' />} label='Credit Card' />
                                    <FormControlLabel value='DebitCard' control={<Radio name='paymentModes' />} label='Debit Card' />
                                    <FormControlLabel value='BankTransfer' control={<Radio name='paymentModes' />} label='Bank Transfer' />
                                </RadioGroup>
                            </FormControl> */}
                                <h5 className='title'>Multiple Payment Modes</h5>
                                {preview ?
                                    <div className='paymentMode'>{previewData.paymentModes ? previewData.paymentModes.join(",") : ''}</div> : <FormGroup >
                                        <FormControlLabel checked={values.paymentModes.includes('Cash')} onChange={() => handleChangePaymentModes('Cash')} name='paymentModes' control={<Checkbox />} label='Cash' />
                                        <FormControlLabel checked={values.paymentModes.includes('CreditCard')} onChange={() => handleChangePaymentModes('CreditCard')} name='paymentModes' control={<Checkbox />} label='Credit Card' />
                                        <FormControlLabel checked={values.paymentModes.includes('DebitCard')} onChange={() => handleChangePaymentModes('DebitCard')} name='paymentModes' control={<Checkbox />} label='Debit Card' />
                                        <FormControlLabel checked={values.paymentModes.includes('BankTransfer')} onChange={() => handleChangePaymentModes('BankTransfer')} name='paymentModes' control={<Checkbox />} label='Bank Transfer' />
                                    </FormGroup>}

                            </div>
                        </div>
                    </>}

                {currentTabvalue === 4 &&
                    <>
                        <div className='salesmanInfo_section'>
                            <div className='first_flexbox'>
                                <div className='inputBox productCode sm-25 lg-25'>
                                    <Input
                                        label={'Area Code'}
                                        type={'text'}
                                        name={'salesmanAreaCode'}
                                        id={'salesmanAreaCode'}
                                        wrapperClass={'col12 '}
                                        value={values.salesmanAreaCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox Product_Name sm-25 lg-25'>
                                    <Select
                                        label={'Territory'}
                                        name={'salesmanTerritory'}
                                        options={[
                                            { id: 'North Zone', value: 'North Zone' },
                                        ]}
                                        wrapperClass={'sm-40 '}
                                        value={values.salesmanTerritory}
                                        onChange={handleChange}
                                        error={errors.salesmanTerritory}
                                        touched={touched.salesmanTerritory}
                                        disabled={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox sm-25 lg-25'>
                                    <Select
                                        label={'Region'}
                                        name={'salesmanRegion'}
                                        options={[
                                            { id: 'Midwest', value: 'Midwest' },
                                        ]}
                                        wrapperClass={'sm-40 '}
                                        value={values.salesmanRegion}
                                        onChange={handleChange}
                                        error={errors.salesmanRegion}
                                        touched={touched.salesmanRegion}
                                        disabled={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox sm-25 lg-25'>
                                    <Select
                                        label={'Country'}
                                        name={'salesmanCountry'}
                                        options={[
                                            { id: 'India', value: 'India' },
                                            { id: 'United States', value: 'United State' },
                                        ]}
                                        wrapperClass={'sm-40 '}
                                        value={values.salesmanCountry}
                                        onChange={handleChange}
                                        error={errors.salesmanCountry}
                                        touched={touched.salesmanCountry}
                                        disabled={preview ? true : false}
                                    />
                                </div>
                            </div>

                            <div className='third_flexbox'>

                                <div className='inputBox Product_Name sm-25 lg-25'>
                                    <Select
                                        label={'Business Activity'}
                                        name={'businessActivity'}
                                        options={[
                                            { id: 'Retail Pharma', value: 'Retail Pharma' },
                                        ]}
                                        wrapperClass={'col12 '}
                                        value={values.businessActivity}
                                        onChange={handleChange}
                                        error={errors.businessActivity}
                                        touched={touched.businessActivity}
                                        disabled={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox productCode sm-25 lg-25'>
                                    <Input
                                        label={'Sales Man'}
                                        type={'text'}
                                        name={'salesman'}
                                        id={'salesman'}
                                        wrapperClass={'col12 '}
                                        value={values.salesman}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        ReadOnly={preview ? true : false}
                                    />
                                </div>

                                <div className='inputBox sm-25 lg-25'>
                                    <Select
                                        label={'Deliver Route'}
                                        name={'deliveryRoute'}
                                        options={[
                                            { id: 'Route 22', value: 'Route 22' },
                                        ]}
                                        wrapperClass={'col12'}
                                        value={values.deliveryRoute}
                                        onChange={handleChange}
                                        error={errors.deliveryRoute}
                                        touched={touched.deliveryRoute}
                                        disabled={preview ? true : false}
                                    />
                                </div>
                                <div className='inputBox sm-25 lg-25 collectionDays'>
                                    <h5 className='title'>Collection Days</h5>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        className='fieldBox'
                                        disabled={preview ? true : false}
                                    >
                                        <span className='data'> {values.salesmanCollectionDays.join(",")}</span>
                                    </Button>

                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}

                                    >
                                        {
                                            FilterDayList.map((name, key) => {
                                                return (
                                                    <MenuItem onClick={() => handleChangeSalesmanCollectionDays(name)}>
                                                        <Checkbox checked={values.salesmanCollectionDays.indexOf(name) > -1} />
                                                        <ListItemText primary={name} /></MenuItem>
                                                )
                                            })
                                        }

                                    </Menu>
                                    {/* <Select
                                    label={'Collection Days'}
                                    name={'salesmanCollectionDays'}
                                    options={FilterDayList}
                                    wrapperClass={'sm-40 '}
                                    value={values.salesmanCollectionDays}
                                    onChange={handleChange}
                                    error={errors.salesmanCollectionDays}
                                    touched={touched.salesmanCollectionDays}
                                    disabled={preview ? true : false}
                                /> */}
                                </div>
                            </div>
                        </div>
                    </>}
                <div className='actionFlexbox'>
                    {preview ? null : (
                        <React.Fragment>
                            {!Object.keys(previewData).length > 0 && (
                                <button
                                    type='button'
                                    className='draftBtn'
                                    onClick={() => {
                                        setDraftClicked(true);
                                        handleSubmit();
                                    }}
                                >
                                    Save Draft
                                </button>
                            )}
                            <div className='rightCol'>
                                <button type='button' className='canceltBtn' onClick={handleBack}>
                                    Back
                                </button>
                                <button type='button' className='nextBtn' onClick={handleSubmit}>
                                    Next
                                </button>
                            </div>{' '}
                        </React.Fragment>
                    )}
                </div>
            </div>
            {draftsuccessModal && (
                <SuccessModal
                    handleSuccessClose={handleDraftSuccessPopupClose}
                    SuccessPopUp={draftsuccessModal}
                    SuccessMsg={SuccessMsg}
                    SuccessTitle={SuccessTitle}
                />
            )}
        </React.Fragment>
    );
}

export default LedgerDetails;
