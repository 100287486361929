import React, { useEffect, useState } from 'react';

import { axiosClient } from '../../../services/axiosClient';
import { dateExtract } from './util';
import { selectOptionsPurchaseType } from './constant';

import SaleSchema from './Component/SaleSchema';
import EditTable from './Component/EidtableTable';
import PurchaseBasicField from './Component/PurchaseBasicField';
import SuccessModal from '../../components/common/CommonSuccessModal/SuccessModal';

import { ReactComponent as ArrowLeft } from '../../../assets/images/arrow-left.svg';
import Download from '../../../assets/images/download.svg';


const CreateNewPurchase = ({ previewData, mode, back, setMode }) => {

    const [salesSchema, setSalesSchema] = useState(false);
    const [vendorName, setVendorName] = useState([]);
    const [productAllName, setProductAllName] = useState([]);

    const [subtotal, setSubtotal] = useState(0); // State to store subtotal
    const [totalCashDiscount, setTotalCashDiscount] = useState(previewData?.discount || 0); // State to store subtotal
    const [totalVat, setTotalVat] = useState(previewData?.vatPercentage || 0); // State to store subtotal
    const [grandTotal, setGrandTotal] = useState(previewData?.grandTotal || 0.00); // State to store subtoal

    const [isDraft, setIsDraft] = useState(false);
    const [selectedSchem, setSelectedSchem] = useState(0);
    const [salesMode, setSalesMode] = useState(null);


    const [rows, setRows] = useState([]);

    const [basicFormValues, setBasicFormValues] = useState({
        purchaseType: '',
        transactionId: '',
        purchaseNumber: '',
        purchaseDate: '',
        invoiceNumber: '',
        invoiceDate: '',
        purchaseById: previewData?.purchaseById,
        discountPercentage: '',
        purchaseBy: previewData?.purchaseBy,
    });

    const [successModal, setSuccessModal] = useState('');
    const [SuccessModalMsg, setSuccessModalMsg] = useState('');

    const [errorModal, setErrorModal] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);

    const handleErrorModalClose = () => {
        setErrorModal(false);
        setErrorMessages('');
    };

    const clearAllState = () => {
        setRows([]);
        setSubtotal(0);
        setTotalCashDiscount(0);
        setTotalVat(0);
        setGrandTotal(0);
        setVendorName([]);
        setProductAllName([]);
    };

    const handleDeleteSuccessModalClose = () => {
        setSuccessModal(false);
        setSuccessModalMsg('');
        setBasicFormValues('');
        clearAllState();
        back();
    };


    const handleBasicChange = (e) => {
        const { name, value } = e.target;
        setBasicFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
        if (name === 'purchaseById') {
            let purchaseBY = vendorName.find(item => item._id === value);
            setBasicFormValues(prevValues => ({
                ...prevValues,
                ['purchaseBy']: purchaseBY?.type,
            }));
            setRows((prevRows) =>
                prevRows.map((row) => ({
                    ...row,
                    product: {
                        ...row.product,
                        name: '',
                        productCode: '',
                    },
                })),
            );
        }
    };

    const handleBasicDateChange = (name, date) => {

        const newDate = dateExtract(date);
        if (newDate) {
            setBasicFormValues(prevValues => ({
                ...prevValues,
                [name]: newDate,
            }));
        } else {
            console.error('Invalid date provided');
        }

    };
    const updateApi = () => {
        // Return the ID only in edit mode
        return mode === 'edit' ? { _id: previewData._id } : {};
    };

    const apiURL = () => {
        // Return the correct API endpoint based on the mode
        return mode === 'edit' ? 'admin/purchase/update' : 'admin/purchase/create';
    };

    const handleSaveData = async (isDraft = false) => {
        const accessToken = `Bearer ${sessionStorage.accessToken}`;

        // Format date to "YYYY-MM-DD"
        const formatDate = (dateString) => {
            const date = typeof dateString === 'string' || dateString instanceof String
                ? new Date(dateString)
                : dateString;
            return date ? date.toISOString().slice(0, 10) : null;
        };

        const url = apiURL();

        try {
            // Filter out items where all key values are empty, null, or undefined
                const filteredItems = rows
                    .map(item => {
                        //if (!item.product_id) return null; // Return null if product_id is blank
                        return {
                            ...item,
                            product: item.product_id,// Use the product_id
                            expiryDate: item.expiryDate ? formatDate(item.expiryDate) : null, // Format expiryDate
                            buyingPrice: item.invPrice || 0, // Default to 0 if invPrice is missing
                            ...(item._id ? { _id: item._id } : {}), // Conditionally add _id only if it exists
                        };
                    })
                    .filter(item => {
                        // Remove null items and items where all values are empty, null, or undefined
                        if (!item) return false; // Remove null items
                        return Object.values(item).some(value => value !== '' && value !== null && value !== undefined);
                    });


            const payload = {
                ...basicFormValues,
                purchaseType: selectOptionsPurchaseType.find(
                    (item) => item.id === basicFormValues.purchaseType
                )?.value || basicFormValues.purchaseType,

                purchaseDate: basicFormValues.purchaseDate ? basicFormValues.purchaseDate : null,
                invoiceDate: basicFormValues.invoiceDate ? basicFormValues.invoiceDate : null,
                subtotal: subtotal || 0,
                totalCashDiscount: totalCashDiscount || 0,
                totalVat: totalVat || 0,
                grandTotal: grandTotal || 0,
                isDraft: isDraft,
                ...updateApi(),  // Adds {_id: previewData._id} if in edit mode
                ...(filteredItems.length > 0 ? { items: filteredItems } : {}), // Include items only if not empty
            };

            let response = await axiosClient.post(url, JSON.stringify(payload), {
                headers: {
                    'Content-Type': 'application/json',
                    'x-via-device': true,
                    Authorization: accessToken,
                },
            });

            if (response.status === 200) {
                setSuccessModal(true);
                setSuccessModalMsg(response?.data?.message);
                // setDraftListData(response?.data?.data?.products);
            }
        } catch (error) {
            setErrorModal(true);
            if (error.response && error.response.data) {

                // Extract validation errors if they exist
                const messages = error.response.data.message || []; // Assuming your errors are in an array
                let newMsgArray = messages.split('.,');
                const cleanedMessages = newMsgArray.map(msg => msg.replace(/^Path\s+/, ''));
                setErrorMessages(cleanedMessages); // Set error messages state
            } else {
                // Fallback for other errors
                setErrorMessages(['An unexpected error occurred.']);
            }
        }
    };


    const onSubmitHandler = () => {
        handleSaveData();
    };

    const onSubmitDraft = () => {
        setIsDraft(true);
        setTimeout(() => {
            setIsDraft((draft) => {
                handleSaveData(true);
                return draft;
            });
        });
    };


    const onCreateSalesScheme = () => {
        setSalesSchema(true);
    };

    // Fetch vendor names from the API
    const productNames = async () => {
        const accessToken = `Bearer ${sessionStorage.accessToken}`;
        try {
            let response = await axiosClient.post(
                `admin/purchase/productList`,
                JSON.stringify({
                    'purchaseById': basicFormValues.purchaseById,
                    'purchaseBy': basicFormValues.purchaseBy,
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-via-device': true,
                        Authorization: accessToken,
                    },
                },
            );
            if (response.status === 200) {
                setProductAllName(response?.data?.data?.products);
            }
        } catch (error) {
            console.log('error', error);
        }

    };

    useEffect(() => {
        productNames();
    }, [basicFormValues.purchaseById]);


    return (
        <>
            <div className={`ledgerMaster_container purchaseWrap ${mode}`}>
                <div className='backLink'>
                <span className='link' onClick={handleDeleteSuccessModalClose}>
                    <span className='icon'>
                        <ArrowLeft/>
                    </span>
                    Back to Purchase List
                </span>
                </div>
                <PurchaseBasicField
                    previewData={previewData}
                    formValues={basicFormValues}
                    setFormValues={setBasicFormValues}
                    handleChange={handleBasicChange}
                    handleDateChange={handleBasicDateChange}
                    vendorName={vendorName}
                    setVendorName={setVendorName}
                    mode={mode}
                    setMode={setMode}
                />
                <div className="itemEntry">
                    <h3>Item Entry</h3>
                    <div className="rightImport">
                        <div className="current">
                            Current Balance : <strong>$ 1500</strong>
                        </div>
                        <button className='actionBtn'>
                            <span className='icon'>
                                <img src={Download} alt='upload' className='img'></img>
                            </span>
                            Import
                        </button>
                    </div>
                </div>
                <EditTable
                    tableData={previewData?.items}
                    previewData={previewData}
                    onCreateSalesScheme={onCreateSalesScheme}
                    productAllName={productAllName}
                    rows={rows}
                    setRows={setRows}
                    basicFormValues={basicFormValues}
                    subtotal={subtotal}
                    setSubtotal={setSubtotal}
                    totalCashDiscount={totalCashDiscount}
                    setTotalCashDiscount={setTotalCashDiscount}
                    totalVat={totalVat}
                    setTotalVat={setTotalVat}
                    grandTotal={grandTotal}
                    setGrandTotal={setGrandTotal}
                    mode={mode}
                    setSelectedSchem={setSelectedSchem}
                    setSalesMode={setSalesMode}
                />
                <div className="Bottom">
                    <div className="item">
                        <div className="btnGroup">
                            <button type="button" class="cancelBtn saveDraft" onClick={onSubmitDraft}>Save Draft
                            </button>
                        </div>
                    </div>
                    <div className="item">
                        <div class="btnGroup">
                            <button type="button" class="cancelBtn" onClick={handleDeleteSuccessModalClose}>Cancel
                            </button>
                            <button type="button" class="nextBtn" onClick={onSubmitHandler}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            {salesSchema && <SaleSchema setSalesSchema={setSalesSchema} rows={rows} selectedSchem={selectedSchem} salesMode={salesMode} setSalesMode={setSalesMode}/>}

            {successModal && (
                <SuccessModal
                    handleSuccessClose={handleDeleteSuccessModalClose}
                    SuccessPopUp={successModal}
                    SuccessTitle={SuccessModalMsg}
                    // SuccessMsg={SuccessModalMsg}
                />
            )}

            {errorModal && (
                <SuccessModal
                    handleSuccessClose={handleErrorModalClose}
                    SuccessPopUp={errorModal}
                    SuccessTitle={'Error'}
                    SuccessMsg={
                        <ul className={'errorMsgList'}>
                            {
                                errorMessages.map((item, index) => {
                                    return (
                                        <li key={index + item}>{item}</li>
                                    );
                                })
                            }
                        </ul>
                    }
                />
            )}
        </>
    );
};

export default CreateNewPurchase;