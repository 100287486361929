import React from 'react';
import locales from '../../constants/en.json';
import { ReactComponent as RightArrow } from '../../../assets/images/chevron-right.svg';
import Server from '../../../assets/images/server.svg';
import Feature from '../../../assets/images/feature-2.svg';
import FeatureNew from '../../../assets/images/feature-3.svg';
import CheckCircle from '../../../assets/images/check-circle.svg';
import { efficencyData, servicesData } from './configs';
import OurPartners from '../../components/OurPartners/OurPartners';
import StreamlineTransforming from '../../components/StreamlineTransforming/StreamlineTransforming';
import RatingViews from '../../components/RatingViews/RatingViews';
import PlanPricing from '../../components/PlanPricing/PlanPricing';
import FAQS from '../../components/FAQS/FAQS';
import Testimonials from '../../components/Testimonials/Testimonials';
import FeedbackForm from '../../components/FeedbackForm/FeedbackForm';
import './Home.scss';

function Home(props) {
    return (
        <React.Fragment>
            <div className='homepage_container'>
                <div className='homepage_banner_section'>
                    <div className='center_content'>
                        <h2 className='heading'>
                            Innovative <br></br> <span className='highlight'>Software Solutions</span> for
                        </h2>
                        <h3 className='sub_heading'>Pharmacy, Supermarket & Auto Retail</h3>
                        <div className='grid_action'>
                            <button className='btn_blue'>Get started free</button>
                            <button className='btn_outline'>See all plans</button>
                        </div>
                    </div>
                </div>
                <div className='services_section'>
                    <div className='container'>
                        <div className='efficiency_card_box'>
                            {efficencyData.map(item => {
                                return (
                                    <div className='card_box'>
                                        <span className='img_box'>
                                            <img src={item.icon} alt='icon'></img>
                                        </span>
                                        <span className='view_data'>{item.views}</span>
                                        <span className='info'>{item.data}</span>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='services_grid_box'>
                            {servicesData.map(item => {
                                return (
                                    <div className='card_container'>
                                        <div className='flexbox'>
                                            <div className='img_block'>
                                                {item.icon}
                                                {/* <img src={item.icon} alt="icon"></img> */}
                                            </div>
                                            <div className='content'>
                                                <h4 className='title'>{item.title}</h4>
                                                <span className='info'>{item.detail}</span>
                                            </div>
                                            <span className='right_arrow'>
                                                <RightArrow />
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                            <span className='services_link'>
                                View all services
                                <span className='icon'>
                                    <RightArrow />
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <OurPartners />
                <div className='our_advantage_section'>
                    <div className='container'>
                        <h5 className='subtitle'>Officia esse deserunt magna</h5>
                        <h4 className='title'>Our Advantages</h4>
                        <div className='feature_gridbox'>
                            <div className='flex_item'>
                                <div className='img_block lightBlue'>
                                    <img src={Server} alt='feature-icon-1'></img>
                                </div>
                                <h4 className='card-title'>Feature name</h4>
                                <span className='card_info'>Aliqua incididunt elit ea deserunt magna anim aute ullamco</span>
                            </div>
                            <div className='flex_item'>
                                <div className='img_block lightsafron'>
                                    <img src={Feature} alt='feature-icon-1'></img>
                                </div>
                                <h4 className='card-title'>Feature name</h4>
                                <span className='card_info'>Aliqua incididunt elit ea deserunt magna anim aute ullamco</span>
                            </div>
                            <div className='flex_item'>
                                <div className='img_block lightviolet'>
                                    <img src={FeatureNew} alt='feature-icon-1'></img>
                                </div>
                                <h4 className='card-title'>Feature name</h4>
                                <span className='card_info'>Aliqua incididunt elit ea deserunt magna anim aute ullamco</span>
                            </div>
                        </div>
                        <div className='learn_more_link'>
                            <span className='learn_more'>
                                {locales.learn_more}
                                <span className='icon'>
                                    <RightArrow />
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='ease_section'>
                    <div className='container'>
                        <div className='grid_box'>
                            <div className='content_flexbox'>
                                <div className='data_side'>
                                    <h5 className='subtitle'>Empower</h5>
                                    <h4 className='title'>Build Your Online Pharmacy Store with Ease</h4>
                                    <p className='info'>
                                        FOGO's ecommerce solutions for pharmacies provide everything you need to set up an online store and start
                                        selling medicines. With our user-friendly platform, you can easily manage your inventory, process orders, and
                                        offer a seamless shopping experience to your customers.
                                    </p>
                                    <ul className='point_list'>
                                        <li>
                                            <span className='icon'>
                                                <img src={CheckCircle} alt='check-circle'></img>
                                            </span>
                                            <span className='text'>Effortlessly manage your inventory and stock levels</span>
                                        </li>
                                        <li>
                                            <span className='icon'>
                                                <img src={CheckCircle} alt='check-circle'></img>
                                            </span>
                                            <span className='text'>Streamline order processing and fulfillment</span>
                                        </li>
                                        <li>
                                            <span className='icon'>
                                                <img src={CheckCircle} alt='check-circle'></img>
                                            </span>
                                            <span className='text'>Deliver a seamless shopping experience to your customers</span>
                                        </li>
                                    </ul>
                                    <div className='actions'>
                                        <button className='sign_up'>{locales.signUp}</button>
                                        <button className='learn_more'>
                                            {locales.learn_more}
                                            <span className='icon'>
                                                <RightArrow />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className='image_side'>{/* <img src={Presentation} className='img_block' alt="img-1"></img> */}</div>
                            </div>
                            <div className='content_flexbox'>
                                <div className='image_side'>{/* <img src={Presentation} className='img_block' alt="img-1"></img> */}</div>
                                <div className='data_side'>
                                    <h5 className='subtitle'>Streamline</h5>
                                    <h4 className='title'>Efficiently manage your Pharmacy's finances with ease</h4>
                                    <p className='info'>
                                        FOGO's finance and accounting features provide a comprehensive solution for managing your pharmacy's finances.
                                        From tracking sales and expenses to generating financial reports, our software simplifies the financial
                                        management process.
                                    </p>
                                    <div className='sales_manage_flexgrid'>
                                        <div className='flex_item'>
                                            <h5 className='title'>Sales Tracking</h5>
                                            <p className='info'>
                                                Track sales in real-time and gain valuable insights into your pharmacy's performance.
                                            </p>
                                        </div>
                                        <div className='flex_item'>
                                            <h5 className='title'>Expense Management</h5>
                                            <p className='info'>
                                                Effortlessly manage and track expenses, ensuring accurate financial records for your pharmacy.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='actions'>
                                        <button className='sign_up'>{locales.signUp}</button>
                                        <button className='learn_more'>
                                            {locales.learn_more}
                                            <span className='icon'>
                                                <RightArrow />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <StreamlineTransforming />
                <RatingViews />
                <PlanPricing />
                <FAQS />
                <Testimonials />
                <FeedbackForm />
            </div>
        </React.Fragment>
    );
}

export default Home;
