import { axiosClient } from './axiosClient';

export const loginService = async values => {
    return await axiosClient.post(`admin/vendor/login`, JSON.stringify({ ...values })).then(res => res.data);
};

export const getRequiredListingsService = async () => {
    return await axiosClient.get(`admin/vendor/listRegistration`).then(res => res.data);
};

export const otpvVerificationService = async values => {
    return await axiosClient.post(`admin/vendor/verify`, JSON.stringify({ ...values }));
};
export const purchaseService = async values => {
    return await axiosClient.post(`admin/vendor/purchase`, JSON.stringify({ ...values }));
};

export const registrationService = async values => {
    return await axiosClient.post(`admin/vendor/registration`, JSON.stringify({ ...values }));
};

export const signupVerificationService = async values => {
    return await axiosClient.post(`admin/vendor/onboard`, JSON.stringify({ ...values }));
};