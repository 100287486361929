import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FAQS from '../../../components/FAQS/FAQS';
import Testimonials from '../../../components/Testimonials/Testimonials';
import { blogsfirstData, BlogPostData } from '../config';
import Banner from '../../../../assets/images/blog_page_banner.png';
import BlogFooterBanner from '../../../../assets/images/blog_footer_banner.png';
import './Blog.scss';

function Blog() {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const hiringtabData = ['View All', 'Design', 'Software Engineering', 'Customer Success', 'Sales', 'Marketing'];

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <div className='blog_page_container'>
                <div className='blog_page_banner'>
                    <img src={Banner} alt='banner' className='banner_img'></img>
                </div>
                <div className='blog_grid_section'>
                    <div className='container'>
                        <Box sx={{ width: '100%' }}>
                            <Box className='tab_container'>
                                <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                                    {hiringtabData.map((item, index) => {
                                        return <Tab label={item} {...a11yProps(index)} />;
                                    })}
                                </Tabs>
                            </Box>
                            <CustomTabPanel className='tabData_box' value={value} index={0}>
                                <div className='flexbox'>
                                    {blogsfirstData.map((item, index) => {
                                        return (
                                            <div
                                                className='column_card'
                                                onClick={() => {
                                                    navigate('/Article');
                                                }}
                                                key={index}
                                            >
                                                <div className='thumbnail'>
                                                    <img src={item.image} alt='icon'></img>
                                                </div>
                                                <div className='content'>
                                                    <div className='label_flexbox'>
                                                        <span className='label'>{item.label}</span>
                                                        <span className='duration'>{item.duration}</span>
                                                    </div>
                                                    <h5 className='title'>{item.heading}</h5>
                                                    <p className='description'>{item.describe}</p>
                                                    <div className='profile_flexbox'>
                                                        <span className='profile_icon'>
                                                            <img src={item.profileIcon} alt='icon'></img>
                                                        </span>
                                                        <span className='profilename'>{item.profileName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel className='tabData_box' value={value} index={1}>
                                <div className='flexbox'>
                                    {blogsfirstData.map((item, index) => {
                                        return (
                                            <div
                                                className='column_card'
                                                onClick={() => {
                                                    navigate('/Article');
                                                }}
                                                key={index}
                                            >
                                                <div className='thumbnail'>
                                                    <img src={item.image} alt='icon'></img>
                                                </div>
                                                <div className='content'>
                                                    <div className='label_flexbox'>
                                                        <span className='label'>{item.label}</span>
                                                        <span className='duration'>{item.duration}</span>
                                                    </div>
                                                    <h5 className='title'>{item.heading}</h5>
                                                    <p className='description'>{item.describe}</p>
                                                    <div className='profile_flexbox'>
                                                        <span className='profile_icon'>
                                                            <img src={item.profileIcon} alt='icon'></img>
                                                        </span>
                                                        <span className='profilename'>{item.profileName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel className='tabData_box' value={value} index={2}>
                                <div className='flexbox'>
                                    {blogsfirstData.map((item, index) => {
                                        return (
                                            <div
                                                className='column_card'
                                                onClick={() => {
                                                    navigate('/Article');
                                                }}
                                                key={index}
                                            >
                                                <div className='thumbnail'>
                                                    <img src={item.image} alt='icon'></img>
                                                </div>
                                                <div className='content'>
                                                    <div className='label_flexbox'>
                                                        <span className='label'>{item.label}</span>
                                                        <span className='duration'>{item.duration}</span>
                                                    </div>
                                                    <h5 className='title'>{item.heading}</h5>
                                                    <p className='description'>{item.describe}</p>
                                                    <div className='profile_flexbox'>
                                                        <span className='profile_icon'>
                                                            <img src={item.profileIcon} alt='icon'></img>
                                                        </span>
                                                        <span className='profilename'>{item.profileName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </CustomTabPanel>
                        </Box>
                    </div>
                </div>
                <div className='blog_post_section'>
                    <div className='container'>
                        <h5 className='subtitle'>Our Values</h5>
                        <h4 className='title'>Explore Our Blog Posts</h4>
                        <span className='description'>Stay informed with our latest blog posts.</span>
                        <div className='flexbox'>
                            {BlogPostData.map((item, index) => {
                                return (
                                    <div
                                        className='column_card'
                                        key={index}
                                        onClick={() => {
                                            navigate('/Article');
                                        }}
                                    >
                                        <div className='thumbnail'>
                                            <img src={item.image} alt='icon'></img>
                                        </div>
                                        <div className='content'>
                                            <div className='label_flexbox'>
                                                <span className='label'>{item.label}</span>
                                                <span className='duration'>{item.duration}</span>
                                            </div>
                                            <h5 className='title'>{item.heading}</h5>
                                            <p className='description'>{item.describe}</p>
                                            <div className='profile_flexbox'>
                                                <span className='profile_icon'>
                                                    <img src={item.profileIcon} alt='icon'></img>
                                                </span>
                                                <span className='profilename'>{item.profileName}</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <FAQS />
                <Testimonials />
                <div className='blog_footer_banner'>
                    <img src={BlogFooterBanner} alt='banner' className='banner_img'></img>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Blog;
