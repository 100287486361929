import React from 'react';
import { Outlet, Navigate } from "react-router-dom";

function PrivateRoutes() {
    const token = sessionStorage.getItem('accessToken');

    return !token ? (
        <Navigate to={'/'} />
    ) : (
        <Outlet
            context={{
            }}
        />
    );
}

export default PrivateRoutes;
