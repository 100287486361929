import React, { useEffect, useState } from 'react';
import { Input, Select } from '../../../components/common';
import Trash from '../../../../assets/images/trash-2.svg';
import { axiosClient } from '../../../../services/axiosClient';
import SuccessModal from '../../../components/common/CommonSuccessModal/SuccessModal';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const columns = [
    { key: 'quantity', label: 'Quantity' },
    { key: 'bonus', label: 'Bonus' },
    { key: 'margin', label: 'Margin' },
];

const dateFormat = (dateStr) => {
    const date = new Date(dateStr);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits
    const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
    return <span>{`${month}/${year}`}</span>;
};

const EditTable = ({ rows, handleRowChange, onDeleteRow, viewOnly = false, salesMode }) => {
    return (
        <div className={`item ${viewOnly ? 'viewMode' : ''}`}>
            <table>
                <thead>
                <tr>
                    {columns.map((column) => (
                        <th key={column.key}>{column.label}</th>
                    ))}
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {rows?.map((row, index) => (
                    <tr key={index}>
                        {columns.map((column) => (
                            <td key={column.key}>
                                <Input
                                    type="text"
                                    name={column.key}
                                    value={row[column.key]}
                                    placeholder=" "
                                    onChange={(e) => handleRowChange(index, column.key, e.target.value)}
                                    disabled={viewOnly||(salesMode === 'view' ? true : false)}
                                />
                            </td>
                        ))}
                        <td>
                            {!viewOnly && (salesMode != 'view') && (
                                <button className="commonBtn" onClick={() => onDeleteRow(index)}>
                                    <img src={Trash} alt="Delete row" />
                                </button>
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

const SaleSchema = ({ setSalesSchema, rows: allRows, selectedSchem, setSalesMode, salesMode }) => {
    const [data, setData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectData, setSelectData] = useState(null);
    const [rows, setRows] = useState([]);
    const [batchNumber, setBatchNumber] = useState(selectData?.batchNumber || 'All');

    const [successModal, setSuccessModal] = useState('');
    const [SuccessModalMsg, setSuccessModalMsg] = useState('');

    const [errorModal, setErrorModal] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);

    const [allDataSelect, setAllDataSelect] = useState(null);

    const handleErrorModalClose = () => {
        setErrorModal(false);
        setErrorMessages('');
    };

    const handleDeleteSuccessModalClose = () => {
        setSuccessModal(false);
        setSuccessModalMsg('');
        setSalesSchema(false);
    };


    // Initialize rows with empty data
    useEffect(() => {
        const initialRows = Array.from({ length: 5 }).map(() => ({
            quantity: '',
            bonus: '',
            margin: '',
        }));
        setRows(initialRows);
    }, []);

    // Filter and extract product names from rows
    useEffect(() => {
        if (allRows.length > 0) {
            const filteredData = allRows.filter(item => {
                const product = item?.product;

                if (!product || typeof product !== 'object') return false; // Exclude if product is falsy or not an object

                // Check if all values in the product object are empty, null, or undefined
                const isAllValuesEmpty = Object.values(product).every(value => {
                    // For nested objects like lastTransaction, check if all inner values are also empty
                    if (typeof value === 'object' && value !== null) {
                        return Object.values(value).every(innerValue => innerValue === '' || innerValue === null || innerValue === undefined);
                    }
                    return value === '' || value === null || value === undefined;
                });

                return !isAllValuesEmpty; // Keep the item if not all product values are empty
            });

            setData(filteredData);
        }
    }, [allRows]);

    // Extract product names for the Select options
    const productOptions = data.map((item) => {
        const productName = typeof item.product === 'string' ? item.product : item.product.name;
        const productCode = typeof item.product === 'string' ? item.product : item.product._id || item.product_id;
        return { id: productCode, value: productName };
    });

    // Set the first product as default when productOptions are available
    useEffect(() => {
        if (productOptions.length > 0) {
            setSelectedProduct(productOptions[selectedSchem].id); // Set the first product as selected
            const firstProductData = data.find(item => {
                const productCode = typeof item.product === 'string' ? item.product : item.product._id || item.product_id;
                return productCode === productOptions[selectedSchem].id;
            });
            setSelectData(firstProductData);
        }
    }, [data]); //productOptions, data  Add productOptions and data as dependencies

    // Handle select box change
    const handleProductChange = (e) => {
        setSelectedProduct(e.target.value);
        const productCode = e.target.value;
        const filteredData = data.find(item => (item.product._id || item.product_id) === productCode);
        setSelectData(filteredData);
        salesSchemeDetail(e.target.value);
    };

    // Handle changes in the table rows
    const handleRowChange = (index, key, value) => {

        // Create a new copy of the rows to avoid mutating the state directly
        const updatedRows = [...rows];

        // Only update the current key's value
        updatedRows[index][key] = value;


        if (['quantity', 'bonus', 'margin'].includes(key)) {
            const quantity = parseFloat(updatedRows[index].quantity) || 0;
            const bonus = parseFloat(updatedRows[index].bonus) || 0;
            const margin = parseFloat(updatedRows[index].bonus) || 0;
            updatedRows[index].salesScheme = 'newEntry';
        }

        // Update the state with the modified rows
        setRows(updatedRows);
    };

    // Handle batch selection
    const handleBatchChange = (newBatchNumber) => {
        setBatchNumber(newBatchNumber);
    };

    // Handle row deletion
    const onDeleteRow = (indexToDelete) => {
        const updatedRows = [...rows];

        // Reset the values of the row at the specified index
        updatedRows[indexToDelete] = {
            quantity: '',
            bonus: '',
            margin: '',
        };

        // Update the state with the modified rows
        setRows(updatedRows);
    };

    const salesSchemeDetail = async (value) => {
        const accessToken = `Bearer ${sessionStorage.accessToken}`;
        try {
            let response = await axiosClient.post(
                `admin/purchase/bonusscheme/list`,
                JSON.stringify({
                    'productId': value ? value : allRows[selectedSchem]?.product?._id || allRows[selectedSchem]?.product_id,
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-via-device': true,
                        Authorization: accessToken,
                    },
                },
            );
            if (response.status === 200) {
                setAllDataSelect(response?.data?.data);

                // Get the new schemes array
                let newRows = response?.data?.data?.schemes[0]?.saleSchemes || [];

                // Ensure newRows has exactly 5 objects
                if (newRows.length < 5) {
                    newRows = newRows.concat(
                        Array.from({ length: 5 - newRows.length }).map(() => ({
                            quantity: '',
                            bonus: '',
                            margin: '',
                        })),
                    );
                } else if (newRows.length > 5) {
                    newRows = newRows.slice(0, 5);
                }

                setRows(newRows);

            }
        } catch (error) {
            setErrorModal(true);
        }

    };

    useEffect(() => {
        salesSchemeDetail();
    }, []);

    const salesSchemeApi = async () => {
        const accessToken = `Bearer ${sessionStorage.accessToken}`;
        try {
            let response = await axiosClient.post(
                `admin/purchase/bonusscheme/create`,
                JSON.stringify({
                    'productId': selectData?.product?._id,
                    'batchNumber': selectData?.batchNumber,
                    'expiryDate': selectData?.expiryDate,
                    'quantity': selectData?.quantity,
                    'bonus': selectData?.bonus || 0,
                    'margin': selectData?.margin,
                    'purchaseRate': selectData?.buyingPrice,
                    'purchaseBonusScheme': selectData?.quantity + '+' + (selectData?.bonus ? selectData?.bonus : '0'),
                    'saleSchemes': rows
                        .map(item => {
                            // Check if product_id is present
                            if (!item.quantity && !item.bonus && !item.margin) return null;
                            return {
                                ...item,
                            };
                        })
                        .filter(item => item !== null),
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-via-device': true,
                        Authorization: accessToken,
                    },
                },
            );
            if (response.status === 200) {
                setSuccessModalMsg(response?.data?.message);
                setSuccessModal(true);
                //setProductAllName(response?.data?.data?.products);
            }
        } catch (error) {
            setErrorModal(true);
            if (error.response && error.response.data) {

                // Extract validation errors if they exist
                const messages = error.response.data.message || []; // Assuming your errors are in an array
                let newMsgArray = messages.split('.,');
                const cleanedMessages = newMsgArray.map(msg => msg.replace(/^Path\s+/, ''));
                setErrorMessages(cleanedMessages); // Set error messages state
            } else {
                // Fallback for other errors
                setErrorMessages(['An unexpected error occurred.']);
            }
        }

    };


    const onCancelHandle = () => {
        setSalesSchema(false);
    };

    const onSaveHandle = () => {
        salesSchemeApi();
    };

    const onEditSalesDetail = () => {
        setSalesMode(null);
    };


    return (
        <>
            <div className={`saleSchemaOverlay ${salesMode}`}>
                <div className="saleSchemeWrap">
                    <div className="header">
                        <h1>Create Sales Bonus Scheme</h1>


                        {salesMode === 'view' &&
                        <div className="edit_BtnWrap">
                            <button className={'edit_Btn'} onClick={onEditSalesDetail}><ModeEditIcon/> Edit</button>
                        </div>
                        }
                    </div>
                    <div className="body">
                        <div className="details">
                            <div className="rowWrap">
                                <div className="row">
                                    <div className="item">
                                        <Select
                                            id="productName"
                                            label="Product Name"
                                            name="ProductName"
                                            value={selectedProduct}
                                            onChange={handleProductChange}
                                            options={productOptions}
                                            disabled={salesMode === 'view' ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="item">
                                        <label>Batch Number</label>
                                        <span>{selectData?.batchNumber}</span>
                                    </div>
                                    <div className="item">
                                        <label>Expiry Date</label>
                                        {selectData?.expiryDate && dateFormat(selectData?.expiryDate)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="item">
                                        <label>Quantity</label>
                                        <span>{selectData?.quantity}</span>
                                    </div>
                                    <div className="item">
                                        <label>Bonus</label>
                                        <span>{selectData?.bonus || '0'}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="item">
                                        <label>Purchase Rate</label>
                                        <span>{selectData?.buyingPrice}</span>
                                    </div>
                                    <div className="item">
                                        <label>Margin</label>
                                        <span>{selectData?.margin}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="item">
                                        <label>Purchase Bonus Scheme</label>
                                        <input type="text" placeholder="" className="bonusSchemeInput"
                                               value={selectData?.quantity + '+' + (selectData?.bonus ? selectData?.bonus : '0')} disabled={salesMode === 'view' ? true : false}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="createSalesScheme">
                            <h3>Create Sales Scheme</h3>
                            <div className="rowWrap">
                                <div className="row" style={{ width: '60%' }}>
                                    <div className="item">
                                        <label>Current Batch</label>
                                        <span>{selectData?.batchNumber}</span>
                                    </div>
                                    <div className="item">
                                        <Select
                                            id="batchSelect"
                                            label="Batch"
                                            name="Batch"
                                            value={batchNumber}
                                            onChange={(e) => handleBatchChange(e.target.value)}
                                            options={[
                                                { id: 'All', value: 'All' },
                                                { id: selectData?.batchNumber, value: selectData?.batchNumber },
                                            ]}
                                            disabled={salesMode === 'view' ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="tableWrap purchaseWrap">
                                <EditTable rows={rows} handleRowChange={handleRowChange} onDeleteRow={onDeleteRow} salesMode={salesMode}/>
                                <EditTable rows={rows} handleRowChange={handleRowChange} onDeleteRow={onDeleteRow}
                                           viewOnly={true}/>
                            </div>

                            <div className="bonusCal">
                                <h3>Bonus Scheme</h3>
                                {
                                    rows?.map((item, index) => {
                                        // Check if quantity is present
                                        if (!item.quantity) {
                                            return null; // Skip rendering for this item if quantity is not present
                                        }

                                        // Set bonus to 0 if it is not provided
                                        const bonusDisplay = item.bonus ? item.bonus : '0';

                                        return (
                                            <span key={index} style={{ marginRight: '5px' }}>
                                            {item.quantity} + {bonusDisplay}{index < rows.length - 1 ? ', ' : ''}
                                        </span>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="btnGroup">
                            <button type="button" className="cancelBtn" onClick={onCancelHandle}>
                                Cancel
                            </button>
                            {
                                salesMode === 'view' ?
                                    <button type="button" className="nextBtn" disabled={salesMode === 'view' ? true : false}>Save</button>
                                    :
                                    <button type="button" className="nextBtn" onClick={onSaveHandle} >Save</button>
                            }

                        </div>
                    </div>
                </div>
            </div>
            {successModal && (
                <SuccessModal
                    handleSuccessClose={handleDeleteSuccessModalClose}
                    SuccessPopUp={successModal}
                    SuccessTitle={SuccessModalMsg}
                    // SuccessMsg={SuccessModalMsg}
                />
            )}
            {errorModal && (
                <SuccessModal
                    handleSuccessClose={handleErrorModalClose}
                    SuccessPopUp={errorModal}
                    SuccessTitle={'Error'}
                    SuccessMsg={
                        <ul className={'errorMsgList'}>
                            {
                                errorMessages.map((item, index) => {
                                    return (
                                        <li key={index + item}>{item}</li>
                                    );
                                })
                            }
                        </ul>
                    }
                />
            )}
        </>
    );
};

export default SaleSchema;
