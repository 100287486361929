import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import Swal from 'sweetalert2';
import { Input, Select } from '../../components/common';
import { createCategory, updateCategoryDetails } from '../../../services/categoryMaster.service';

const UpdateCategoryForm = ({ editCategory = null, categoryMap, toggleShowUpdateForm, setEditCategory, selectedL1 }) => {
    let isEditMode = false;
    const categoryList = Object.values(categoryMap);
    const addUserEditFormSchema = Yup.object({
        name: Yup.string().required('Name is required.'),
    });

    const addCategoryInitialValues = {
        name: '',
        categoryId: '',
        masterCategory: null,
    };

    if (!isEmpty(editCategory)) {
        isEditMode = true;
        addCategoryInitialValues.name = !!editCategory.name ? editCategory.name : editCategory.value;
        if (editCategory.level === 2) {
            addCategoryInitialValues.masterCategory = editCategory?.parent;
        } else if (editCategory.level === 3) {
            addCategoryInitialValues.masterCategory = selectedL1;
            addCategoryInitialValues.categoryId = editCategory?.parent;
        }
    }

    const { values, handleChange, handleSubmit, errors, touched, resetForm } = useFormik({
        initialValues: addCategoryInitialValues,
        validationSchema: addUserEditFormSchema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: (values, action) => {
            console.log({ values });
            if (isEditMode) {
                updateCategory(values);
            } else {
                registerCategory(values);
            }
            setTimeout(() => {
                toggleShowUpdateForm();
                action.resetForm();
                setEditCategory(null);
            }, 500);
        },
    });

    const registerCategory = async values => {
        createCategory(values)
            .then(response => {
                if (response && response.status == true) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Category created successfully!',
                        icon: 'success',
                    });
                } else if (response && response.status == false) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `Error creating Category! \n Error Message: ${response.message}`,
                    });
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Error creating Category! \n Error Message: ${error}`,
                });
            });
    };

    const updateCategory = async values => {
        values.id = editCategory?._id || editCategory?.id;
        updateCategoryDetails(values)
            .then(response => {
                if (response && response.status == true) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Category updated successfully!',
                        icon: 'success',
                    });
                } else if (response && response.status == false) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `Error updating Category! \n Error Message: ${response.message}`,
                    });
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Error updating Category! \n Error Message: ${error}`,
                });
            });
    };

    return (
        <div className='categoryFormContainer'>
            <div className='formHeader'>
                <h2 className='title'>{isEditMode ? 'Edit' : 'Add New'} Category</h2>
                <h3 className='subTitle'>
                    {editCategory?.level === 1
                        ? "The name of Category Level 1 will be updated after clicking the 'Save' button."
                        : editCategory?.level === 2
                        ? "The name of Category Level 2 will be updated, and it can be moved to another category after clicking the 'Save' button."
                        : editCategory?.level === 3
                        ? "The name of Category Level 3 will be updated, and it can be moved to another categories after clicking the 'Save' button."
                        : 'To move this category to different levels, use the dropdowns to select or remove parent categories.'}
                </h3>
            </div>
            <form className='form_container' onSubmit={handleSubmit}>
                {isEditMode && editCategory.level === 1 ? null : (
                    <Select
                        label={'Category Level 1'}
                        name={'masterCategory'}
                        options={categoryList}
                        wrapperClass={'col4'}
                        value={values.masterCategory}
                        onChange={handleChange}
                    />
                )}

                {isEditMode && (editCategory.level === 1 || editCategory.level === 2) ? null : (
                    <Select
                        label={'Category Level 2'}
                        name={'categoryId'}
                        options={
                            isEditMode
                                ? categoryMap[values.masterCategory || selectedL1]?.childCategory
                                : categoryMap[values.masterCategory]?.childCategory
                        }
                        wrapperClass={'col4'}
                        value={values.categoryId}
                        onChange={handleChange}
                    />
                )}
                <Input
                    label={'Name'}
                    type={'text'}
                    name={'name'}
                    id={'name'}
                    isRequired
                    wrapperClass={'col4'}
                    value={values.name}
                    onChange={handleChange}
                    error={errors.name}
                    touched={touched.name}
                />
                {!isEditMode && (
                    <ul className='addFormGuide'>
                        <li>
                            <span>Main Category (Level 1)</span>: Enter a name without selecting from the dropdowns to create a Main Category.
                        </li>
                        <li>
                            <span>Category Level 2</span>: Select a category from "Category Level 1," then enter a name to create a subcategory under
                            Level 1.
                        </li>
                        <li>
                            <span>Category Level 3</span>: Select both "Category Level 1" and "Category Level 2," then enter a name to create a
                            sub-subcategory under Level 2.
                        </li>
                    </ul>
                )}
                <div className='bottom_actions'>
                    <button
                        className='cancelBtn'
                        onClick={() => {
                            setEditCategory(null);
                            toggleShowUpdateForm();
                            resetForm();
                        }}
                    >
                        Cancel
                    </button>
                    <button className={'saveBtn'}>Save</button>
                </div>
            </form>
        </div>
    );
};

export default UpdateCategoryForm;
