import React, { useState, useEffect } from 'react';

import Checkbox from '@mui/material/Checkbox';
import CloseX from '../../../../assets/images/x-cross.svg';

const ManageColumn = (props) => {
    const [columnData, setColumnData] = useState(props.columnData);
    const [filteredData, setFilteredData] = useState(props.columnData);
    const [searchText, setSearchText] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(true);
    }, []);

    // Handle checkbox change
    const handleChange = (id) => (event) => {
        const updatedColumnData = columnData.map((item) =>
            item.id === id ? { ...item, visible: event.target.checked } : item
        );

        // Check if there's only one item left as checked
        const visibleCount = updatedColumnData.filter((item) => item.visible).length;
        if (visibleCount >= 1) {
            setColumnData(updatedColumnData);
            const updatedFilteredData = filteredData.map((item) =>
                item.id === id ? { ...item, visible: event.target.checked } : item
            );
            setFilteredData(updatedFilteredData);
        }
    };

    // Handle search input change
    const handleSearch = (event) => {
        const text = event.target.value;
        setSearchText(text);

        const filtered = columnData.filter((item) =>
            item.name.toLowerCase().includes(text.toLowerCase())
        );
        setFilteredData(filtered);
    };

    const onCloseHandle = () => {
        setIsOpen(false);
        setTimeout(() => props.setDrawerOpen(false), 500);
    };

    const onSubmit = () => {
        props.setColumnData(columnData);
        setIsOpen(false);
        setTimeout(() => props.setDrawerOpen(false), 500);
    };

    // Check if only one item is checked to disable the checkbox
    const visibleCount = columnData.filter((item) => item.visible).length;

    return (
        <div className={`manageColumn ${isOpen ? 'open' : ''}`}>
            <div className="head">
                <h2>Choose Column</h2>
                <img
                    src={CloseX}
                    alt="close_icon"
                    className="close"
                    onClick={onCloseHandle}
                />
            </div>
            <div className="bodyColumn">
                <div className="search">
                    <input
                        type="text"
                        placeholder="Find a field"
                        value={searchText}
                        onChange={handleSearch}
                    />
                </div>
                <div className="bodyInner">
                    {filteredData.map((item) => (
                        <div className="item" key={item.id}>
                            <div>
                                <Checkbox
                                    checked={item.visible}
                                    onChange={handleChange(item.id)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    disabled={item.visible && visibleCount === 1} // Disable if only one item is left checked
                                />
                                <label>{item.name}</label>
                            </div>
                            <div className="moveBtn">=</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="footerColumn">
                <div className="btnGroup">
                    <button type="button" className="cancelBtn" onClick={onCloseHandle}>
                        Cancel
                    </button>
                    <button type="button" className="nextBtn" onClick={onSubmit}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ManageColumn;
