import Swal from 'sweetalert2';
import { loginService } from '../../../services/registration.service';
import { updateLoginDetails } from '../../../redux/slices/LoginServiceSlices';

export const passwordDeleteModal = (dispatch, deleteService, fetchListData) => {
    const { email } = JSON.parse(sessionStorage.getItem('loginData')).user;

    Swal.fire({
        title: 'Are you sure you want to delete?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
    }).then(result => {
        if (result.isConfirmed) {
            Swal.fire({
                title: 'Please enter Your Password to proceed',
                input: 'password',
                inputPlaceholder: 'Enter your password',
                showCancelButton: true,
                confirmButtonText: 'Proceed',
                showLoaderOnConfirm: true,
                preConfirm: password => {
                    if (!password) {
                        Swal.showValidationMessage('Password is required');
                        return false;
                    }
                    return password;
                },
            }).then(result => {
                if (result.isConfirmed) {
                    loginService({ email, password: result.value })
                        .then(response => {
                            if (response?.status === true) {
                                sessionStorage.setItem('accessToken', response.data.token);
                                sessionStorage.setItem('loginData', JSON.stringify(response.data));
                                dispatch(updateLoginDetails(response.data));
                                deleteService().then(response => {
                                    if (response?.status === true) {
                                        Swal.fire({
                                            title: 'Success',
                                            text: 'Category deleted successfully!',
                                            icon: 'success',
                                        });
                                        fetchListData();
                                    } else if (response?.status === false) {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: response?.data?.message,
                                        });
                                    }
                                });
                            }
                        })
                        .catch((err,...res) => {
                            console.log({res})
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: err,
                            });
                        });
                }
            });
        }
    });
};
