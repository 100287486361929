import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { Input } from '../../components/common';
import { loginValidationSchema } from '../SignUp/signupValidationSchema';
import ResetPasswordModal from '../../components/Modals/ResetPasswordModal';
import { loginService } from '../../../services/registration.service';
import { updateLoginDetails } from '../../../redux/slices/LoginServiceSlices';
import locales from '../../constants/en.json';
import FogoLogo from '../../../assets/images/logo_fogo.svg';
import './SignIn.scss';

function SignIn(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [showResetPassModal, setShowResetPassModal] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);

    const onPasswordShow = () => {
        setPasswordShow(state => !state);
    };

    const { values, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik({
        initialValues: { email: '', password: '' },
        validationSchema: loginValidationSchema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: (values, action) => {
            handleSignIn(values);
            action.resetForm();
        },
    });

    const handleSignIn = (values) => {
        try {
            loginService(values).then(response => {
                if (response?.status == true) {
                    sessionStorage.setItem('accessToken', response.data.token);
                    sessionStorage.setItem('loginData', JSON.stringify(response.data));
                    dispatch(updateLoginDetails(response.data));
                    navigate('/dashboard');
                } else if (response?.status == false) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response?.data?.message,
                    });
                }
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Something went wrong',
                text: error,
            });
        }
    };

    useEffect(() => {
        document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <React.Fragment>
            <div className='signInWrapper'>
                <div className='header'>
                    <div className='left_logoBlock' onClick={() => navigate('/')}>
                        <img src={FogoLogo} alt='fogo_logo'></img>
                    </div>
                    <div className='right_action' id='right_action'>
                        <span className='dont_account'>{locales.dont_have_account}</span>
                        <button className='signup_btn' onClick={() => navigate('/SignUp')}>
                            {locales.signUp}
                        </button>
                    </div>
                </div>
                <div className={`mainContainer ${!showResetPassModal && 'signInModal_show'}`}>
                    {!showResetPassModal && (
                        <div className='signIn_formBlock'>
                            <h2 className='title'>{'Sign in to your account'}</h2>
                            <div className='form_container'>
                                <Input
                                    label={'Email'}
                                    type={'text'}
                                    name={'email'}
                                    id={'email'}
                                    wrapperClass={'col12'}
                                    value={values.email}
                                    onChange={handleChange}
                                    isRequired
                                    error={errors.email}
                                    touched={touched.email}
                                />
                                <Input
                                    label={'Password'}
                                    type={passwordShow ? 'text' : 'password'}
                                    name={'password'}
                                    id={'password'}
                                    isRequired
                                    value={values.password}
                                    className={'eyeWrap'}
                                    wrapperClass={'col12'}
                                    onChange={handleChange}
                                    hasEye
                                    onEyeClick={onPasswordShow}
                                    showEye={passwordShow}
                                    touched={touched.password}
                                    error={errors.password}
                                />
                                <div className='submit_action'>
                                    <button className='submitBtn' onClick={handleSubmit}>
                                        {locales.signIn}
                                    </button>
                                </div>
                                <div className='forgot_pass_action'>
                                    <span
                                        className='link'
                                        onClick={() => {
                                            setShowResetPassModal(true);
                                            setOpen(true);
                                        }}
                                    >
                                        {locales.forgot_password}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showResetPassModal && <ResetPasswordModal openState={open} handlecloseState={() => setShowResetPassModal(false)} />}
        </React.Fragment>
    );
}

export default SignIn;
