import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Input, Select } from '../../../components/common';
import { axiosClient } from '../../../../services/axiosClient';
import SuccessModal from '../../../components/common/CommonSuccessModal/SuccessModal';
import CommonTable from '../../../components/common/CommonTable/CommonTable';
import DeleteModal from '../../../components/common/CommonDeleteModal/CommonDeleteModal';
import './AddNewLedger.scss';


const bankDetailEditFormSchema = Yup.object({
    bankName: Yup.string().required('Bank Name is required.'),
    bankAccountNumber: Yup.string().required('Bank Account No is required.'),
    bankAccountHolderName: Yup.string().required('Bank Account Holder Name is required.'),
    bankBranchName:Yup.string().required('Bank Branch Name is required.'),
    bankIfscCode:Yup.string().required('Bank IFSC code is required.'),
});

const bankDetailInitialValues = {
    bankName: '',
    bankBranchName: '',
    bankAccountGroup: 'Saving',
    bankSwiftCode: '',
    bankCountry: 'India',
    bankIfscCode: '',
    bankIbanNumber: '',
    bankMicrCode: '',
    bankAccountNumber: '',
    bankAccountHolderName: '',
    bankBranch: 'Branch A',
    isDefult:'false',
    _id:''
};

function BankDetails({ changeTab, ProductCreateList, ledgerDetailData, preview, previewData, back, ledgerAllData, draftPopUpClose }) {
    const [draftClicked, setDraftClicked] = useState(false);
    const [draftButtonClick, setDraftButtonClick] = useState(false);
    const [draftsuccessModal, setDraftSuccessModal] = useState(false);
    const [SuccessMsg, setSuccessMsg] = useState('');
    const [SuccessTitle, setSuccessTitle] = useState('');
    const [showLoader, setshowLoader] = useState(false);
    const [currentTabvalue, setCurrentTabvalue] = useState(0);

    const [deleteBankData, setDeleteBankData] = useState('');
    const [DeleteModalTitle, setDeleteModalTitle] = useState('');
    const [DeleteModalMsg, setDeleteModalMsg] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [bankListData, setBankListData] = useState([]);
    const [editData, setEditData] = useState('');
    const [successModal, setSuccessModal] = useState('');
    const [SuccessModalMsg, setSuccessModalMsg] = useState('');
    const [isCheckFormClick, setIsCheckFormClick] = useState(false);
    const tableHeader = [ 'Bank Name',
        'Bank Branch Name',
        'Account Group',
         'Bank Swift Code',
        'Country',
        'IFSC Code',
        'Bank Account Number'];
    const tableFilterHeader = [ 'bankName',
        'bankBranchName',
        'bankAccountGroup',
        'bankSwiftCode',
        'bankCountry',
        'bankIfscCode',
        'bankAccountNumber'];
    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik({
        initialValues: bankDetailInitialValues,
        validationSchema: bankDetailEditFormSchema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: (values, action) => {
            if(isCheckFormClick){

            }else{
            handleFormSubmit();
            }
            // action.resetForm();
        },
    });

//  const handleDeleteTempIds =(value)=>{
//        value.forEach(item => {
//             if(item?._id?.toString().length<=3){
//                 delete item._id;
//                 delete item.bankId      
//                 }
//          });
//          return value;
//  }

    const handleFormSubmit = async  => {
        const value = [...[values],...bankListData];
        if (draftClicked) {
            handleDraft(value);
        } else {   
        changeTab(2);
        ledgerDetailData(value);
        }
    };

    useEffect(()=>{
        let bankDetails = previewData.bankDetails || ledgerAllData?.bankDetails;
        if(bankDetails?.length>0)
        setBankListData((bankListData)=>[...bankDetails]); 
    },[])

    const handleTabBack=()=>{
        changeTab(0);
    }

   const checkForDraftSubmit =()=>{
    if(JSON.stringify(bankDetailInitialValues)===JSON.stringify(values) && bankListData.length>0){
        ledgerAllData.isDraft= true;
        delete ledgerAllData.bankDetails;
        delete ledgerAllData?.ledgerCode;
        ledgerAllData.bankDetails = [...bankListData];
         if (draftButtonClick == false) {
           setDraftButtonClick(true);
           ledgerAllData?.bankDetails?.map((item) => {
            if(item?._id?.toString().length<=3){
                delete item._id;
                delete item.bankId      
                }
         });
         ledgerAllData?.document?.map(item => {
                if (item?._id?.toString().length <= 3) {
                    delete item._id;
                    delete item.documentId;
                }
            });
            saveDraft(ledgerAllData);
         }
    }
   else{
        handleSubmit();
   }
    }

    const handleNext=()=>{
             let value =[];
             if(values.bankName && values.bankBranchName && values.bankIfscCode && values.bankAccountNumber && values.bankAccountHolderName){
                value = [...[values],...bankListData];
             }else{
                value = [...bankListData];
             }
            //let value = handleDeleteTempIds
            ledgerDetailData(value);
            changeTab(2);
    }

    const handleDraft = data => {
        ledgerAllData.isDraft= true;
        delete ledgerAllData.bankDetails;
        delete ledgerAllData?.ledgerCode;
        ledgerAllData.bankDetails = [...[values],...bankListData];
         if (draftButtonClick == false) {
           setDraftButtonClick(true);
           ledgerAllData?.bankDetails?.map((item) => {
            if(item?._id?.toString().length<=3){
                delete item._id;
                delete item.bankId      
                }
         });
         ledgerAllData?.document?.map(item => {
                if (item?._id?.toString().length <= 3) {
                    delete item._id;
                    delete item.documentId;
                }
            });
            saveDraft(ledgerAllData);
         }
    };

    const saveDraft = async event => {
        const accessToken = `Bearer ${sessionStorage.accessToken} `;
        try {
            let response = await axiosClient.post(`admin/ledger/create`, event, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-via-device': true,
                    Authorization: accessToken,
                },
            });

            if (response.status == 200) {
                 setDraftSuccessModal(true);
                setSuccessTitle('Draft Saved Successfully');
               setDraftButtonClick(false);
                 setDraftClicked(false);
            }
        } catch (error) {
            setDraftClicked(false);
            setDraftButtonClick(false);
            console.log('error', error);
        }
    };

    const handleDraftSuccessPopupClose = () => {
        draftPopUpClose();
    };

    const handleBankDelete = e => {
        //setDeleteBankData(e);
        // setDeleteModal(true);
        // setDeleteModalTitle('Confirm Product Deletion');
        // setDeleteModalMsg('Are you sure you want to delete this product? This action cannot be undone');
        handleDeleteData(e);
    };


    const handleEditDataPopulate = id => {
        let filter;
        filter = bankListData.filter(item => {
            if (item._id == id) {
                return item;
            }
        });
        Object.entries(filter[0]).forEach(
            ([key, value]) => setFieldValue(key, value)
        );
    };


    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handleDeleteSuccessModalClose = () => {
        setSuccessModal(false);
        setSuccessModalMsg('');
        setEditData([]);
    };

    const handleDeleteData = (id) => {
        let filter;
        filter = bankListData.filter(item => {
            if (item._id !== id) {
                return item;
            }
        });
        setBankListData((bankListData)=>[...filter]);
    };

    const handleMakeDeafultClick=(id)=>{
        let filter;
        filter = bankListData.filter(item => {
            if (item._id === id) {
                return item;
            }
        });
        filter[0].isDefult = true;
        setBankListData((bankListData)=>[...bankListData]);
    }

    const handleReset =()=>{
      handleResetForm();
    }

    const handleResetForm=()=>{
        Object.entries(bankDetailInitialValues).forEach(
            ([key, value]) => setFieldValue(key, value)
        );
    }

    const handleSave =()=>{
        setIsCheckFormClick(true);
        handleSubmit();
        if(Object.keys(errors).length===0 && (values.bankName && values.bankAccountNumber && values.bankBranch && values.bankIfscCode && values.bankAccountHolderName)){
        if(values._id){
            let filter;
            filter = bankListData.filter(item => {
                if (item._id !== values._id) {
                    return item;
                }
            });
            if(filter.length>0){
            setBankListData((bankListData)=>[...filter,...[values]]);
            }else{
                setBankListData((bankListData)=>[...[values]]); 
            }
        }else{
            values._id = bankListData.length+1; 
            setBankListData((bankListData)=>[...bankListData,...[values]]);
        }
       
        handleResetForm();
    }
        
    }
    return (
        <React.Fragment>
            <div className={`Detail_container  ${preview ? 'preview_active' : ''}`}>
                {preview ? null :<div className='first_flexbox'>
                    <div className='inputBox Product_Name sm-30 lg-30'>
                        <Input
                            label={'Bank Name'}
                            type={'text'}
                            name={'bankName'}
                            id={'bankName'}
                            value={values.bankName}
                            wrapperClass={'col12'}
                            onChange={handleChange}
                            error={errors.bankName}
                            touched={touched.bankName}
                            isRequired
                            ReadOnly={preview ? true : false}

                        />
                    </div>
                    <div className='inputBox Product_Name sm-30 lg-30'>
                        <Input
                            label={'Bank Branch Name'}
                            type={'text'}
                            name={'bankBranchName'}
                            id={'bankBranchName'}
                            value={values.bankBranchName}
                            wrapperClass={'col12'}
                            isRequired
                            onChange={handleChange}
                            error={errors.bankBranchName}
                            touched={touched.bankBranchName}
                            ReadOnly={preview ? true : false}

                        />
                    </div>
                    <div className='inputBox sm-30 lg-30'>
                        <Select
                            label={'Account Group'}
                            name={'bankAccountGroup'}
                            options={[
                                { id: 'Current', value: 'Current' },
                                { id: 'Saving', value: 'Saving' },
                            ]}

                            wrapperClass={'col12'}
                            value={values.bankAccountGroup}
                            onChange={handleChange}
                            error={errors.bankAccountGroup}
                            touched={touched.bankAccountGroup}
                            disabled={preview ? true : false}
                        />
                    </div>
                </div>}

                {preview ? null : <div className='first_flexbox'>
                    <div className='inputBox Product_Name sm-25 lg-25'>
                        <Input
                            label={'Bank Swift Code'}
                            type={'text'}
                            name={'bankSwiftCode'}
                            id={'bankSwiftCode'}
                            value={values.bankSwiftCode}
                            wrapperClass={'col12'}
                            onChange={handleChange}
                            error={errors.bankSwiftCode}
                            touched={touched.bankSwiftCode}
                            ReadOnly={preview ? true : false}

                        />
                    </div>

                    <div className='inputBox sm-25 lg-25'>
                        <Select
                            label={'Country'}
                            name={'bankCountry'}
                            options={[
                                { id: 'India', value: 'India' },
                                { id: 'United States', value: 'United States' },
                            ]}

                            wrapperClass={'col12'}
                            value={values.bankCountry}
                            onChange={handleChange}
                            error={errors.bankCountry}
                            touched={touched.bankCountry}
                            disabled={preview ? true : false}
                        />
                    </div>
                    <div className='inputBox Product_Name sm-25 lg-25'>
                        <Input
                            label={'IFSC Code'}
                            type={'text'}
                            name={'bankIfscCode'}
                            id={'bankIfscCode'}
                            value={values.bankIfscCode}
                            wrapperClass={'col12'}
                            onChange={handleChange}
                            error={errors.bankIfscCode}
                            touched={touched.bankIfscCode}
                            ReadOnly={preview ? true : false}
                            isRequired

                        />
                    </div>
                    <div className='inputBox Product_Name sm-25 lg-25'>
                        <Input
                            label={'MICR code'}
                            type={'text'}
                            name={'bankMicrCode'}
                            id={'bankMicrCode'}
                            value={values.bankMicrCode}
                            wrapperClass={'col12'}
                            onChange={handleChange}
                            error={errors.bankMicrCode}
                            touched={touched.bankMicrCode}
                            ReadOnly={preview ? true : false}

                        />
                    </div>
                </div>}


                {preview ? null : <div className='first_flexbox'>
                    <div className='inputBox Product_Name sm-30 lg-30'>
                        <Input
                            label={'Bank Account No'}
                            type={'text'}
                            name={'bankAccountNumber'}
                            id={'bankAccountNumber'}
                            value={values.bankAccountNumber}
                            wrapperClass={'col12'}
                            onChange={handleChange}
                            error={errors.bankAccountNumber}
                            isRequired
                            touched={touched.bankAccountNumber}
                            ReadOnly={preview ? true : false}

                        />
                    </div>
                    <div className='inputBox Product_Name sm-30 lg-30'>
                        <Input
                            label={'Account Holder Name'}
                            type={'text'}
                            name={'bankAccountHolderName'}
                            id={'bankAccountHolderName'}
                            value={values.bankAccountHolderName}
                            wrapperClass={'col12'}
                            onChange={handleChange}
                            isRequired
                            error={errors.bankAccountHolderName}
                            touched={touched.bankAccountHolderName}
                            ReadOnly={preview ? true : false}

                        />
                    </div>
                    <div className='inputBox sm-30 lg-30'>
                        <Select
                            label={'Branch'}
                            name={'bankBranch'}
                            options={[
                                { id: 'Branch A', value: 'Branch A' },
                                { id: 'Branch B', value: 'Branch B' },
                            ]}

                            wrapperClass={'col12'}
                            value={values.bankBranch}
                            onChange={handleChange}
                            error={errors.bankBranch}
                            touched={touched.bankBranch}
                            disabled={preview ? true : false}
                        />
                    </div>
                </div>}

                {preview ? null :<div className='actionTableFlexbox'>
                            <div className='rightCol'>
                                <button type='button' className='canceltBtn' onClick={ handleReset}>
                                    Reset
                                </button>
                                <button type='button' className='nextBtn' onClick={handleSave}>
                                    Add New Bank
                                </button>
                            </div>
                </div>}
                <div className='table_container bankDetailsTable' style={{ overflowX: 'auto'}}>
                    <CommonTable
                        deleteProductData={e => handleBankDelete(e)}
                        dataEditPopulate={e => handleEditDataPopulate(e)}
                        header={tableHeader}
                        tableBodyData={bankListData}
                        tableFilterHeader={tableFilterHeader}
                        makeDefault={true}
                        preview={preview}
                        handleMakeDeafultClick={handleMakeDeafultClick}
                        showEditAction={true}
                        showDeleteAction={true}
                    />
                </div>

                <div className='actionFlexbox'>
                    {preview ? null : (
                        <React.Fragment>
                             {!Object.keys(previewData).length>0 && (
                            <button
                                type='button'
                                className='draftBtn'
                                onClick={() => {
                                    setIsCheckFormClick(false);
                                    setDraftClicked(true);
                                    checkForDraftSubmit();
                                }}
                            >
                                Save Draft
                            </button>
                            )}
                            <div className='rightCol'>
                                <button type='button' className='canceltBtn' onClick={handleTabBack}>
                                    Back
                                </button>
                                <button type='button' className='nextBtn' onClick={()=>{setIsCheckFormClick(false);
                                    bankListData.length>0?handleNext():handleSubmit()}}>
                                    Next
                                </button>
                            </div>{' '}
                        </React.Fragment>
                    )}
                </div>
            </div>
            {draftsuccessModal && (
                <SuccessModal
                    handleSuccessClose={handleDraftSuccessPopupClose}
                    SuccessPopUp={draftsuccessModal}
                    SuccessMsg={SuccessMsg}
                    SuccessTitle={SuccessTitle}
                />
            )}
            {successModal && (
                <SuccessModal
                    handleSuccessClose={handleDeleteSuccessModalClose}
                    SuccessPopUp={successModal}
                    SuccessTitle={SuccessModalMsg}
                // SuccessMsg={SuccessModalMsg}
                />
            )}
            {deleteModal && (
                <DeleteModal
                    handleDeleteClose={handleDeleteModalClose}
                    DeletePopUp={deleteModal}
                    DeleteModalTitle={DeleteModalTitle}
                    DeleteModalMsg={DeleteModalMsg}
                    handleDeleteConfirm={handleDeleteData}
                />
            )}
        </React.Fragment>

    );
}

export default BankDetails;
