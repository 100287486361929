import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import { leftNavConfig } from './leftNavConfig';
import Zoomview from '../../../assets/images/zoomview.svg';
import ExpandView from '../../../assets/images/expand_view.svg';
import { ReactComponent as DownArrow } from '../../../assets/images/chevron-down.svg';
import { ReactComponent as UpArrow } from '../../../assets/images/chevron-up.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/images/logout.svg';
import fogoLogo from '../../../assets/images/fogo_logo.svg';
import CompactLogo from '../../../assets/images/compact_logo.svg';
import './AdminLeftNav.scss';

function AdminLeftNav({ toggleCollapsedNav, handleSelectedMenu, handleUpdateBreadCrumb, handleLogout, collapsedNav }) {
    const navigate = useNavigate();
    const [menuSelected, setMenuSelected] = useState('/dashboard');
    const [subMenuSeleted, setSubMenuSeleted] = useState('');
    const onNavItemSelection = (item, isSubMenu) => {
        if (isSubMenu) {
            setSubMenuSeleted(item.path);
            navigate(item.path);
        } else if (item.path) {
            setMenuSelected(item.path);
            navigate(item.path);
            setSubMenuSeleted('');
        } else {
            setMenuSelected(item.label);
            if (item.label === menuSelected) {
                setMenuSelected('');
            }
        }
    };

    return (
        <div className={classNames('leftNavContainer', { collapsedNav: collapsedNav })}>
            <div className='leftNavList'>
                <div className='header_logoBlock'>
                    {collapsedNav ? (
                        <img src={CompactLogo} className='compact_logo' alt='compact_logo'></img>
                    ) : (
                        <img src={fogoLogo} className='fogo_logo' alt='fogo_logo'></img>
                    )}
                    <button className='toggle_btn' onClick={toggleCollapsedNav}>
                        {collapsedNav ? (
                            <img src={ExpandView} className='collapsedNav' alt='collapsedNav'></img>
                        ) : (
                            <img src={Zoomview} className='collapse_view' alt='collapse_icon'></img>
                        )}
                    </button>
                </div>
                <div className={classNames('menu_itemContainer', { collapsedNav: collapsedNav })}>
                    <ul>
                        {leftNavConfig.map((item, index) => (
                            <li key={index}>
                                <button
                                    className={classNames('menu_link', { active: item.path && item.path === menuSelected })}
                                    onClick={() => onNavItemSelection(item)}
                                >
                                    <Tooltip className='tooltip' title={`${item.label}`}></Tooltip>
                                    <div className='icon'>{item.icon}</div> <span className='text'>{item.label}</span>
                                    {item?.subMenu && <span className='caret_icon'>{item.label === menuSelected ? <UpArrow /> : <DownArrow />}</span>}
                                </button>
                                {item.label === menuSelected && (
                                    <ul className='subMenu'>
                                        {item?.subMenu?.map(subItem => {
                                            return (
                                                <li>
                                                    <button
                                                        className={classNames('menu_link', subItem.label, {
                                                            active: subItem.path && subItem.path == subMenuSeleted,
                                                        })}
                                                        onClick={() => onNavItemSelection(subItem, true)}
                                                    >
                                                        {subItem.label}
                                                    </button>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className='logoutBtn' onClick={handleLogout}>
                <button>
                    <div className='icon'>
                        <LogoutIcon />
                    </div>
                    <span className='text'>Log out</span>
                </button>
            </div>
        </div>
    );
}

export default AdminLeftNav;
