import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import CreatAccountPage from '../../components/SignUpRegistration/CreateAccountPage';
import EmailVerifyPage from '../../components/SignUpRegistration/EmailVerificationPage';
import RegistrationPage from '../../components/SignUpRegistration/RegistrationPage';
import SubscriptionPlanPage from '../../components/SignUpRegistration/SubscriptionPlanPage';
import PaymentMethodPage from '../../components/SignUpRegistration/PaymentMethod';
import { getRequiredListingsService } from '../../../services/registration.service';
import { updateRequiredListings } from '../../../redux/slices/RequiredListingsSlices';
import locales from '../../constants/en.json';
import FogoLogo from '../../../assets/images/logo_fogo.svg';
import './SignUp.scss';

function SignUp(props) {
    const dispatch = useDispatch();
    const { country = null, industry = null } = useSelector(state => state?.requiredListings?.listings);
    const countryDataList =
        country &&
        country.map(country => {
            return { id: country._id, value: country.phone };
        });
    const industryDataList =
        industry &&
        industry.map(industry => {
            return { id: industry._id, value: industry.name };
        });
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState('About yourself');
    const stepperItem = [
        {
            label: 'About yourself',
            element: <CreatAccountPage countryDataList={countryDataList} onNextClick={() => setActiveStep('Verification')} />,
        },
        {
            label: 'Verification',
            element: <EmailVerifyPage countryDataList={countryDataList} onNextClick={() => setActiveStep('Registration')} />,
        },
        {
            label: 'Registration',
            element: (
                <RegistrationPage
                    countryDataList={countryDataList}
                    industryDataList={industryDataList}
                    onNextClick={() => setActiveStep('Subscribtion')}
                />
            ),
        },
        {
            label: 'Subscribtion',
            element: <SubscriptionPlanPage countryDataList={countryDataList} onNextClick={() => setActiveStep('Payment')} />,
        },
        {
            label: 'Payment',
            element: <PaymentMethodPage />,
        },
    ];

    let Element = null;
    stepperItem.forEach(item => {
        if (item.label === activeStep) {
            Element = item.element;
        }
    });

    useEffect(() => {
        document.scrollingElement.scrollTop = 0;
        if (isEmpty(country)) {
            (async function () {
                const response = await getRequiredListingsService();
                dispatch(updateRequiredListings(response.data));
            })();
        }
    }, []);

    return (
        <React.Fragment>
            <div className='signUp_wrapper'>
                <div className='header'>
                    <div
                        className='left_logoBlock'
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        <img src={FogoLogo} alt='fogo_logo'></img>
                    </div>
                    <div className='right_action' id='right_action'>
                        <span className='dont_account'>{locales.already_have_account}</span>
                        <button
                            className='signup_btn'
                            onClick={() => {
                                navigate('/login');
                            }}
                        >
                            {locales.signIn}
                        </button>
                    </div>
                </div>
                <div className='mainContainer'>
                    <div className={`content_wrapper `}>
                        <div className='stepper_container'>
                            <ul>
                                {stepperItem.map((item, index) => {
                                    return (
                                        <li key={index} className={`${activeStep === item.label ? `active` : ''}`}>
                                            <span className='index'>{index + 1}</span>
                                            <span className='text'>{item.label}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        {Element}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SignUp;
