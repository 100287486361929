import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import LedgerDetails from './LedgerDetails';
import BankDetails from './BankDetails';
import ExciseDetails from './ExciseDetails';
import Document from './Document';
import Notes from './Notes';
import { ReactComponent as ArrowLeft } from '../../../../assets/images/arrow-left.svg';
import { ReactComponent as Edit } from '../../../../assets/images/pencil.svg';
import './AddNewLedger.scss';

function AddNewLedger({
    back,
    preview,
    previewData,
    removePreviewMode,
    successModalClose,
    EditData,
    draftSuccessPopUpClose,
    categoriesAllData,
    productCreateListData,
    editMode,
}) {
    const [value, setValue] = useState(0);
    const [mainTab, setMainTab] = useState(0);
    const [productCreateList, setProductCreateList] = useState(productCreateListData);
    const [ledgerDetailCollection, setLedgerDetailCollection] = useState('');
    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    const handleTabChange = (event, newValue) => {
        setMainTab(newValue);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

   const handleTabsData=(value)=>{
    const newData ={...ledgerDetailCollection,...value};
    setLedgerDetailCollection(newData);
   }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleDraftPopUpClose = () => {
        draftSuccessPopUpClose();
    };

    const handleTabs = event => {
        handleTabChange(true, event);
    };

    const handleLedgerDetailData = event => {
            switch (mainTab) {
            case 0:
                handleTabsData(event);
                break;
                case 1:
                 let bankDetails = event;   
                 handleTabsData({bankDetails})
                break;
                case 2:
                    handleTabsData(event);
                break;
                case 3:
                    let document = event;
                    handleTabsData({document});
                break;
                case 4:
                    handleTabsData(event);
                    break;
        
            default:
                break;
        }
    };



    const AddLedgerTabs = ['Ledger Details', 'Bank Details', 'Excise Details', 'Document', 'Notes'];

    return (
        <div className={`addNewLedger_container ${editMode ? "editLedger" : ""}`}>
            <div className='backLink'>
                <span className='link' onClick={back}>
                    <span className='icon'>
                        <ArrowLeft />
                    </span>
                    Back to Ledger
                </span>
            </div>
            <div className={`container_section ${preview ? 'preview' : ''}`}>
                <div className='headerFlexbox'>
                    <div className='left-part'>
                    <h1 className='section_title'>{`${preview ? 'Ledger Details' : EditData ? 'Edit Ledger' : 'Create New Ledger'} `}</h1>
                    <h5 className='subtitle'>Customer & Supplier</h5>
                    </div>
                    <div className='right-part'>
                    {preview ? (
                            <button className='editBtn' onClick={() => removePreviewMode()}>
                                <span className='icon'>
                                    <Edit />
                                </span>
                                Edit
                            </button>
                        ) : (
                            ''
                        )}
                    </div>
                    </div>
               
                <Box className='tabsContainer' sx={{ width: '100%' }}>
                    <Box className='tabFlexContainer'>
                        <Tabs value={mainTab} onChange={preview ? handleTabChange : ""} aria-label='basic tabs example'>
                            {AddLedgerTabs &&
                                AddLedgerTabs.length > 0 &&
                                AddLedgerTabs.map((label, index) => {
                                    return <Tab label={label} {...a11yProps(index)} />;
                                })}
                        </Tabs>
                       
                    </Box>

                    <CustomTabPanel value={mainTab} index={0} className='tabContentContainer'>
                        <LedgerDetails
                             preview={preview}
                             previewData={previewData}
                            ledgerDetailData={e => handleLedgerDetailData(e)}
                            ProductCreateList={productCreateList}
                            changeTab={e => handleTabs(e)}
                            back={back}
                            ledgerAllData={ledgerDetailCollection}
                            draftPopUpClose={() => handleDraftPopUpClose()}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={mainTab} index={1} className='tabContentContainer'>
                        <BankDetails
                         preview={preview}
                         previewData={previewData}
                        ledgerDetailData={e => handleLedgerDetailData(e)}
                        ProductCreateList={productCreateList}
                        changeTab={e => handleTabs(e)}
                        back={back}
                        ledgerAllData={ledgerDetailCollection}
                        draftPopUpClose={() => handleDraftPopUpClose()}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={mainTab} index={2} className='tabContentContainer'>
                       <ExciseDetails
                       preview={preview}
                       previewData={previewData}
                       ledgerDetailData={e => handleLedgerDetailData(e)}
                       ProductCreateList={productCreateList}
                       changeTab={e => handleTabs(e)}
                       back={back}
                       ledgerAllData={ledgerDetailCollection}
                       draftPopUpClose={() => handleDraftPopUpClose()}
                       />
                    </CustomTabPanel>
                    <CustomTabPanel value={mainTab} index={3} className='tabContentContainer'>
                       <Document
                       preview={preview}
                    previewData={previewData}
                       ledgerDetailData={e => handleLedgerDetailData(e)}
                       ProductCreateList={productCreateList}
                       changeTab={e => handleTabs(e)}
                       back={back}
                       ledgerAllData={ledgerDetailCollection}
                       draftPopUpClose={() => handleDraftPopUpClose()}
                       />
                    </CustomTabPanel>
                    <CustomTabPanel value={mainTab} index={4} className='tabContentContainer'>
                       <Notes
                        preview={preview}
                        previewData={previewData}
                       ledgerDetailData={e => handleLedgerDetailData(e)}
                       ProductCreateList={productCreateList}
                       changeTab={e => handleTabs(e)}
                       back={back}
                       ledgerAllData={ledgerDetailCollection}
                       successModalClose={()=>successModalClose()}
                       draftPopUpClose={() => handleDraftPopUpClose()}
                       />
                    </CustomTabPanel>
                </Box>
            </div>
        </div>
    );
}

export default AddNewLedger;
