import React from 'react';
import locales from '../../constants/en.json';
import { ReactComponent as RightArrow } from '../../../assets/images/chevron-right.svg';
import Bannercommon from '../../../assets/images/banner_common.png';
import './StreamlineTransforming.scss';

function StreamlineTransforming(props) {
    const StreamlineTransformingData = {
        subtitle: 'Enhance',
        title: 'Transforming Customer Relationships for Pharmacies Everywhere',
        info: 'Our CRM system is specifically designed for pharmacies, empowering them to build stronger customer relationships. With advanced features like customer segmentation, personalized messaging, and loyalty programs, pharmacies can provide exceptional service and increase customer satisfaction. Say goodbye to manual tracking and hello to a streamlined CRM solution that will revolutionize your pharmacy business.',
        image: Bannercommon,
    };

    return (
        <React.Fragment>
            <div className='StreamlineTransforming_container'>
                <div className='container'>
                    <h5 className='subtitle'>{StreamlineTransformingData.subtitle}</h5>
                    <h4 className='title'>{StreamlineTransformingData.title}</h4>
                    <p className='info'>{StreamlineTransformingData.info}</p>
                    <div className='actions'>
                        <button className='signUp_btn'>{locales.signUp}</button>
                        <button className='learn_more'>
                            {locales.learn_more}
                            <span className='icon'>
                                <RightArrow />
                            </span>
                        </button>
                    </div>
                    <div
                        className='banner_block'
                        style={{
                            backgroundImage: `url(${StreamlineTransformingData.image})`,
                        }}
                    ></div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default StreamlineTransforming;
