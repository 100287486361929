// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const UserSaveListSlices = createSlice({
    name: 'UserSaveList',
    initialState: { data: '' },
    reducers: {
        addUserSaveList: (state, action) => {
            state.data = action.payload;
        },
        clearStore(state) {
            state = {};
        },
    },
});

export const { addUserSaveList, clearStore } = UserSaveListSlices.actions;
export default UserSaveListSlices.reducer;
