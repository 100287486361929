import React from 'react';
import classNames from 'classnames';
import { Input } from './Input';
import { Select } from './Select';

export function SelectWithInput({
    label,
    values,
    selectName,
    inputName,
    selectLabel = '',
    inputLabel = '',
    onSelectChange,
    isRequired,
    options,
    placeholder,
    wrapperClass,
    onInputChange,
    touched,
    errors,
    disabled,
}) {
    let errorkey = inputName;

    return (
        <div className={classNames('inputField contactNumber', wrapperClass, { disabled: disabled })}>
            <label className='label'>
                {label}
                {isRequired && <span style={{ color: '#EF4444' }}> *</span>}
            </label>

            <div className='flexbox '>
                <Select
                    label={selectLabel}
                    name={selectName}
                    options={options}
                    disabled={disabled}
                    wrapperClass={'col2'}
                    value={values[selectName]}
                    onChange={onSelectChange}
                    touched={touched[selectName]}
                    error={errors[selectName]}
                />
                <Input
                    label={inputLabel}
                    type={'text'}
                    name={inputName}
                    id={inputName}
                    wrapperClass={'col8'}
                    value={values[inputName]}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={onInputChange}
                    touched={touched[selectName]}
                    maxLength={values[selectName] == 'India' ? 10 : ''}
                    error={errors[selectName] ? errors[selectName] : errors[errorkey]}
                />
            </div>
        </div>
    );
}
