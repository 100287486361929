// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const RequiredListingsSlices = createSlice({
    name: 'requiredListings',
    initialState: {listings: {}},
    reducers: {
        updateRequiredListings: (state, action) => {
            state.listings = {...action.payload};
        },
        clearStore(state) {
            state.listings = {};
        },
    },
});

export const { updateRequiredListings, clearStore } = RequiredListingsSlices.actions;
export default RequiredListingsSlices.reducer;
