
import User from "../../../assets/images/user-2.svg";
import { ReactComponent as UserCog } from "../../../assets/images/user-cog.svg";
import { ReactComponent as CRMIcon } from "../../../assets/images/goal.svg";
import { ReactComponent as CMSIcon } from "../../../assets/images/settings.svg";
import { ReactComponent as InventoryIcon } from "../../../assets/images/shopping-basket.svg";

export const efficencyData = [
    {
        icon: User,
        views: "100M+",
        data: "Efficiently manage your pharmacy operations with FOGO ERP"
    },
    {
        icon: User,
        views: "100M+",
        data: "Efficiently manage your pharmacy operations with FOGO ERP"
    },
    {
        icon: User,
        views: "100M+",
        data: "Efficiently manage your pharmacy operations with FOGO ERP"
    }
];

export const servicesData = [
    {
        icon: <UserCog />,
        title: "HRM",
        detail: "HRM ensures employee productivity and satisfaction by aligning organizational goals with individual needs."
    },
    {
        icon: <CRMIcon />,
        title: "CRM",
        detail: "CRM, or Customer Relationship Management, enhances customer satisfaction and loyalty."
    },
    {
        icon: <CMSIcon />,
        title: "CMS",
        detail: "CMS, or Content Management System, simplifies the creation, management, and publication of digital content on websites."
    },
    {
        icon: <InventoryIcon />,
        title: "Inventory Management",
        detail: "CMS, or Content Management System, simplifies the creation, management, and publication of digital content on websites."
    }
];

