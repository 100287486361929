import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

import { Input } from '../../../components/common';
import InfoBox from './InfoBox';
import Trash from '../../../../assets/images/trash-2.svg';
import SmallInfo from './SmallInfo';
import eyeOn from '../../../../assets/images/eye-on.svg';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { AutoComplete } from './AutoComplete';

const EditTable = ({
                       tableData = [], onCreateSalesScheme, previewData, productAllName, setRows, rows, basicFormValues, subtotal, setSubtotal,
                       totalCashDiscount, setTotalCashDiscount, totalVat, setTotalVat, grandTotal, setGrandTotal, mode, setSelectedSchem, setSalesMode
                   }) => {


    const viewMode = (mode === 'viewMode' ? true : false);

    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const columns = [
        { key: 'code', label: 'Code', disabled: true },
        { key: 'product', label: 'Item Name' },
        { key: 'quantity', label: 'Qty' },
        { key: 'bonus', label: 'Bonus' },
        { key: 'invPrice', label: 'Inv. Rate' },
        { key: 'batchNumber', label: 'Batch No' },
        { key: 'expiryDate', label: 'Exp' }, // Date picker for this field
        { key: 'discountPercentage', label: 'Disc' },
        { key: 'mrp', label: 'MRP' },
        { key: 'sellingPrice', label: 'S. Price' },
        { key: 'margin', label: 'Margin', disabled: true },
        { key: 'cost', label: 'Cost', disabled: true },
        { key: 'amount', label: 'Amount', disabled: true },
    ];

    useEffect(() => {
        if (tableData.length > 0) {
            setRows(tableData);
        } else {
            const initialRows = Array.from({ length: 7 }).map(() => ({
                product: {
                    name: '',
                    productCode: '',
                    vat: '',
                    lastTransaction: {},
                },
                product_id: '',
                quantity: '',
                bonus: '',
                invPrice: '',
                batchNumber: '',
                expiryDate: null, // Set as null to work with DatePicker
                discountPercentage: '',
                mrp: '',
                sellingPrice: '',
                margin: '',
                cost: '',
                amount: '',
            }));
            setRows(initialRows);
        }
    }, []);

    // Calculate the subtotal whenever rows change
    useEffect(() => {
        const calculatedSubtotal = rows.reduce((total, row) => {
            const amount = parseFloat(row.amount) || 0;
            return total + amount;
        }, 0);
        setSubtotal(calculatedSubtotal);

    }, [rows]);

    const handleRowChange = (index, key, value) => {
        const updatedRows = [...rows];
        if (key === 'code') {
            updatedRows[index].product.productCode = value;
        } else {
            updatedRows[index][key] = value;

            // Automatically calculate `amount` when `quantity`, `invPrice`, `discountPercentage`, or `bonus` changes
            if (['quantity', 'invPrice', 'discountPercentage', 'bonus'].includes(key)) {
                const quantity = parseFloat(updatedRows[index].quantity) || 0;
                const bonus = parseFloat(updatedRows[index].bonus) || 0;
                const invPrice = parseFloat(updatedRows[index].invPrice) || 0;
                const discountPercentage = parseFloat(updatedRows[index].discountPercentage) || 0;

                // Adjusted amount calculation with bonus included in quantity
                const totalQuantity = quantity + bonus;
                const amount = (quantity * invPrice) - (quantity * invPrice * discountPercentage / 100);
                const cost = (amount / totalQuantity) * quantity;
                const margin = amount - cost;
                updatedRows[index].amount = amount.toFixed(2); // Set `amount` as a fixed decimal
                updatedRows[index].cost = cost.toFixed(2);
                updatedRows[index].margin = margin.toFixed(2);

                if (mode === 'edit') {
                    updatedRows[index].product_id = updatedRows[index]?.product?._id || updatedRows[index].product_id;
                }


            }
        }

        setRows(updatedRows);
    };

    const handleAutoCompleteChange = (index, value) => {
        const updatedRows = [...rows];
        updatedRows[index].product.productCode = value ? (value?.productCode) : '';
        updatedRows[index].product.name = value ? (value?.name) : '';

        updatedRows[index].product.vat = value ? (value?.vat) : '';
        updatedRows[index].product.lastTransaction = value ? (value?.lastTransaction) : {};

        updatedRows[index].product_id = value ? (value?._id) : '';

        setRows(updatedRows);
    };

    const onCreateNewRow = () => {
        const newRow = {
            product: {
                name: '',
                productCode: '',
                vat: '',
                lastTransaction: {},
            },
            product_id: '',
            quantity: '',
            bonus: '',
            invPrice: '',
            batchNumber: '',
            expiryDate: null,
            discountPercentage: '',
            mrp: '',
            sellingPrice: '',
            margin: '',
            cost: '',
            amount: '',
        };
        setRows([...rows, newRow]);
    };

    const onDeleteRow = (indexToDelete) => {
        setRows(rows.filter((_, index) => index !== indexToDelete));
    };

    const columnConditions = (column) => {
        return column.key === 'product'
            ? 'product'
            : (basicFormValues?.purchaseType !== 'BonusPurchase' && basicFormValues?.purchaseType !== 'Bonus Purchase') && column.key === 'bonus'
                ? 'hideElement'
                : column.key;
    };
    const onSalesScheme = (salesIndex) => {
        setSelectedSchem(salesIndex);
        setSalesMode('view');
        onCreateSalesScheme();
    };

    const onAddCreateSales = () => {
        setSelectedSchem(0);
        setSalesMode(null);
        onCreateSalesScheme();
    };

    useEffect(() => {
        const discountPercentage = basicFormValues?.discountPercentage || 0;
        const discountAmount = (subtotal * discountPercentage) / 100;

        setTotalCashDiscount(discountAmount); // Sets the calculated discount
        setGrandTotal(subtotal - discountAmount + totalVat); // Subtracts discount from subtotal for grand total

    }, [subtotal, basicFormValues?.discountPercentage]);

    useEffect(() => {
        setTotalCashDiscount((subtotal * basicFormValues?.discountPercentage) / 100);
    }, [basicFormValues]);

    // Update total VAT when rows change
    useEffect(() => {
        const calculatedTotalVat = rows.reduce((amount, row) => {
            return amount + parseFloat((row?.amount * row?.product?.vat) / 100) || 0;
        }, 0);
        setTotalVat(calculatedTotalVat);
    }, [rows]);


    return (
        <>
            <div className="tableWrap">
                <table>
                    <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column.key} className={columnConditions(column)}>
                                {column.label}
                            </th>
                        ))}
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows?.map((row, index) => (
                        <tr key={index}
                            onMouseEnter={() => setHoveredRowIndex(row?.product?.lastTransaction)}
                            onMouseLeave={() => setHoveredRowIndex(null)}
                        >
                            {columns.map((column) => (
                                <td key={column.key} className={columnConditions(column)}>
                                    {column.key === 'product' ? (
                                        <AutoComplete viewMode={viewMode}
                                                      handleAutoCompleteChange={handleAutoCompleteChange}
                                                      productAllName={productAllName}
                                                      row={row}
                                                      index={index}
                                        />
                                    ) : column.key === 'expiryDate' ? (
                                        <DatePicker
                                            selected={row.expiryDate}
                                            onChange={(date) => handleRowChange(index, 'expiryDate', date)}
                                            dateFormat="MM/yy"
                                            showMonthYearPicker
                                            placeholderText="MM/YY"
                                            wrapperClassName="ExpiryDatePicker"
                                            disabled={viewMode}
                                        />
                                    ) : column.key === 'code' ? (
                                            <Input
                                                type="text"
                                                name={column.key}
                                                value={row?.product?.productCode}
                                                placeholder=" "
                                                onChange={(e) => handleRowChange(index, 'code', e.target.value)}
                                                disabled={column?.disabled}
                                            />
                                        ) :
                                        <Input
                                            type="text"
                                            name={column.key}
                                            value={row[column.key]}
                                            placeholder=" "
                                            onChange={(e) => handleRowChange(index, column.key, e.target.value)}
                                            disabled={mode === 'viewMode' ? true : column?.disabled}
                                        />
                                    }
                                    {column.key === 'product' ? <InfoBox data={row?.product?.lastTransaction}/> : ''}
                                </td>
                            ))}
                            <td>
                                {
                                    mode === 'viewMode' ?
                                        <button className="commonBtn">
                                            <img src={Trash} alt="Delete row"/>
                                        </button>
                                        :
                                        <button className="commonBtn" onClick={() => onDeleteRow(index)}>
                                            <img src={Trash} alt="Delete row"/>
                                        </button>
                                }
                                {
                                    row?.product?.productCode ?
                                        <button className="commonBtn" onClick={() => onSalesScheme(index)}>
                                            <img src={eyeOn} alt="View row"/>
                                            <SmallInfo/>
                                        </button>
                                        :
                                        <button className="commonBtn" >
                                            <img src={eyeOn} alt="View row"/>
                                            <SmallInfo/>
                                        </button>
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="Bottom">
                <div className="item">
                    <div className="textItem">
                        <div className="addItem" onClick={onCreateNewRow}>+ Add Item</div>
                        <div className="addItem" onClick={() => onAddCreateSales()}>+ Create Sale Schema</div>
                    </div>
                    <InfoBox bottom={true} data={hoveredRowIndex}/>
                </div>
                <div className="item">
                    <div className="total">
                        <div className="row">
                            <div className="item">Subtotal</div>
                            <div className="item">${subtotal.toFixed(2)}</div>
                        </div>
                        <div className="row">
                            <div className="item">VAT%</div>
                            <div className="item">{totalVat.toFixed(2)}</div>
                        </div>
                        <div className="row">
                            <div className="item">Cash Discount</div>
                            <div className="item">{totalCashDiscount.toFixed(2)}</div>
                        </div>
                        <div className="row">
                            <div className="item">Grand Total</div>
                            <div className="item">${grandTotal.toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditTable;
