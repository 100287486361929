import React, { useState, useRef } from 'react';
import locales from '../../../constants/en.json';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';
import { ReactComponent as ArrowUpDown } from '../../../../assets/images/chevrons-up-down.svg';
import { ReactComponent as Dots } from '../../../../assets/images/more-horizontal.svg';
import { ReactComponent as Pencil } from '../../../../assets/images/pencil.svg';
import { ReactComponent as Bin } from '../../../../assets/images/trash-2.svg';
import { ReactComponent as Settings } from '../../../../assets/images/settings.svg';
import { ReactComponent as Morevertical } from '../../../../assets/images/more-vertical.svg';
import { ReactComponent as Equal } from '../../../../assets/images/equal.svg';
import { ReactComponent as Cross } from '../../../../assets/images/x.svg';
import { ReactComponent as EyeOn } from '../../../../assets/images/eye-on.svg';
import Copy from '../../../../assets/images/copy.svg';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as Checkcircle } from '../../../../assets/images/check-circle.svg';
import './CommonTable.scss';
import ManageColumn from '../../../pages/Purchase/Component/ManageColumn';

function CommonTable(props) {
    console.log("preview",props?.preview)
    const [indexCheck, setIndexCheck] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [filterCheckList, setFilterCheckList] = useState([...props.header]);
    const [filterIndex, setFilterIndex] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [moreanchorEl, setMoreanchorEl] = useState(null);
    const [filteredProductCode, setFilteredProductCode] = useState('');
    const [filteredProductID, setFilteredProductID] = useState('');
    const open = Boolean(anchorEl);
    const moreOpen = Boolean(moreanchorEl);
    const [copied, setCopied] = useState(false);
    const [copyOpen, setCopyOpen] = useState(false);

    const [draweropen, setDrawerOpen] = React.useState(false);

    const handleClose = () => {
        setAnchorEl(null);
        setMoreanchorEl(null);
    };

    const handleClick = index => {
        props.dataPopulate && props.dataPopulate(index);
    };

    const handleEditClick = index => {
        props.dataEditPopulate(index);
        setAnchorEl(null);
    };
    let filtered;
    const handleChange = event => {
        if (indexCheck.includes(event)) {
            filtered = indexCheck.filter(item => {
                if (item !== event) {
                    return item;
                }
            });

            if (indexCheck.includes(0) == true && event !== 0 && filtered.includes(0) == false) {
                setIndexCheck([0, ...filtered]);
            } else {
                setIndexCheck([...filtered]);
            }

            if (selectAll) {
                setSelectAll(false);
            }
        } else {
            setIndexCheck([...indexCheck, event]);
            if (selectAll) {
                setSelectAll(false);
            }
        }
    };

    const handleChangeAll = () => {
        if (selectAll) {
            setIndexCheck([]);
            setSelectAll(false);
        } else {
            let collections =
                props.tableBodyData &&
                props.tableBodyData.length > 0 &&
                props.tableBodyData.map((item, index) => {
                    return index;
                });

            setIndexCheck([...indexCheck, ...collections]);
            setSelectAll(true);
        }
    };

    const handlePopUpClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuOptionClicked = event => {
        filerProductCode(event);
    };

    const filerProductCode = event => {
        let filterObject;
        let filteredCode;
        let FilteredID;
        filterObject =
            props.tableBodyData &&
            props.tableBodyData.length > 0 &&
            props.tableBodyData.filter((item, index) => {
                if (index == event) {
                    return item;
                }
            });

        filteredCode = filterObject[0].productCode ? filterObject[0].productCode : filterObject[0].code;
        FilteredID = filterObject[0]._id;
        setFilteredProductCode(filteredCode);
        setFilteredProductID(FilteredID);
    };

    const handleDeleProduct = id => {
        props.deleteProductData(id);
        setAnchorEl(null);
    };

    const handleShowDetail = id => {
        props.handleViewDetail(id);
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCopyOpen(false);
    };

    const handleMakeDeafultClick = id => {
        props.handleMakeDeafultClick(id);
        setAnchorEl(null);
    };

    const handleManageColumn = () => {
        setDrawerOpen(true);
        props.setDrawerColumnOpen && props.setDrawerColumnOpen(true);
    };

    const toggleDrawer = newOpen => () => {
        setDrawerOpen(newOpen);
    };

    const handlefilterCheckList = (event, index) => {
        if (event) {
            if (filterCheckList.includes(event)) {
                let filterData = filterCheckList.filter(item => {
                    if (item !== event) {
                        return item;
                    }
                });
                // let filterData = filterCheckList.splice(index, 1);
                setFilterCheckList(filterData);
            } else {
                setFilterCheckList([...filterCheckList, event]);
            }
        }
    };

    // const handlefilterSave = () => {
    //     props.filterSave(filterCheckList);
    //     setDrawerOpen(false);
    // };
    console.log(props);

    return (
        <React.Fragment>
            {props.header && props.header.length > 0 && props.tableBodyData && props.tableBodyData.length > 0 ? (
                <div className='commonTable_container'>
                    <div className='table_head'>
                        <div className={`table_row ln_${props.header.length}`}>
                            {props.header && props.header.length > 0 && props.pagerender !== 'ledgerMaster' ? (
                                <div className='tabCheckBox'>
                                    <Checkbox checked={selectAll ? true : false} onChange={() => handleChangeAll()} />
                                </div>
                            ) : null}
                            {props.header &&
                                props.header.length > 0 &&
                                props.header.map((label, index) => {
                                    return (
                                        <div key={index} className='thead'>
                                            <div className='flexbox'>
                                                <span className='text'>{label}</span>
                                                <span className='icon'>
                                                    <ArrowUpDown />
                                                </span>
                                                {
                                                    // props.moreVertical && props.moreVertical.length > 0 && props.moreVertical.includes(label) &&
                                                    <PopupState variant='popover' popupId='demo-popup-menu'>
                                                        {popupState => (
                                                            <React.Fragment>
                                                                <span className='morevertical' variant='contained' {...bindTrigger(popupState)}>
                                                                    <Morevertical />
                                                                </span>
                                                                <Menu className='moreverticalMenu' {...bindMenu(popupState)}>
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            handleManageColumn();
                                                                            popupState.close();
                                                                        }}
                                                                    >
                                                                        <span className='icon'>
                                                                            <Settings />
                                                                        </span>{' '}
                                                                        Manage column
                                                                    </MenuItem>
                                                                </Menu>
                                                            </React.Fragment>
                                                        )}
                                                    </PopupState>
                                                }
                                            </div>
                                        </div>
                                    );
                                })}

                            {props.header && props.header.length > 0 && <div className='moreOptions'></div>}
                        </div>
                    </div>
                    <div className='tableBody'>
                        {props.tableBodyData &&
                            props.tableBodyData.length > 0 &&
                            props.tableBodyData.map((item, index) => {
                                return (
                                    <div className={`tablerow ln_${props.header.length}`} key={index}>
                                        {props.pagerender !== 'ledgerMaster' ? (
                                            <div className={`tabCheckBox ${indexCheck.includes(index) ? 'checked' : ''}`}>
                                                <Checkbox checked={indexCheck.includes(index) ? true : false} onChange={() => handleChange(index)} />
                                            </div>
                                        ) : null}
                                        {Object.entries(item).map((data, index) => {
                                            if (props.header?.includes(data[0]) || props.tableFilterHeader?.includes(data[0])) {
                                                return (
                                                    <div key={index} className={`tableCell ${data[0]}`}>
                                                        <div className='flexbox'>
                                                            {data[0] == 'productCode' ||
                                                            data[0] == 'ledgerCode' ||
                                                            data[0] == 'name' ||
                                                            data[0] == 'companyName' ? (
                                                                <span className='text link' onClick={() => handleClick && handleClick(data[1])}>
                                                                    {data[1]}
                                                                </span>
                                                            ) : (
                                                                <span className={`text ${data[1] && `${data[1]}`}`}>{data[1]}</span>
                                                            )}
                                                            {props?.copyHeaderItem?.includes(data[0]) ? (
                                                                <span className='copy'>
                                                                    <CopyToClipboard
                                                                        text={data[1]}
                                                                        onCopy={() => {
                                                                            setCopyOpen(true);
                                                                            setCopied(true);
                                                                        }}
                                                                    >
                                                                        <img src={Copy} alt='copyIcon' className='icon'></img>
                                                                    </CopyToClipboard>
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                        {props?.preview ? (
                                            ""
                                        ):(
                                        <div className='moreOptions'>
                                            <span
                                                className='icon'
                                                id='basic-button'
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup='true'
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={event => {
                                                    handleMenuOptionClicked(index);
                                                    handlePopUpClick(event);
                                                }}
                                            >
                                                <Dots />
                                            </span>
                                        </div>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            ) : (
                <Box className='loader_container' sx={{ display: 'flex' }}>
                    <p>No Data found</p>
                </Box>
            )}
           {props?.preview ? (
            ""
           ):(
            <Menu
                className='editMenu'
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {props?.showEditAction && (
                    <MenuItem onClick={() => handleEditClick(filteredProductCode ? filteredProductCode : filteredProductID)}>
                        <span className='icon'>
                            <Pencil />
                        </span>{' '}
                        Edit
                    </MenuItem>
                )}
                {props?.showDeleteAction && (
                    <MenuItem onClick={() => handleDeleProduct(filteredProductID)}>
                        <span className='icon'>
                            <Bin />
                        </span>{' '}
                        Delete
                    </MenuItem>
                )}
                {props?.makeDefault && (
                    <MenuItem onClick={() => handleMakeDeafultClick(filteredProductID)}>
                        <span className='icon'>
                            <Checkcircle />
                        </span>{' '}
                        Make Default
                    </MenuItem>
                )}
                {props?.showViewProducts && (
                    <MenuItem onClick={() => handleShowDetail(filteredProductID)}>
                        <span className='icon'>
                            <EyeOn />
                        </span>{' '}
                        {props.showViewProducts ? 'View Products' : 'Hide'}
                    </MenuItem>
                )}
                {/* <MenuItem onClick={handleClose}><span className='icon'><EyeOff /></span> Hide</MenuItem> */}
            </Menu>
           )}



        </React.Fragment>
    );
}

export default CommonTable;
