import axios from 'axios';
import { apiUrl, port } from './constants.services';

const axiosClient = axios.create({
    baseURL: `${apiUrl}:${port}/api/`,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

const axiosAuthenticatedClient = axios.create({
    baseURL: `${apiUrl}:${port}/api/`,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor to dynamically set the Authorization header
axiosAuthenticatedClient.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('accessToken'); // Retrieve token from sessionStorage
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

const axiosFileClient = axios.create({
    baseURL: `${apiUrl}:${port}/api/`,
    headers: {
        Accept: 'application/json',
    },
});

export { axiosClient, axiosFileClient, axiosAuthenticatedClient };
